// Core imports
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux";

// State & report config
import { IApplicationState } from "../../../store"
import { FetchState } from "../../../entities/FetchState"
import { IReportDataObject } from "../../../entities/reports/IReportData"
import { IReport, ITrendData, ActionCreators } from "../../../store/rapporten/charts/OndersteuningsCirkelTrends";
import Medewerker from "../../../entities/Medewerker";

// Components
import { VictoryChart, VictoryTheme, VictoryTooltip, VictoryPie, VictoryLegend, VictoryAxis, VictoryLabel } from 'victory';
import { Button, Alert, Container, Row, Col, Table } from "react-bootstrap"
import StartEndAtePickers from "../../selectie/StartEndDatePickers"
import AllMedewerkersDropDown from "../../selectie/AllMedewerkersDropDown"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSyncAlt} from "@fortawesome/free-solid-svg-icons";

// Setup Title
const reportTitle = "Evolutie Ondersteuningscirkels";
const selectDates = true;
const selectMedewerker = true;
const SHOW_TOP_ITEMS = 6;

interface IPropsFromState {
    ondersteuningsCirkelTrends: IReportDataObject<IReport>;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = (state: IApplicationState) => ({
    ondersteuningsCirkelTrends: state.rapporten.charts.OndersteuningsCirkelTrends.Report,
    selectedUser: state.selectie.selectedMedewerker,
    startDate: state.selectie.selectedDateRange.startDate,
    endDate: state.selectie.selectedDateRange.endDate
});

// Mapping Event Dispatcher
interface IPropsFromDispatch {
    FetchReport: typeof ActionCreators.FetchReport
    FetchReportXlsx: typeof ActionCreators.FetchReportXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReport(userId, startDate, endDate)),
    FetchReportXlsx: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});


type AllProps = IPropsFromState & IPropsFromDispatch

class ReportChart extends React.Component<AllProps> {
    constructor(props: any, context: any) {
        super(props, context);

        this.RequestReport = this.RequestReport.bind(this);
        this.RequestReportXlsx = this.RequestReportXlsx.bind(this);

        // Load data on component load
        this.RequestReport();
    }
    
    public render() {

        const medewerkerDropDown = selectMedewerker ? <AllMedewerkersDropDown /> : <></>
        const datePicker = selectDates ? <StartEndAtePickers /> : <></>

        

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.ondersteuningsCirkelTrends.state) {
            case FetchState.Success:
                const downwardTrendGraph = this.getGraph(this.props.ondersteuningsCirkelTrends.data.downwardTrends, "-", "qualitative");
                const upwardTrendGraph = this.getGraph(this.props.ondersteuningsCirkelTrends.data.upwardTrends, "+", "heatmap");

                body = <Container fluid={true}>
                            <Row>
                                <Col>
                                    <div style={{ padding: "5px", width: "60%", textAlign: "center", margin: "0 auto" }}>
                                        <h4>Ondersteuningscirkels verwijderd</h4>
                                        {downwardTrendGraph}                                        
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ padding: "5px", width: "60%", textAlign: "center", margin: "0 auto" }}>
                                        <h4>Ondersteuningscirkels toegevoegd</h4>
                                        {upwardTrendGraph}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.ondersteuningsCirkelTrends.errorMessage}</Alert>
            default:
                break;
        }

        return (
            <div>
                <h1>{reportTitle}</h1>
                {medewerkerDropDown}
                {datePicker}
                <br />
                <Button disabled={this.props.ondersteuningsCirkelTrends.state === FetchState.Busy} onClick={this.RequestReport}>  
                    Bekijken <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
                &nbsp;
                <Button disabled={this.props.ondersteuningsCirkelTrends.state === FetchState.Busy} onClick={this.RequestReportXlsx}>  
                    Downloaden <FontAwesomeIcon icon={faDownload} />
                </Button>
                <br />
                {body}
                
            </div>
        );
    }

    private RequestReport() {
        this.props.FetchReport(this.props.selectedUser.id, this.props.startDate, this.props.endDate);
    }    

    private RequestReportXlsx() {
        this.props.FetchReportXlsx(this.props.selectedUser.id, this.props.startDate, this.props.endDate);
    }

    private getGraph(data: ITrendData[], sign: string, colorScale: string[] | "blue" | "green" | "red" | "grayscale" | "qualitative" | "heatmap" | "warm" | "cool" | undefined) {
        
        
        let total: number = 0;
        data.forEach(a => total += a.aantal);
        

        // change data set so it only takes the top 10 items and groups the rest as "other"
        let showTopNumberOfItems = SHOW_TOP_ITEMS;
        if (data.length < SHOW_TOP_ITEMS) {
            showTopNumberOfItems = data.length;
        }
        const groupedData = data.slice(0, showTopNumberOfItems);
        const otherData = data.slice(showTopNumberOfItems);

        let aantal = 0;
        if (data.length > SHOW_TOP_ITEMS) {
            let sum: number = 0;            
            otherData.forEach(a => {sum += a.aantal; aantal++;});
            groupedData.push({ "name": "Andere (" + aantal + ")", "aantal": sum});
        }

        return <div>
                    <VictoryChart
                        theme={VictoryTheme.material}
                        padding={{ top: 50, bottom: 50, left: -200, right: 200 }}
                        width={400}>
                        
                        <VictoryAxis style={{ 
                            axis: {stroke: "transparent"}, 
                            ticks: {stroke: "transparent"},
                            tickLabels: { fill:"transparent"}
                        }} />

                        <VictoryLabel
                            x={0} y={175}
                            text={total}
                            style={{ fontSize: 20, color: "#333" }}
                        />

                        <VictoryPie 
                            data={groupedData}
                            x="name"
                            y="aantal"
                            colorScale={ colorScale }
                            innerRadius={30}
                            startAngle={0}
                            endAngle={180}

                            horizontal={true}
                            style={{ labels: { fontSize: 6 } }}
                            animate={{
                                duration: 2000,
                                onLoad: { duration: 1000 }
                                }}

                            labels={({ datum }) => datum.name + " : " + sign + datum.aantal }
                            labelPosition={idx => +idx < 3 ? "endAngle" : "centroid" }
                            labelPlacement={"vertical"}
                            labelComponent={<VictoryLabel dx={callback => +callback.index < 1 ? 30 : -5 } />}
                            // labelComponent={ <VictoryTooltip theme={VictoryTheme.material} cornerRadius={2} style={{ fontSize: 7, padding: 5, backgroundColor: "#ffffff", fill: "#666", borderWidth: 0.5, borderColor: "#999" }} />}
                            />
                    </VictoryChart>
                
                    <Table striped={true} bordered={true} hover={true}>
                        <thead>
                            <tr>
                                <th>Ondersteuningscirkel</th>
                                <th style={{ width: "100px"}}>Aantal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, idx) => {
                                return <tr key={item.name}>
                                            <td style={{ textAlign: "left", borderBottom: idx === SHOW_TOP_ITEMS - 1 ? "4px solid #45b29d" : "" }}>{item.name}</td>
                                            <td style={{ borderBottom: idx === SHOW_TOP_ITEMS - 1 ? "4px solid #45b29d" : "" }}>{sign + item.aantal}</td>
                                        </tr>;
                            })}
                        </tbody>                    
                    </Table>

                </div>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportChart);