import { getToken } from "./Authentication";
import Either, { Left, Right } from "./Either";

export interface IError {
    text: string;
    responseCode: number;
}

function dateTimeReviver(key: any, value: any) {
    // "2020-01-28T12:08:37.121"
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?(Z)?/;

    if (typeof value === 'string' && dateFormat.test(value)) {
        return new Date(value);
    }

    return value;
}

export class GraphQl {
    public static async call<T>(query: string): Promise<Either<IError, T>> {
        try {
            const response = await fetch('/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  `Bearer ${getToken()}`
                },
                body: JSON.stringify({ query })
            });
            const responseText = await response.text();
            const responseJson = JSON.parse(responseText, dateTimeReviver)

            if (responseJson.errors && responseJson.errors.length > 0) {
                // map all the error messages in one string
                const errorMessages = responseJson.errors.map((e: { message: any; }) => e.message).join(', ');
                return new Left({ text: errorMessages, responseCode: response.status });
            } 

            return new Right(responseJson.data);
        } catch (e) {
            return new Left({ text: e, responseCode: 0})
        }
    }
}