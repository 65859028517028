import * as AanmeldingAantalPerMaand from './AanmeldingAantalPerMaand'
import * as AanmeldingAangemeldenAfgeslotenZonderContact from './AanmeldingAangemeldAfgeslotenZonderContact'
import * as AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz from "./AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz"
import * as ProcesDossierverloop from "./ProcesDossierverloop"
import * as AanmeldingVerwijzer from "./AanmeldingVerwijzer"
import * as EenmaligContact from "./EenmaligContact"
import * as VroegtijdigeStopzetting from "./VroegtijdigeStopzetting"
import * as ProcesLeeftijdGeslachtBeperkingActivieteitEnz from "./ProcesLeeftijdGeslachtBeperkingActivieteitEnz"
import * as ProcesContactplaats from "./ProcesContactplaats"
import * as OndersteuningsCirkelsInstroomEnUitstroomDto from "./OndersteuningsCirkelsInstroomEnUitstroomDto"
import * as OndersteuningsCirkelsUitstroom from "./AfgeslotenOndersteuningscirkelsUitstroom"
import * as OndersteuningsCirkelsInstroom from "./OndersteuningscirkelsInstroom"
import * as Gedetineerden from "./Gedetineerden"
import * as ProcesBijkomendeUitdaging from "./ProcesBijkomendeUitdaging"
import * as AanmeldingGeenVermoedenBeperking from "./AanmeldingGeenVermoedenBeperking"
import * as AanmeldingOpPvb from "./AanmeldingOpPvb"
import * as AfgeslotenOpPvb from "./AfgeslotenOpPvb"
import * as AfgeslotenGemiddeldeDuurProcesInMaanden from "./AfgeslotenGemiddeldeDuurProcesInMaanden"
import * as RedenVanAfsluitPerClient from "./RedenVanAfsluitPerClient"
import * as RedenVanAfsluit from "./RedenVanAfsluit"
import * as RedenHeraanmeldingPerClient from "./RedenHeraanmeldingPerClient"
import * as RedenHeraanmelding from "./RedenHeraanmelding"
import * as AfgeslotenAardVanHandicap from "./AfgeslotenAardVanHandicap"
import * as AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz from "./AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz"
import * as AfgeslotenOndersteuningscirkelsUitstroomWachtlijst from "./AfgeslotenOndersteuningscirkelsUitstroomWachtlijst"

import {combineReducers} from "redux";
import {all, fork} from "redux-saga/effects";


/* State */
export interface IState  {
    AanmeldingAantalPerMaand: AanmeldingAantalPerMaand.IState,
    AanmeldingAangemeldenAfgeslotenZonderContact: AanmeldingAangemeldenAfgeslotenZonderContact.IState,
    AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz: AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz.IState,
    AanmeldingVerwijzer: AanmeldingVerwijzer.IState,
    ProcesDossierverloop: ProcesDossierverloop.IState,
    EenmaligContact: EenmaligContact.IState,
    VroegtijdigeStopzetting: VroegtijdigeStopzetting.IState,
    ProcesLeeftijdGeslachtBeperkingActivieteitEnz: ProcesLeeftijdGeslachtBeperkingActivieteitEnz.IState,
    ProcesContactplaats: ProcesContactplaats.IState,
    OndersteuningsCirkelsInstroomEnUitstroomDto: OndersteuningsCirkelsInstroomEnUitstroomDto.IState,
    OndersteuningsCirkelsUitstroom: OndersteuningsCirkelsUitstroom.IState,
    OndersteuningsCirkelsInstroom: OndersteuningsCirkelsInstroom.IState,
    Gedetineerden: Gedetineerden.IState,
    ProcesBijkomendeUitdaging: ProcesBijkomendeUitdaging.IState,
    AanmeldingGeenVermoedenBeperking: AanmeldingGeenVermoedenBeperking.IState,
    AanmeldingOpPvb: AanmeldingOpPvb.IState,
    AfgeslotenOpPvb: AfgeslotenOpPvb.IState,
    AfgeslotenGemiddeldeDuurProcesInMaanden: AfgeslotenGemiddeldeDuurProcesInMaanden.IState,
    RedenVanAfsluitPerClient: RedenVanAfsluitPerClient.IState,
    RedenVanAfsluit: RedenVanAfsluit.IState,
    RedenHeraanmeldingPerClient: RedenHeraanmeldingPerClient.IState,
    RedenHeraanmelding: RedenHeraanmelding.IState,
    AfgeslotenAardVanHandicap: AfgeslotenAardVanHandicap.IState
    AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz: AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz.IState
    AfgeslotenOndersteuningscirkelsUitstroomWachtlijst: AfgeslotenOndersteuningscirkelsUitstroomWachtlijst.IState
}
export const InitialState: IState = {
    AanmeldingAantalPerMaand: AanmeldingAantalPerMaand.InitialState,
    AanmeldingAangemeldenAfgeslotenZonderContact: AanmeldingAangemeldenAfgeslotenZonderContact.InitialState,
    AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz: AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz.InitialState,
    AanmeldingVerwijzer: AanmeldingVerwijzer.InitialState,
    ProcesDossierverloop: ProcesDossierverloop.InitialState,
    EenmaligContact: EenmaligContact.InitialState,
    VroegtijdigeStopzetting: VroegtijdigeStopzetting.InitialState,
    ProcesLeeftijdGeslachtBeperkingActivieteitEnz: ProcesLeeftijdGeslachtBeperkingActivieteitEnz.InitialState,
    ProcesContactplaats: ProcesContactplaats.InitialState,
    OndersteuningsCirkelsInstroomEnUitstroomDto: OndersteuningsCirkelsInstroomEnUitstroomDto.InitialState,
    OndersteuningsCirkelsUitstroom: OndersteuningsCirkelsUitstroom.InitialState,
    OndersteuningsCirkelsInstroom: OndersteuningsCirkelsInstroom.InitialState,
    Gedetineerden: Gedetineerden.InitialState,
    ProcesBijkomendeUitdaging: ProcesBijkomendeUitdaging.InitialState,
    AanmeldingGeenVermoedenBeperking: AanmeldingGeenVermoedenBeperking.InitialState,
    AanmeldingOpPvb: AanmeldingOpPvb.InitialState,
    AfgeslotenOpPvb: AfgeslotenOpPvb.InitialState,
    AfgeslotenGemiddeldeDuurProcesInMaanden: AfgeslotenGemiddeldeDuurProcesInMaanden.InitialState,
    RedenVanAfsluitPerClient: RedenVanAfsluitPerClient.InitialState,
    RedenVanAfsluit: RedenVanAfsluit.InitialState,
    RedenHeraanmeldingPerClient: RedenHeraanmeldingPerClient.InitialState,
    RedenHeraanmelding: RedenHeraanmelding.InitialState,
    AfgeslotenAardVanHandicap: AfgeslotenAardVanHandicap.InitialState,
    AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz: AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz.InitialState,
    AfgeslotenOndersteuningscirkelsUitstroomWachtlijst: AfgeslotenOndersteuningscirkelsUitstroomWachtlijst.InitialState
}

export const ActionCreators = {
    AanmeldingAantalPerMaand: AanmeldingAantalPerMaand.ActionCreators,
    AanmeldingAangemeldenAfgeslotenZonderContact: AanmeldingAangemeldenAfgeslotenZonderContact.ActionCreators,
    AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz: AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz.ActionCreators,
    AanmeldingVerwijzer: AanmeldingVerwijzer.ActionCreators,
    ProcesDossierverloop: ProcesDossierverloop.ActionCreators,
    EenmaligContact: EenmaligContact.ActionCreators,
    VroegtijdigeStopzetting: VroegtijdigeStopzetting.ActionCreators,
    ProcesLeeftijdGeslachtBeperkingActivieteitEnz: ProcesLeeftijdGeslachtBeperkingActivieteitEnz.ActionCreators,
    ProcesContactplaats: ProcesContactplaats.ActionCreators,
    OndersteuningsCirkelsInstroomEnUitstroomDto: OndersteuningsCirkelsInstroomEnUitstroomDto.ActionCreators,
    OndersteuningsCirkelsUitstroom: OndersteuningsCirkelsUitstroom.ActionCreators,
    OndersteuningsCirkelsInstroom: OndersteuningsCirkelsInstroom.ActionCreators,
    Gedetineerden: Gedetineerden.ActionCreators,
    ProcesBijkomendeUitdaging: ProcesBijkomendeUitdaging.ActionCreators,
    AanmeldingGeenVermoedenBeperking: AanmeldingGeenVermoedenBeperking.ActionCreators,
    AanmeldingOpPvb: AanmeldingOpPvb.ActionCreators,
    AfgeslotenOpPvb: AfgeslotenOpPvb.ActionCreators,
    AfgeslotenGemiddeldeDuurProcesInMaanden: AfgeslotenGemiddeldeDuurProcesInMaanden.ActionCreators,
    RedenVanAfsluitPerClient: RedenVanAfsluitPerClient.ActionCreators,
    RedenVanAfsluit: RedenVanAfsluit.ActionCreators,
    RedenHeraanmeldingPerClient: RedenHeraanmeldingPerClient.ActionCreators,
    RedenHeraanmelding: RedenHeraanmelding.ActionCreators,
    AfgeslotenAardVanHandicap: AfgeslotenAardVanHandicap.ActionCreators,
    AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz: AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz.ActionCreators,
    AfgeslotenOndersteuningscirkelsUitstroomWachtlijst: AfgeslotenOndersteuningscirkelsUitstroomWachtlijst.ActionCreators
}
export const reducers = combineReducers<IState>({
    AanmeldingAantalPerMaand: AanmeldingAantalPerMaand.Reducers,
    AanmeldingAangemeldenAfgeslotenZonderContact: AanmeldingAangemeldenAfgeslotenZonderContact.Reducers,
    AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz: AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz.Reducers,
    AanmeldingVerwijzer: AanmeldingVerwijzer.Reducers,
    ProcesDossierverloop: ProcesDossierverloop.Reducers,
    EenmaligContact: EenmaligContact.Reducers,
    VroegtijdigeStopzetting: VroegtijdigeStopzetting.Reducers,
    ProcesLeeftijdGeslachtBeperkingActivieteitEnz: ProcesLeeftijdGeslachtBeperkingActivieteitEnz.Reducers,
    ProcesContactplaats: ProcesContactplaats.Reducers,
    OndersteuningsCirkelsInstroomEnUitstroomDto: OndersteuningsCirkelsInstroomEnUitstroomDto.Reducers,
    OndersteuningsCirkelsUitstroom: OndersteuningsCirkelsUitstroom.Reducers,
    OndersteuningsCirkelsInstroom:  OndersteuningsCirkelsInstroom.Reducers,
    Gedetineerden: Gedetineerden.Reducers,
    ProcesBijkomendeUitdaging: ProcesBijkomendeUitdaging.Reducers,
    AanmeldingGeenVermoedenBeperking: AanmeldingGeenVermoedenBeperking.Reducers,
    AanmeldingOpPvb: AanmeldingOpPvb.Reducers,
    AfgeslotenOpPvb: AfgeslotenOpPvb.Reducers,
    AfgeslotenGemiddeldeDuurProcesInMaanden: AfgeslotenGemiddeldeDuurProcesInMaanden.Reducers,
    RedenVanAfsluitPerClient: RedenVanAfsluitPerClient.Reducers,
    RedenVanAfsluit: RedenVanAfsluit.Reducers,
    RedenHeraanmeldingPerClient: RedenHeraanmeldingPerClient.Reducers,
    RedenHeraanmelding: RedenHeraanmelding.Reducers,
    AfgeslotenAardVanHandicap: AfgeslotenAardVanHandicap.Reducers,
    AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz: AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz.Reducers,
    AfgeslotenOndersteuningscirkelsUitstroomWachtlijst: AfgeslotenOndersteuningscirkelsUitstroomWachtlijst.Reducers
});

export function* sagas() {
    yield all(
        [
            fork(AanmeldingAantalPerMaand.Sagas),
            fork(AanmeldingAangemeldenAfgeslotenZonderContact.Sagas),
            fork(AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz.Sagas),
            fork(AanmeldingVerwijzer.Sagas),
            fork(ProcesDossierverloop.Sagas),
            fork(EenmaligContact.Sagas),
            fork(VroegtijdigeStopzetting.Sagas),
            fork(ProcesLeeftijdGeslachtBeperkingActivieteitEnz.Sagas),
            fork(ProcesContactplaats.Sagas),
            fork(OndersteuningsCirkelsInstroomEnUitstroomDto.Sagas),
            fork(OndersteuningsCirkelsUitstroom.Sagas),
            fork(OndersteuningsCirkelsInstroom.Sagas),
            fork(Gedetineerden.Sagas),
            fork(ProcesBijkomendeUitdaging.Sagas),
            fork(AanmeldingGeenVermoedenBeperking.Sagas),
            fork(AanmeldingOpPvb.Sagas),
            fork(AfgeslotenOpPvb.Sagas),
            fork(AfgeslotenGemiddeldeDuurProcesInMaanden.Sagas),
            fork(RedenVanAfsluitPerClient.Sagas),
            fork(RedenVanAfsluit.Sagas),
            fork(RedenHeraanmeldingPerClient.Sagas),
            fork(RedenHeraanmelding.Sagas),
            fork(AfgeslotenAardVanHandicap.Sagas),
            fork(AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz.Sagas),
            fork(AfgeslotenOndersteuningscirkelsUitstroomWachtlijst.Sagas)
    ]);
}
