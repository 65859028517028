// React-Redux Stuff
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { IApplicationState } from "../../../store"

// Local Redux State Stuff
import * as InternalReports from "../../../store/rapporten/internal"
import LopendeDossiers from "../../../entities/reports/LopendeDossiers"
import { FetchState } from "../../../entities/FetchState"
import { IReportData } from "../../../entities/reports/IReportData"

// Components
import AllMedewerkersDropDown from "../../selectie/AllMedewerkersDropDown"
import { Button, Alert } from "react-bootstrap"
import Medewerker from "../../../entities/Medewerker"
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

interface IPropsFromState {
    rapport: IReportData<LopendeDossiers>;
    selectedUser: Medewerker;
}
const mapStateToProps = ({ rapporten, selectie }: IApplicationState) => ({
    rapport: rapporten.internal.LopendeDossiers,
    selectedUser: selectie.selectedMedewerker
});


interface IPropsFromDispatch {
    FetchReport: typeof InternalReports.ActionCreator.FetchLopendeDossiers,
    DownloadReport: typeof InternalReports.ActionCreator.FetchLopendeDossiersXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string) => dispatch(InternalReports.ActionCreator.FetchLopendeDossiers(userId)),
    DownloadReport: (userId: string) => dispatch(InternalReports.ActionCreator.FetchLopendeDossiersXlsx(userId))
});


type AllProps = IPropsFromState & IPropsFromDispatch

class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);

        this.RequestReport = this.RequestReport.bind(this);
        this.download = this.download.bind(this);
    }

    public render() {
        const header = (<div>
            <h1>Rapport Lopende Dossiers</h1>
            <AllMedewerkersDropDown />
            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.RequestReport}>Bekijken</Button>

            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.download}>Download</Button>
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.rapport.state) {
            case FetchState.Success:
                body = <div>
                    <Alert variant="success" dismissible={true}><Alert.Heading>Rapport opgehaald</Alert.Heading>Gegevens werden opgehaald op {this.props.rapport.fetchDate.toLocaleString()} </Alert>
                    <ReactTable
                        data={this.props.rapport.data}
                        columns={
                            [
                                {
                                    Header: "Medewerker", columns:
                                        [
                                            { Header: "Medewerker", accessor: "medewerker" }
                                        ]
                                },
                                {
                                    Header: "Client", columns:
                                        [
                                            { Header: "Dossier", accessor: "dossierNummer" },
                                            { Header: "Naam", accessor: "clientNaam" },
                                            { Header: "18-maander", accessor: "achttienMaander", Cell: p => <span>{p.value ? "Ja" : "Nee"}</span> },
                                            { Header: "Project", accessor: "isProject", Cell: p => <span>{p.value ? "Ja" : "Nee"}</span> },
                                        ]
                                },
                                {
                                    Header: "Status", columns:
                                        [
                                            { Header: "Eerste Contact", accessor: "eersteContact", Cell: p => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                                            { Header: "Eerste Begeleiding", accessor: "eersteBegeleiding", Cell: p => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                                            { Header: "Recentste Begeleding", accessor: "laatsteBegeleiding", Cell: p => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                                            { Header: "Maximum Einddatum Begeleiding",id: "maxLaatsteBegeleiding", accessor: x => x.maxLaatsteBegeleiding, Cell: p => <span title={p.row._original.maxLaatsteBegeleidingCalculation}>{p.row.maxLaatsteBegeleiding == null ? "" : p.row.maxLaatsteBegeleiding.toLocaleDateString()}</span> }
                                        ]
                                },
                            ]
                        }
                        getTrProps={(state: any, rowInfo: any) => {
                            // console.log(rowInfo);
                            if (rowInfo === undefined) {
                                return {};
                            }

                            if (rowInfo.row.maxLaatsteBegeleiding == null) {
                                return {};
                            }

                            if (rowInfo.row.maxLaatsteBegeleiding < new Date()) {
                                return {
                                    style: {
                                        background: 'rgb(220, 53, 69)',
                                        color: 'rgb(255, 255, 255)'
                                    }
                                }
                            } else {
                                const warningDate = new Date(rowInfo.row.maxLaatsteBegeleiding);
                                warningDate.setDate(warningDate.getDate() - 14);

                                if (warningDate < new Date()) {
                                    return {
                                        style: {
                                            background: 'rgb(255, 193, 7)',
                                            color: 'rgb(52, 58, 64)'
                                        }
                                    }
                                } else {
                                    return {};
                                }
                            }
                        }}
                    />
                </div>;
                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.rapport.errorMessage}</Alert>
            default:
                break;
        }

        return (
            <div>
                {header}
                <hr />
                {body}
            </div>
        );
    }

    private download(): void {
        this.props.DownloadReport(this.props.selectedUser.id);
    }

    private RequestReport() {
        this.props.FetchReport(this.props.selectedUser.id);
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
