import { IReportData } from "src/entities/reports/IReportData";
import { IReport, ActionCreators } from "../../../store/rapporten/dop/DossiersPerGemeente";
import Medewerker from "../../../entities/Medewerker";
import {IApplicationState} from "../../../store";
import {Dispatch} from "redux";
import * as React from "react";
import StdRapport from "../common/StandaardRapportFullImprovedDownload";
import {connect} from "react-redux";
import {IReportDownloadData} from "../../../entities/reports/IReportDownloadData";

// Setup Title
const reportTitle = "Dossiers Per Gemeente";
const selectDates = true;
const selectMedewerker = false;
const downloadName = "DossiersPerGemeente.xlsx";

// Mapping Dynamic Properties
interface IPropsFromState {
    rapport: IReportData<IReport>;
    download: IReportDownloadData;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = (state: IApplicationState) => ({
    rapport: state.rapporten.dop.DossiersPerGemeente.Report,
    download: state.rapporten.dop.DossiersPerGemeente.ReportXlsx,
    selectedUser: state.selectie.selectedMedewerker,
    startDate: state.selectie.selectedDateRange.startDate,
    endDate: state.selectie.selectedDateRange.endDate
});

// Mapping Event Dispatcher
interface IPropsFromDispatch {
    FetchReport: typeof ActionCreators.FetchReport
    DownloadReport: typeof ActionCreators.FetchReportXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

// Setup Report
type AllProps = IPropsFromState & IPropsFromDispatch
class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
        return(
            <StdRapport
                title={reportTitle}
                selectDate={selectDates}
                selectMedewerker={selectMedewerker}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedUser={this.props.selectedUser}
                rapport={this.props.rapport}
                download={this.props.download}
                downloadName={downloadName}
                FetchReport={this.props.FetchReport}
                FetchReportXlsx={this.props.DownloadReport}
                columns={
                    [
                        {
                            Header: "Gemeente", columns:
                            [
                                {Header: "Gemeente", accessor: "gemeente"}
                            ]
                        },
                        {
                            Header: "Statistieken", columns: 
                                [
                                    { Header: "Aantal Dossiers", accessor: "aantalDossiers" },
                                    { Header: "Aantal Clienten", accessor: "aantalClienten" },
                                    { Header: "Aantal Aanmeldingen", accessor: "aantalAanmeldingen" },
                                    { Header: "Aantal Processen", accessor: "aantalProcessen" },
                                    { Header: "Aantal Contacten", accessor: "aantalContacten" },
                                    { Header: "Aantal Afgesloten", accessor: "aantalAfgesloten" },
                                    { Header: "Aantal Op Pvb", accessor: "aantalOpPvb" },
                                    { Header: "Aantal Op Dop", accessor: "aantalOpDop" },
                                    
                                    
                                ]
                        },
                    ]
                }
            />
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
