import {Action, Reducer} from "redux";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {IDataState, emptyDataState} from "../../entities/IDataState";
import {FetchState} from "../../entities/FetchState";
import {callApiWithResult, ICallResult, IError} from "../../utils/callApi";
import Either from "../../utils/Either";

/******************************************************************
 * REPORT SPECIFIC STUFF
 *****************************************************************/
// The data to be expected
export interface IData {
    connectionValid: boolean;
}

// API Endpoints
const reportEndpoint = "/Connection";

// The action types prefix is very important to be unique to this file!
export enum ActionTypes {
    FetchReport = "connection.Connection Fetch",
    FetchReportSuccess = "connection.Connection FetchReportSuccess",
    FetchReportFailed = "connection.Connection FetchReportFailed"
}

/**********************************************************
 * STATE
 **********************************************************/
// Define report, state and initialize state for this report
export interface IState {
    data: IDataState<IData>
}
export const InitialState: IState = {
    data: emptyDataState()
}



/**********************************************************
 * ACTIONS & CREATORS
 **********************************************************/
export interface IFetch {
    type: ActionTypes.FetchReport
}
export interface IFetchSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchReportSuccess,
    payload: { data: IData }
}
export interface IFetchFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchReportFailed,
    payload: { errorMessage: string }
}
type Actions = IFetch | IFetchSuccess | IFetchFailed;
export class ActionCreators {
    public static Fetch(): IFetch {
        return {
            type: ActionTypes.FetchReport
        };
    }
    public static FetchSuccess(newData: IData): IFetchSuccess {
        return {
            type: ActionTypes.FetchReportSuccess,
            payload: { data: newData }
        };
    }
    public static FetchFailed(err: string): IFetchFailed {
        return {
            type: ActionTypes.FetchReportFailed,
            payload: { errorMessage: err }
        };
    }
}

/******************************************************************
 * REDUCER
 *****************************************************************/
// Reducers are changing the state
export const Reducers: Reducer<IState, Actions> = (state = InitialState, action) => {
    switch (action.type) {
        case ActionTypes.FetchReport:
            return {
                ...state,
                data: {
                    ...state.data,
                    state: FetchState.Busy }
            };
        case ActionTypes.FetchReportSuccess:
            return {
                ...state,
                data: {
                    ...state.data,
                    fetchDate: new Date(),
                    state: FetchState.Success,
                    data: action.payload.data
                }
            };
        case ActionTypes.FetchReportFailed:
            return {
                ...state,
                data: {
                    ...state.data,
                    fetchDate: new Date(),
                    state: FetchState.Error,
                    data: InitialState.data.data,
                    errorMessage: action.payload.errorMessage
                }
            };
        default:
            return state;
    }
}


/******************************************************************
 * SAGAS
 *****************************************************************/
function* handleIFetchReport(action: IFetch) {
        const response: Either<IError, ICallResult<IData>> = yield call(callApiWithResult, 'get',  `${reportEndpoint}`);

        yield response
            .FlatMap(success => put(ActionCreators.FetchSuccess(success.result) as any))
            .Reduce(error => put(ActionCreators.FetchFailed(error.text) as any))
}


function* watchAll() {
    yield takeEvery(ActionTypes.FetchReport, handleIFetchReport);
}
export function* Sagas() {
    yield all([fork(watchAll)]);
}
