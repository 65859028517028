import { IReportData } from "src/entities/reports/IReportData";
import { IReport, ActionCreators } from "../../../store/rapporten/dop/Wachtlijst";
import Medewerker from "../../../entities/Medewerker";
import {IApplicationState} from "../../../store";
import {Dispatch} from "redux";
import * as React from "react";
import StdRapport from "../common/StandaardRapportFull";
import {connect} from "react-redux";

// Setup Title
const reportTitle = "Wachtlijst";
const selectDates = false;
const selectMedewerker = false;

// Mapping Dynamic Properties
interface IPropsFromState {
    rapport: IReportData<IReport>;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = (state: IApplicationState) => ({
    rapport: state.rapporten.dop.Wachtlijst.Report,
    selectedUser: state.selectie.selectedMedewerker,
    startDate: state.selectie.selectedDateRange.startDate,
    endDate: state.selectie.selectedDateRange.endDate
});

// Mapping Event Dispatcher
interface IPropsFromDispatch {
    FetchReport: typeof ActionCreators.FetchReport;
    DownloadReport: typeof ActionCreators.FetchReportXlsx;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

// Setup Report
type AllProps = IPropsFromState & IPropsFromDispatch
class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
        return(
            <StdRapport
                title={reportTitle}
                selectDate={selectDates}
                selectMedewerker={selectMedewerker}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedUser={this.props.selectedUser}
                rapport={this.props.rapport}
                FetchReport={this.props.FetchReport}
                FetchReportXlsx={this.props.DownloadReport}
                columns={
                    [
                        {
                            Header: "Medewerker", columns:
                                [
                                    {Header: "Medewerker", accessor: "medewerker"}
                                ]
                        },
                        {
                            Header: "Aanmelding", columns:
                                [

                                    {
                                        Header: "Aanmeldingsdatum",
                                        accessor: "aanmeldingsDatum",
                                        Cell: (p: any) =>
                                            <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                    },
                                    {Header: "Dossier", accessor: "dossierNummer"},
                                    {
                                        Header: "Voorrangdossier",
                                        accessor: "voorrangDossierType",
                                        Cell: (p: any) => <span>{p.value == null ? "Nee" : p.value}</span>
                                    }
                                ]
                        },
                        {
                            Header: "Client", columns:
                                [
                                    {Header: "Naam", accessor: "clientNaam"},
                                    {Header: "Leeftijd", accessor: "clientLeeftijd"},
                                    {Header: "Domiciliegemeente", accessor: "domicilieGemeente"},
                                    {Header: "Verblijfgemeente", accessor: "verblijfGemeente"}
                                ]
                        },
                        {
                            Header: "Status", columns:
                                [
                                    {
                                        Header: "Aanmeldingsgesprek / Cas",
                                        accessor: "aanmeldingsGesprekCasDatum",
                                        Cell: (p: any) =>
                                            <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                    },
                                    {Header: "Reden niet opgestart", accessor: "redenNietOpgestart"},
                                    {
                                        Header: "Vermoedelijke opstart",
                                        accessor: "vermoedelijkeOpstartDatum",
                                        Cell: (p: any) =>
                                            <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                    },
                                    {
                                        Header: "Vraag Op Pvb",
                                        accessor: "heeftVraagNaarOpPvb",
                                        Cell: (p: any) => <span>{p.value ? "Ja" : "Nee"}</span>
                                    },
                                    {
                                        Header: "Gevalideerd",
                                        accessor: "isGevalideerdeAanmelding",
                                        Cell: (p: any) => <span>{p.value ? "Ja" : "Nee"}</span>
                                    }
                                ]
                        },
                    ]
                }
            />
        );
    }


}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);