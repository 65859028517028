export default class DateRange {
    public static Default(): DateRange {
        const date = new Date();
        const stDate = new Date(date.getFullYear(), 0, 1,0,0,0);
        const enDate = new Date(date.getFullYear(), 11, 31,23,59,59);

        return {startDate: stDate, endDate: enDate}
    }

    public startDate: Date;
    public endDate: Date;
}