import {Action, Reducer} from "redux";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {IReportData, emptyReportData} from "../../../entities/reports/IReportData";
import {FetchState} from "../../../entities/FetchState";
import {callApiWithResult, downloadFile, ICallResult, IError} from "../../../utils/callApi";
import Either from "../../../utils/Either";

/******************************************************************
 * REPORT SPECIFIC STUFF
 *****************************************************************/
// The data to be expected
export interface IReport {
    naamSe: string;
    nummerSe: string;
    rijksregisterNummer: string;
    naam: string;
    voornaam: string;
    straatNummer: string;
    postcode: string;
    gemeente: string;
    typeRegistratie: string;
    datum: Date;
    datumRegistratie: Date;
    naamMedewerker: string;
    voornaamMedewerker: string;
}

// API Endpoints
const reportEndpoint = "/Dop/GetRegistratiesDoorMedewerkersDto";
const reportEndpointXlsx = "/Dop/GetRegistratiesDoorMedewerkersDtoXlsx";
const reportXlsxFileName = "RegistratiesDoorMedewerkers.xlsx";

// The action types prefix is very important to be unique to this file!
enum ActionTypes {
    FetchReport = "rapporten.dop.GetRegistratiesDoorMedewerkersDto Fetch",
    FetchReportXlsx =  "rapporten.dop.GetRegistratiesDoorMedewerkersDto FetchXlsx",
    FetchReportSuccess = "rapporten.dop.GetRegistratiesDoorMedewerkersDto FetchReportSuccess",
    FetchReportFailed = "rapporten.dop.GetRegistratiesDoorMedewerkersDto FetchReportFailed"
}

/**********************************************************
 * STATE
 **********************************************************/
// Define report, state and initialize state for this report
export interface IState {
    Report: IReportData<IReport>
}
export const InitialState: IState = {
    Report: emptyReportData()
}



/**********************************************************
 * ACTIONS & CREATORS
 **********************************************************/
export interface IFetchReport {
    type: ActionTypes.FetchReport,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }    
}
export interface IFetchReportXlsx {
    type: ActionTypes.FetchReportXlsx,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }
}
export interface IFetchReportSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchReportSuccess,
    payload: { data: IReport[] }
}
export interface IFetchReportFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchReportFailed,
    payload: { errorMessage: string }
}
type Actions = IFetchReport | IFetchReportXlsx | IFetchReportSuccess | IFetchReportFailed;
export class ActionCreators {
    public static FetchReport(userId: string, startDateIn: Date, endDateIn: Date): IFetchReport {
        return { 
            type: ActionTypes.FetchReport, 
            payload: { gebruikersId: userId, startDate: startDateIn, endDate: endDateIn } 
        };
    }
    public static FetchReportXlsx(userId: string, startDateIn: Date, endDateIn: Date): IFetchReportXlsx {
        return { 
            type: ActionTypes.FetchReportXlsx, 
            payload: { gebruikersId: userId, startDate: startDateIn, endDate: endDateIn } 
        };
    }
    public static FetchReportSuccess(newData: IReport[]): IFetchReportSuccess {
        return {
            type: ActionTypes.FetchReportSuccess,
            payload: { data: newData }
        };
    }
    public static FetchReportFailed(err: string): IFetchReportFailed {
        return {
            type: ActionTypes.FetchReportFailed,
            payload: { errorMessage: err }
        };
    }
}

/******************************************************************
 * REDUCER
 *****************************************************************/
// Reducers are changing the state
export const Reducers: Reducer<IState, Actions> = (state = InitialState, action) => {
    switch (action.type) {
        case ActionTypes.FetchReport:
            return { 
                ...state, 
                Report: { 
                    ...state.Report, 
                    state: FetchState.Busy } 
            };
        case ActionTypes.FetchReportSuccess:
            return {
                ...state,
                Report: {
                    ...state.Report,
                    fetchDate: new Date(),
                    state: FetchState.Success,
                    data: action.payload.data
                }
            };
        case ActionTypes.FetchReportFailed:
            return {
                ...state,
                Report: {
                    ...state.Report,
                    fetchDate: new Date(),
                    state: FetchState.Error,
                    data: [],
                    errorMessage: action.payload.errorMessage
                }
            };
        default:
            return state;
    }
}


/******************************************************************
 * SAGAS
 *****************************************************************/
function* handleIFetchReport(action: IFetchReport) {
    const response: Either<IError, ICallResult<IReport[]>> = yield call(callApiWithResult, 'get', `${reportEndpoint}?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`);

    yield response
        .FlatMap(success => put(ActionCreators.FetchReportSuccess(success.result) as any))
        .Reduce(error => put(ActionCreators.FetchReportFailed(error.text) as any))
}


function* handleIFetchReportXlsx(action: IFetchReportXlsx) {

    // call the thing
    yield call(downloadFile, 'get', `${reportEndpointXlsx}?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`, reportXlsxFileName);
    // Todo: error handling
}

function* watchAll() {
    yield takeEvery(ActionTypes.FetchReport, handleIFetchReport);
    yield takeEvery(ActionTypes.FetchReportXlsx, handleIFetchReportXlsx);
}
export function* Sagas() {
    yield all([fork(watchAll)]);
}