import { AuthenticationContext } from 'react-adal';

export const authContext = new AuthenticationContext({
    instance: 'https://login.microsoftonline.com/',
    tenant: 'common',
    clientId: '52410e5c-a891-40a0-a0a8-25aa7ededc55',
    endpoints: {
        api: 'https://johnverbiest.be/Dop.Rapporten'
    },
    postLogoutRedirectUri: 'https://www.google.be',
    redirectUri: window.location.origin,
    cacheLocation: 'sessionStorage'
});

export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
};