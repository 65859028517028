import * as React from "react";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap"
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-arrow-buttons/dist/css/bootstrap-arrow-buttons.css"
import { Dispatch } from "redux"
import { connect } from "react-redux"

import { IApplicationState } from "../../store"
import * as Selectie from "../../store/selectie/"
import DateRange from "../../entities/DateRange"

interface IPropsFromState {
    currentDateRange: DateRange
}
const mapStateToProps = ({ selectie }: IApplicationState) => {
    return {
        currentDateRange: selectie.selectedDateRange
    }

}

interface IPropsFromDispatch {
    updateDateRange: typeof Selectie.SelectieActionCreator.DateRangeChanged
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateDateRange: (newDateRange: DateRange) => dispatch(Selectie.SelectieActionCreator.DateRangeChanged(newDateRange))
});

class StartEndDatePickers extends React.Component<IPropsFromState & IPropsFromDispatch> {

    constructor(props: any, context: any) {
        super(props, context);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateThisYear = this.updateThisYear.bind(this);
        this.updateThisMonth = this.updateThisMonth.bind(this);
        this.addYear = this.addYear.bind(this);
        this.subtractYear = this.subtractYear.bind(this);
        this.addMonth = this.addMonth.bind(this);
        this.subtractMonth = this.subtractMonth.bind(this);
        this.updateMonths = this.updateMonths.bind(this);
        this.updateDate = this.updateDate.bind(this);
    }

    public render() {
        const buttonMargin: React.CSSProperties = {
            marginLeft: "50px"
        }

        return (
            <div>
                <Button onClick={this.subtractYear} variant={"primary"} className="btn-arrow-left">Jaar</Button>
                <Button onClick={this.subtractMonth} variant={"primary"} className="btn-arrow-left">Maand</Button>

                <DatePicker
                    id="beginDatePicker"
                    selected={this.props.currentDateRange.startDate}
                    selectsStart={true}
                    startDate={this.props.currentDateRange.startDate}
                    endDate={this.props.currentDateRange.endDate}
                    onChange={this.handleStartChange}
                    
                />
                {" (inclusief) tot en met "}
                <DatePicker
                    id="endDatePicker"
                    selected={this.props.currentDateRange.endDate}
                    selectsEnd={true}
                    startDate={this.props.currentDateRange.startDate}
                    endDate={this.props.currentDateRange.endDate}
                    onChange={this.handleEndChange}
                />
                <Button onClick={this.addMonth} variant={"primary"} className="btn-arrow-right">Maand</Button>
                <Button onClick={this.addYear} variant={"primary"} className="btn-arrow-right">Jaar</Button>
                
                <Button style={buttonMargin} onClick={this.updateThisMonth}>Huidige Maand</Button>
                <Button onClick={this.updateThisYear}>Huidig Jaar</Button>
            </div>
        )
    }

    private handleStartChange(date: Date) {
        const utcDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        this.props.updateDateRange({ ...this.props.currentDateRange, startDate: utcDate });
    }
    private handleEndChange(date: Date) {
        const utcDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        this.props.updateDateRange({ ...this.props.currentDateRange, endDate: utcDate });
    }
    private updateThisYear() {
        const thisYear = new Date().getFullYear();
        this.handleChange(new Date(thisYear, 0, 1), new Date(thisYear, 11, 31));
    }
    private updateThisMonth() {
        const today = new Date();
        this.handleChange(new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0));
    }
    private addYear() {
        this.updateMonths(+12);
    }
    private subtractYear() {
        this.updateMonths(-12);
    }
    private addMonth() {
        this.updateMonths(+1);
    }
    private subtractMonth() {
        this.updateMonths(-1);
    }
    private updateMonths(months: number) {
        this.handleChange(this.updateDate(this.props.currentDateRange.startDate, months), this.updateDate(this.props.currentDateRange.endDate, months));
    }
    private updateDate(initalDate: Date, months: number): Date {
        if (initalDate.getMonth() !==
            new Date(initalDate.getFullYear(), initalDate.getMonth(), initalDate.getDate() + 1).getMonth()) {
            // Last day of the month mode
            return new Date(initalDate.getFullYear(), initalDate.getMonth() + months + 1, 0);
        }

        return new Date(initalDate.getFullYear(), initalDate.getMonth() + months, initalDate.getDate());
    }
    private handleChange(starttDate: Date, enddDate: Date) {
        const startUtcDate = new Date(starttDate.getFullYear(), starttDate.getMonth(), starttDate.getDate(), 0, 0, 0);
        const endUtcDate = new Date(enddDate.getFullYear(), enddDate.getMonth(), enddDate.getDate(), 23, 59, 59);
        this.props.updateDateRange({ ...this.props.currentDateRange, startDate: startUtcDate, endDate: endUtcDate });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StartEndDatePickers)