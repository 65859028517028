import {Reducer} from "redux";
import { all, fork, takeEvery, put } from "redux-saga/effects";
import {push} from "connected-react-router";

export interface IState {
    dummy: number
} 

export const InitialState: IState = {
    dummy: 0
}

enum ActionTypes {
    ToOutreach = "Navigate.ToOutreach",
    ToOutreachNew = "Navigate.ToOutreachNew",
    ToOutreachDetail = "Navigate.ToOutreachDetail",
    ToOutreachEdit = "Navigate.ToOutreachEdit",
}
interface IOutreach {
    type: ActionTypes.ToOutreach
}
interface IOutreachNew {
    type: ActionTypes.ToOutreachNew
}
interface IOutreachDetail {
    type: ActionTypes.ToOutreachDetail
    id: number
}
interface IOutreachEdit {
    type: ActionTypes.ToOutreachEdit
    id: number
}


type Actions = IOutreach | IOutreachNew | IOutreachDetail | IOutreachEdit

export const ActionCreators = {
    ToOutreach (): IOutreach {
        return {
            type: ActionTypes.ToOutreach
        }
    },
    ToOutreachNew(): IOutreachNew {
        return {
            type: ActionTypes.ToOutreachNew
        }
    },
    ToOutreachDetail(id: number): IOutreachDetail {
        return {
            type: ActionTypes.ToOutreachDetail,
            id
        }
    }, 
    ToOutreachEdit(id: number): IOutreachEdit {
        return {
            type: ActionTypes.ToOutreachEdit,
            id
        }
    }, 
}

export const Reducers: Reducer<IState, Actions> = (state = InitialState, action) => state

/*********************************************************************************************
 * Saga's
 */

function* handleToOutreach(action: IOutreach) {
    yield put(push(`/outreach/`))
}

function* handleToNewOutreach(action: IOutreachNew) {
    yield put(push('/outreach/new'))
}

function* handleToOutreachDetail(action: IOutreachDetail) {
    yield put(push(`/outreach/details/${action.id}`))
}

function* handleToOutreachEdit(action: IOutreachEdit) {
    yield put(push(`/outreach/edit/${action.id}`))
}

function* watchAll() {
    yield takeEvery(ActionTypes.ToOutreach, handleToOutreach);
    yield takeEvery(ActionTypes.ToOutreachNew, handleToNewOutreach);
    yield takeEvery(ActionTypes.ToOutreachDetail, handleToOutreachDetail);
    yield takeEvery(ActionTypes.ToOutreachEdit, handleToOutreachEdit);
}
export function* Sagas() {
    yield all([fork(watchAll)])
}