import { getToken } from './Authentication';
import Either, {Left, Right} from "./Either";
const baseUrl = ""

function dateTimeReviver(key: any, value: any) {
    // "2020-01-28T12:08:37.121"
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?(Z)?$/;

    if (typeof value === 'string' && dateFormat.test(value)) {
        return new Date(value);
    }
    return value;
}

async function awesomeFetcher(path: string, method: string, data: any) {
    if (path.startsWith("/"))
    {
        path = path.substring(1);
    }
    return await fetch(baseUrl + "/api/" + path,
        {
            method,
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify(data)
        });
}

export async function callApiWithResult<T>(method: string, path: string, data?: any): Promise<Either<IError, ICallResult<T>>> {
    try {
        const res = await awesomeFetcher(path, method, data);
        const text = await res.text();
        return res.ok
            ? new Right({
                text,
                result: JSON.parse(text, dateTimeReviver),
                responseCode: res.status
            })
            : new Left(new ComError(text, res.status))

    } catch (e) {
        return new Left({text: e.toString(), responseCode: -1})
    }
}
export async function callApiNoResult(method: string, path: string, data?: any): Promise<Either<IError, ICallNoResult>> {
    try {
        const res = await awesomeFetcher(path, method, data);
        return res.ok
            ? new Right({
                responseCode: res.status
            })
            : new Left(new ComError(await res.text(), res.status))

    } catch (e) {
        return new Left({text: e.toString(), responseCode: -1})
    }
}

export async function downloadFile(method: string, path: string, fileName?: string, data?: any)
{
    const res = await fetch(baseUrl + "/api" + path,
        {
            method,
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify(data)
        });

    const anchor = document.createElement("a");
    document.body.appendChild(anchor);

    const blob = await res.blob();
    const objectUrl = window.URL.createObjectURL(blob);
    anchor.href = objectUrl;
    anchor.download = fileName || "rapport.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
}

export async function downloadBlob(method: string, path: string, data?: any): Promise<Either<IError, ICallBlobResult>>
{
    try {
        const res = await fetch(baseUrl + "/api" + path,
            {
                method,
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
                body: JSON.stringify(data)
            });
        
        return res.ok
            ? new Right({result: await res.blob(), responseCode: res.status})
            : new Left(new ComError(await res.text(), res.status))

    } catch (e) {
        return new Left({text: e.toString(), responseCode: -1})
    }
}

export async function callApi(method: string, url: string, path: string, data?: any) {
    const res = await fetch(url + "/api" + path,
        {
            method,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify(data)
        });
    const result2 = JSON.parse(await res.text(), dateTimeReviver);
    return result2;
}

export interface ICallResult<T>
{
    text: string;
    result: T;
    responseCode: number;
}

export interface ICallBlobResult
{
    result: Blob;
    responseCode: number;
}

export interface ICallNoResult
{
    responseCode: number;
}

export interface IError
{
    text: string;
    responseCode: number;
}

export class ComError implements IError
{
    public responseCode: number;
    public text: string;
    
    constructor(error: string, code: number) {
        this.responseCode = code
        try {
            const data = JSON.parse(error) as IComErrorDto;
            this.text = data.errorMessage;
            // tslint:disable-next-line:no-console
            console.error(`${data.errorMessage}\n\n${data.details}`, code)
        } catch {
            this.text = error;
        }
    }
}
interface IComErrorDto {
    errorMessage: string;
    details: string;
}