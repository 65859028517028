import * as React from "react";
import Header from "./common/Header"

interface IProps
{
    children: React.ReactNode;
}
class App extends React.Component<IProps> {

    public render() {
        return (
            <div className="container-fluid">
                <Header />
                {this.props.children}
            </div>
        );
    }
}


export default App