import * as Connection from "./connection/"
import * as Outreach from "./outreach"
import * as Rapporten from "./rapporten"

import * as Selectie from "./selectie"
import selectieSagas from "./selectie/sagas"

import * as Navigate from "./navigate"

import { Store, applyMiddleware, combineReducers, createStore } from "redux"
import { all, fork } from 'redux-saga/effects'

import { RouterState, connectRouter, routerMiddleware } from "connected-react-router"
import {createBrowserHistory, History} from "history";
import { composeWithDevTools } from "redux-devtools-extension"
import createSagaMiddleware from "redux-saga"


// Top level state
export interface IApplicationState {
    connection: Connection.IState;
    rapporten: Rapporten.IRapportenState;
    selectie: Selectie.IState;
    outreach: Outreach.IState;
    router: RouterState;
    navigate: Navigate.IState;
}

// Root reducer, containing all reducers
export const rootReducer = (history: History) => combineReducers<IApplicationState>({
    router: connectRouter(history),
    connection: Connection.reducers,
    rapporten: Rapporten.rootReducer,
    selectie: Selectie.reducer,
    outreach: Outreach.Reducers,
    navigate: Navigate.Reducers
});

// Root action creator holder
export const RootActionCreators = {
    Connection: Connection.ActionCreators,
    Rapporten: Rapporten.ActionCreators,
    Selectie: Selectie.SelectieActionCreator, 
    Outreach: Outreach.ActionCreators,
    Navigate: Navigate.ActionCreators,
}

// Root saga, containing all sagas
export function* rootSaga() {
    yield all([fork(Connection.sagas), fork(Rapporten.midSaga), fork(selectieSagas), fork(Outreach.sagas), fork(Navigate.Sagas)]);
}

export const history = createBrowserHistory();

// Creating the main store
export default function configureStore() : Store<IApplicationState>
{
    const devTools = composeWithDevTools({});
    const sagaMiddleware = createSagaMiddleware()

    const store = createStore(
        rootReducer(history),
        devTools(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}