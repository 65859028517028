import * as React from "react";
import {Dispatch} from "redux"
import {connect} from "react-redux"

import {Button, ButtonToolbar} from "react-bootstrap"
import {IApplicationState} from "../../store"
import * as Data from "../../store/connection/Connection"
import {FetchState} from "../../entities/FetchState";

interface IPropsFromState {
    connection: Data.IState;
}
const mapStateToProps = (state: IApplicationState) => ({
    connection: state.connection.Connection
});


interface IPropsFromDispatch {
    checkConnection: typeof Data.ActionCreators.Fetch
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    checkConnection: () => dispatch(Data.ActionCreators.Fetch())
});


type AllProps = IPropsFromState & IPropsFromDispatch

class ConnectedStatus extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public componentDidMount() {
        const connection = this.props.connection;
        if (connection.data.state !== FetchState.Success) {
            this.props.checkConnection();
        }
    }

    public render() {

        const connection = this.props.connection;
        let text: string;
        let style: any;

        if (connection.data.state === FetchState.Busy) {
            style = 'info';
            text = "Vebinden...";
        } else if (connection.data.state === FetchState.Error) {
            style = 'danger';
            text = "Fout: " + connection.data.errorMessage;
        } else if (connection.data.state === FetchState.Success) {
            if (connection.data.data.connectionValid) {
                style = 'success';
                text = "Verbonden";
            } else {
                style = 'warning';
                text = "Verbinding verbroken";
            }
        } else {
            style = 'default';
            text = "Onbekend";
        }


        return (
            <ButtonToolbar>
                <Button variant={ style } onClick={ this.props.checkConnection }>{ text }</Button> 
            </ButtonToolbar>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedStatus);