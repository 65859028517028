import * as AanmeldingenPerPeriode from "./AanmeldingenPerPeriode";
import * as Afrekening from "./Afrekening"
import * as Projecten from "./Projecten"


import {combineReducers} from "redux";
import {all, fork} from "redux-saga/effects";


/* State */
export interface IState  {
    AanmeldingenPerPeriode: AanmeldingenPerPeriode.IState,
    Afrekening: Afrekening.IState,
    Projecten: Projecten.IState
}
export const InitialState: IState = {
    AanmeldingenPerPeriode: AanmeldingenPerPeriode.InitialState,
    Afrekening: Afrekening.InitialState,
    Projecten: Projecten.InitialState
}

export const ActionCreators = {
    AanmeldingenPerPeriode: AanmeldingenPerPeriode.ActionCreators,
    Afrekening: Afrekening.ActionCreators,
    Projecten: Projecten.ActionCreators
}
export const reducers = combineReducers<IState>({
    AanmeldingenPerPeriode: AanmeldingenPerPeriode.Reducers,
    Afrekening: Afrekening.Reducers,
    Projecten: Projecten.Reducers
});

export function* sagas() {
    yield all(
        [
            fork(AanmeldingenPerPeriode.Sagas),
            fork(Afrekening.Sagas),
            fork(Projecten.Sagas),
    ]);
}
