import { IReportData, emptyReportData } from "../../entities/reports/IReportData";
import { FetchState } from "../../entities/FetchState";
import { Reducer } from "redux";
import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import Either from "../../utils/Either";
import { IError } from "../../utils/callApi";
import { GraphQl } from "../../utils/callGraphQl";


/**********************************************************
 * API Data
 **********************************************************/
export interface IData {
    id: string;
    naam: string;
    enableOmschrijving: boolean;
}

export const defaultItems: IReportData<IData> = emptyReportData();

export const defaultItem: IData = {
    id: '',
    naam: '',
    enableOmschrijving: false
};

/**********************************************************
 * API Settings
 **********************************************************/

const graphQlQuery = `
{ 
    outreachDoelgroepen {
        id
        naam
        enableOmschrijving
    }
}`

interface IGraphQlQueryResponse {
    outreachDoelgroepen: Array<{
        id: string;
        naam: string;
        enableOmschrijving: boolean;
    }>
}

function transformData(response: IGraphQlQueryResponse): IData[] {
    return response.outreachDoelgroepen.map((o) => ({
        id: o.id,
        naam: o.naam,
        enableOmschrijving: o.enableOmschrijving
    }));
}

/**********************************************************
 * State
 **********************************************************/

export interface IState {
    Items: IReportData<IData>
}
const InitialState: IState = {
    Items: defaultItems
}

/**********************************************************
 * Action Types
 **********************************************************/
export enum ActionTypes {
    FetchData = "outreach.OutreachDoelgroep Fetch",
    FetchDataSuccess = "outreach.OutreachDoelgroep FetchSuccess",
    FetchDataFailed = "outreach.OutreachDoelgroep FetchFailed"
}

/**********************************************************
 * Actions & Creators
 **********************************************************/
export interface IFetchData {
    type: ActionTypes.FetchData,
}
export interface IFetchDataSuccess {
    type: ActionTypes.FetchDataSuccess,
    payload: { data: IData[] }
}
export interface IFetchDataFailed {
    type: ActionTypes.FetchDataFailed,
    payload: { errorMessage: string }
}
type Actions = IFetchData | IFetchDataSuccess | IFetchDataFailed;

export class ActionCreators {
    public static FetchData(): IFetchData {
        return { 
            type: ActionTypes.FetchData, 
        };
    }
    public static FetchDataSuccess(newData: IData[]): IFetchDataSuccess {
        return {
            type: ActionTypes.FetchDataSuccess,
            payload: { data: newData }
        };
    }
    public static FetchDataFailed(err: string): IFetchDataFailed {
        return {
            type: ActionTypes.FetchDataFailed,
            payload: { errorMessage: err }
        };
    }
}

/**********************************************************
 * Reducer
 **********************************************************/
export const Reducers: Reducer<IState, Actions> = (state = InitialState, action) => {
    switch (action.type) {
        case ActionTypes.FetchData:
            return { ...state, Items: { ...state.Items, state: FetchState.Busy } }
        case ActionTypes.FetchDataSuccess:
            return { ...state, Items: { ...state.Items, state: FetchState.Success, data: action.payload.data } }
        case ActionTypes.FetchDataFailed:
            return { ...state, Items: { ...state.Items, state: FetchState.Error, errorMessage: action.payload.errorMessage } }
    }
    return state;
}

/**********************************************************
 * Sagas
 **********************************************************/
function* handleIFetchData(action: IFetchData) {
    try {
        const response : Either<IError, IGraphQlQueryResponse>= yield call(GraphQl.call, graphQlQuery);
        yield response
            .FlatMap(success => put(ActionCreators.FetchDataSuccess(transformData(success)) as any))
            .Reduce(error => put(ActionCreators.FetchDataFailed(error.text) as any))
    } catch (err) {
        yield put(ActionCreators.FetchDataFailed(JSON.stringify(err)))
    }
}

function* watchAll() {
    yield takeEvery(ActionTypes.FetchData, handleIFetchData)
}
export function* sagas() {
    yield all([fork(watchAll)]);
}