import { Action, Reducer } from "redux";
import { FetchState } from "../../../entities/FetchState"
import { IReportData, emptyReportData } from "../../../entities/reports/IReportData"
import AanmeldingWachtlijst from "../../../entities/reports/AanmeldingWachtlijst"
import ContactenPerMedewerkerPerMaand from "../../../entities/reports/ContactenPerMedewerkerPerMaand"
import ContactenPerMaand from "../../../entities/reports/ContactenPerMaand"
import ContactenPerMaandGraph from "../../../entities/reports/ContactenPerMaandGraph"
import LopendeDossiers from "../../../entities/reports/LopendeDossiers"
import Gewicht from "../../../entities/reports/Gewicht"
import ContactenPerMedewerkerPerDag from "../../../entities/reports/ContactenPerMedewerkerPerDag"


/**********************************************************
 * STATE
 **********************************************************/

export interface IState {
    AanmeldingWachtlijst: IReportData<AanmeldingWachtlijst>;
    ContactenPerMedewerkerPerMaand: IReportData<ContactenPerMedewerkerPerMaand>;
    ContactenPerMaand: IReportData<ContactenPerMaand>;
    ContactenPerMaandGraph: IReportData<ContactenPerMaandGraph>;
    LopendeDossiers: IReportData<LopendeDossiers>;
    Gewicht: IReportData<Gewicht>;
    ContactenPerMedewerkerPerDag: IReportData<ContactenPerMedewerkerPerDag>;
}

export const initialState: IState =
{
    AanmeldingWachtlijst: emptyReportData(),
    ContactenPerMedewerkerPerMaand: emptyReportData(),
    ContactenPerMaand: emptyReportData(),
    ContactenPerMaandGraph: emptyReportData(),
    LopendeDossiers: emptyReportData(),
    Gewicht: emptyReportData(),
    ContactenPerMedewerkerPerDag: emptyReportData()
}


/**********************************************************
 * ACTIONS & CREATORS
 **********************************************************/
// Action Types
export enum ActionTypes {
    FetchAanmeldingWachtlijst = "Rapporten.Internal.FetchAanmeldingWachtlijst",
    FetchAanmeldingWachtlijstXlsx = "Rapporten.Internal.FetchAanmeldingWachtlijstXlsx",
    FetchAanmeldingWachtlijstSuccess = "Rapporten.Internal.FetchAanmeldingWachtlijstSuccess",
    FetchAanmeldingWachtlijstFailed = "Rapporten.Internal.FetchAanmeldingWachtlijstFailed",
    FetchContactenPerMedewerkerPerMaand = "Rapporten.Internal.FetchContactenPerMedewerkerPerMaand",
    FetchContactenPerMedewerkerPerMaandXlsx = "Rapporten.Internal.FetchContactenPerMedewerkerPerMaandXlsx",
    FetchContactenPerMedewerkerPerMaandSuccess = "Rapporten.Internal.FetchContactenPerMedewerkerPerMaandSuccess",
    FetchContactenPerMedewerkerPerMaandFailed = "Rapporten.Internal.FetchContactenPerMedewerkerPerMaandFailed",
    FetchContactenPerMaand = "Rapporten.Internal.FetchContactenPerMaand",
    FetchContactenPerMaandXlsx = "Rapporten.Internal.FetchContactenPerMaandXlsx",
    FetchContactenPerMaandSuccess = "Rapporten.Internal.FetchContactenPerMaandSuccess",
    FetchContactenPerMaandFailed = "Rapporten.Internal.FetchContactenPerMaandFailed",
    FetchContactenPerMaandGraph = "Rapporten.Internal.FetchContactenPerMaandGraph",
    FetchContactenPerMaandGraphSuccess = "Rapporten.Internal.FetchContactenPerMaandGraphSuccess",
    FetchContactenPerMaandGraphFailed = "Rapporten.Internal.FetchContactenPerMaandGraphFailed",
    FetchLopendeDossiers = "Rapporten.Internal.FetchLopendeDossiers",
    FetchLopendeDossiersXlsx = "Rapporten.Internal.FetchLopendeDossiersXlsx",
    FetchLopendeDossiersSuccess = "Rapporten.Internal.FetchLopendeDossiersSuccess",
    FetchLopendeDossiersFailed = "Rapporten.Internal.FetchLopendeDossiersFailed",
    FetchGewicht = "Raporten.Internal.FetchGewicht",
    FetchGewichtXlsx = "Raporten.Internal.FetchGewichtXlsx",
    FetchGewichtSuccess = "Raporten.Internal.FetchGewichtSuccess",
    FetchGewichtFailed = "Raporten.Internal.FetchGewichtFailed",
    FetchContactenPerMedewerkerPerDag = "Rapporten.Internal.FetchContactenPerMedewerkerPerDag",
    FetchContactenPerMedewerkerPerDagXlsx = "Rapporten.Internal.FetchContactenPerMedewerkerPerDagXlsx",
    FetchContactenPerMedewerkerPerDagSuccess = "Rapporten.Internal.FetchContactenPerMedewerkerPerDagSuccess",
    FetchContactenPerMedewerkerPerDagFailed = "Rapporten.Internal.FetchContactenPerMedewerkerPerDagFailed",
};

// Actions
export interface IFetchAanmeldingWachtlijst extends Action<ActionTypes> {
    type: ActionTypes.FetchAanmeldingWachtlijst,
    payload: { gebruikersId: string}
}
export interface IFetchAanmeldingWachtlijstXlsx extends Action<ActionTypes> {
    type: ActionTypes.FetchAanmeldingWachtlijstXlsx,
    payload: { gebruikersId: string }
}

export interface IFetchAanmeldingWachtlijstSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchAanmeldingWachtlijstSuccess,
    payload: { data: AanmeldingWachtlijst[] }
}

export interface IFetchAanmeldingWachtlijstFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchAanmeldingWachtlijstFailed,
    payload: { errorMessage: string }
}

export interface IFetchContactenPerMedewerkerPerMaand extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerMaand,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }
}
export interface IFetchContactenPerMedewerkerPerMaandXlsx extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerMaandXlsx,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }
}

export interface IFetchContactenPerMedewerkerPerMaandSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerMaandSuccess,
    payload: { data: ContactenPerMedewerkerPerMaand[] }
}

export interface IFetchContactenPerMedewerkerPerMaandFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerMaandFailed,
    payload: { errorMessage: string }
}

export interface IFetchContactenPerMaand extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMaand,
    payload: {startDate: Date, endDate: Date }
}


export interface IFetchContactenPerMaandXlsx extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMaandXlsx,
    payload: { startDate: Date, endDate: Date }
}

export interface IFetchContactenPerMaandSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMaandSuccess,
    payload: { data: ContactenPerMaand[] }
}

export interface IFetchContactenPerMaandFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMaandFailed,
    payload: { errorMessage: string }
}

export interface IFetchContactenPerMaandGraph extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMaandGraph,
    payload: { }
}

export interface IFetchContactenPerMaandGraphSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMaandGraphSuccess,
    payload: { data: ContactenPerMaandGraph[] }
}

export interface IFetchContactenPerMaandGraphFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMaandGraphFailed,
    payload: { errorMessage: string }
}

export interface IFetchLopendeDossiers extends Action<ActionTypes> {
    type: ActionTypes.FetchLopendeDossiers,
    payload: { gebruikersId: string }
}
export interface IFetchLopendeDossiersXlsx extends Action<ActionTypes> {
    type: ActionTypes.FetchLopendeDossiersXlsx,
    payload: { gebruikersId: string }
}

export interface IFetchLopendeDossiersSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchLopendeDossiersSuccess,
    payload: { data: LopendeDossiers[] }
}

export interface IFetchLopendeDossiersFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchLopendeDossiersFailed,
    payload: { errorMessage: string }
}

export interface IFetchGewicht extends Action<ActionTypes> {
    type: ActionTypes.FetchGewicht,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }
}

export interface IFetchGewichtXlsx extends Action<ActionTypes> {
    type: ActionTypes.FetchGewichtXlsx,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }
}

export interface IFetchGewichtSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchGewichtSuccess,
    payload: { data: Gewicht[] }
}

export interface IFetchGewichtFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchGewichtFailed,
    payload: { errorMessage: string }
}

export interface IFetchContactenPerMedewerkerPerDag extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerDag,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }
}

export interface IFetchContactenPerMedewerkerPerDagXlsx extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerDagXlsx,
    payload: { gebruikersId: string, startDate: Date, endDate: Date }
}

export interface IFetchContactenPerMedewerkerPerDagSuccess extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerDagSuccess,
    payload: { data: ContactenPerMedewerkerPerDag[] }
}

export interface IFetchContactenPerMedewerkerPerDagFailed extends Action<ActionTypes> {
    type: ActionTypes.FetchContactenPerMedewerkerPerDagFailed,
    payload: { errorMessage: string }
}


// Action Creators
export class ActionCreator {
    public static FetchAanmeldingWachtlijst(userId: string): IFetchAanmeldingWachtlijst {
        return {
            type: ActionTypes.FetchAanmeldingWachtlijst,
            payload: { gebruikersId: userId }
        };
    }
    public static FetchAanmeldingWachtlijstXlsx(userId: string): IFetchAanmeldingWachtlijstXlsx {
        return {
            type: ActionTypes.FetchAanmeldingWachtlijstXlsx,
            payload: { gebruikersId: userId }
        };
    }

    public static FetchAanmeldingWachtlijstSuccess(newData: AanmeldingWachtlijst[]): IFetchAanmeldingWachtlijstSuccess {
        return {
            type: ActionTypes.FetchAanmeldingWachtlijstSuccess,
            payload: { data: newData }
        }
    }

    public static FetchAanmeldingWachtlijstFailed(err: string): IFetchAanmeldingWachtlijstFailed {
        return {
            type: ActionTypes.FetchAanmeldingWachtlijstFailed,
            payload: { errorMessage: err }
        }
    }

    public static FetchContactenPerMedewerkerPerMaand(userId: string, startDateIn: Date, endDateIn: Date): IFetchContactenPerMedewerkerPerMaand {
        return {
            type: ActionTypes.FetchContactenPerMedewerkerPerMaand, payload: {gebruikersId: userId, startDate: startDateIn, endDate: endDateIn} }
    }
    public static FetchContactenPerMedewerkerPerMaandXlsx(userId: string, startDateIn: Date, endDateIn: Date): IFetchContactenPerMedewerkerPerMaandXlsx {
        return {
            type: ActionTypes.FetchContactenPerMedewerkerPerMaandXlsx, payload: { gebruikersId: userId, startDate: startDateIn, endDate: endDateIn }
        }
    }

    public static FetchContactenPerMedewerkerPerMaandSuccess(newData: ContactenPerMedewerkerPerMaand[]): IFetchContactenPerMedewerkerPerMaandSuccess {
        return {
            type: ActionTypes.FetchContactenPerMedewerkerPerMaandSuccess,
            payload: { data: newData }
        }
    }

    public static FetchContactenPerMedewerkerPerMaandFailed(err: string): IFetchContactenPerMedewerkerPerMaandFailed {
        return {
            type: ActionTypes.FetchContactenPerMedewerkerPerMaandFailed,
            payload: { errorMessage: err }
        }
    }

    public static FetchContactenPerMaand(startDateIn: Date, endDateIn: Date): IFetchContactenPerMaand {
        return {
            type: ActionTypes.FetchContactenPerMaand, payload: { startDate: startDateIn, endDate: endDateIn }
        }
    }    
    public static FetchContactenPerMaandXlsx(startDateIn: Date, endDateIn: Date): IFetchContactenPerMaandXlsx {
        return {
            type: ActionTypes.FetchContactenPerMaandXlsx, payload: { startDate: startDateIn, endDate: endDateIn }
        }
    }

    public static FetchContactenPerMaandSuccess(newData: ContactenPerMaand[]): IFetchContactenPerMaandSuccess {
        return {
            type: ActionTypes.FetchContactenPerMaandSuccess,
            payload: { data: newData }
        }
    }

    public static FetchContactenPerMaandFailed(err: string): IFetchContactenPerMaandFailed {
        return {
            type: ActionTypes.FetchContactenPerMaandFailed,
            payload: { errorMessage: err }
        }
    }

    public static FetchContactenPerMaandGraph(): IFetchContactenPerMaandGraph {
        return {
            type: ActionTypes.FetchContactenPerMaandGraph, payload: { }
        }
    }
    public static FetchContactenPerMaandGraphSuccess(newData: ContactenPerMaandGraph[]): IFetchContactenPerMaandGraphSuccess {
        return {
            type: ActionTypes.FetchContactenPerMaandGraphSuccess,
            payload: { data: newData }
        }
    }

    public static FetchContactenPerMaandGraphFailed(err: string): IFetchContactenPerMaandGraphFailed {
        return {
            type: ActionTypes.FetchContactenPerMaandGraphFailed,
            payload: { errorMessage: err }
        }
    }

    public static FetchLopendeDossiers(userId: string): IFetchLopendeDossiers {
        return {
            type: ActionTypes.FetchLopendeDossiers,
            payload: { gebruikersId: userId }
        }
    }
    public static FetchLopendeDossiersXlsx(userId: string): IFetchLopendeDossiersXlsx {
        return {
            type: ActionTypes.FetchLopendeDossiersXlsx,
            payload: { gebruikersId: userId }
        }
    }

    public static FetchLopendeDossiersSuccess(newData: LopendeDossiers[]): IFetchLopendeDossiersSuccess {
        return {
            type: ActionTypes.FetchLopendeDossiersSuccess,
            payload: { data: newData }
        }
    }

    public static FetchLopendeDossiersFailed(err: string): IFetchLopendeDossiersFailed {
        return {
            type: ActionTypes.FetchLopendeDossiersFailed,
            payload: { errorMessage: err }
        }
    }

    public static FetchGewicht(userId: string, startDateIn: Date, endDateIn: Date): IFetchGewicht {
        return { type: ActionTypes.FetchGewicht, payload: { gebruikersId: userId, startDate: startDateIn, endDate: endDateIn } }
    }

    public static FetchGewichtXlsx(userId: string, startDateIn: Date, endDateIn: Date): IFetchGewichtXlsx {
        return { type: ActionTypes.FetchGewichtXlsx, payload: { gebruikersId: userId, startDate: startDateIn, endDate: endDateIn } }
    }

    public static FetchGewichtSuccess(newData: Gewicht[]): IFetchGewichtSuccess {
        return {
            type: ActionTypes.FetchGewichtSuccess,
            payload: { data: newData }
        }
    }

    public static FetchGewichtFailed(err: string): IFetchGewichtFailed {
        return {
            type: ActionTypes.FetchGewichtFailed,
            payload: { errorMessage: err }
        }
    }

    public static FetchContactenPerMedewerkerPerDag(userId: string, startDateIn: Date, endDateIn: Date): IFetchContactenPerMedewerkerPerDag {
        return { type: ActionTypes.FetchContactenPerMedewerkerPerDag, payload: { gebruikersId: userId, startDate: startDateIn, endDate: endDateIn } }
    }

    public static FetchContactenPerMedewerkerPerDagXlsx(userId: string, startDateIn: Date, endDateIn: Date): IFetchContactenPerMedewerkerPerDagXlsx {
        return { type: ActionTypes.FetchContactenPerMedewerkerPerDagXlsx, payload: { gebruikersId: userId, startDate: startDateIn, endDate: endDateIn } }
    }

    public static FetchContactenPerMedewerkerPerDagSuccess(newData: ContactenPerMedewerkerPerDag[]): IFetchContactenPerMedewerkerPerDagSuccess {
        return {
            type: ActionTypes.FetchContactenPerMedewerkerPerDagSuccess,
            payload: { data: newData }
        }
    }

    public static FetchContactenPerMedewerkerPerDagFailed(err: string): IFetchContactenPerMedewerkerPerDagFailed {
        return {
            type: ActionTypes.FetchContactenPerMedewerkerPerDagFailed,
            payload: { errorMessage: err }
        }
    }


}


/******************************************************************
 * REDUCER
 *****************************************************************/
type ReducerActions = IFetchAanmeldingWachtlijst |
                      IFetchAanmeldingWachtlijstFailed |
                      IFetchAanmeldingWachtlijstSuccess |
                      IFetchContactenPerMedewerkerPerMaand |
                      IFetchContactenPerMedewerkerPerMaandSuccess |
                      IFetchContactenPerMedewerkerPerMaandFailed |
                      IFetchContactenPerMaand |
                      IFetchContactenPerMaandSuccess |
                      IFetchContactenPerMaandFailed |
                      IFetchContactenPerMaandGraph |
                      IFetchContactenPerMaandGraphSuccess |
                      IFetchContactenPerMaandGraphFailed |
                      IFetchLopendeDossiers |
                      IFetchLopendeDossiersSuccess |
                      IFetchLopendeDossiersFailed |
                      IFetchGewicht |
                      IFetchGewichtSuccess |
                      IFetchGewichtFailed |
                      IFetchContactenPerMedewerkerPerDag |
                      IFetchContactenPerMedewerkerPerDagFailed |
                      IFetchContactenPerMedewerkerPerDagSuccess;
export const reducer: Reducer<IState, ReducerActions> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FetchAanmeldingWachtlijst:
            return {
                ...state,
                AanmeldingWachtlijst: { ...state.AanmeldingWachtlijst, state: FetchState.Busy, errorMessage: "" }
            }
        case ActionTypes.FetchAanmeldingWachtlijstSuccess:
            return {
                ...state,
                AanmeldingWachtlijst: { ...state.AanmeldingWachtlijst, state: FetchState.Success, errorMessage: "", data: action.payload.data, fetchDate: new Date() }
            }
        case ActionTypes.FetchAanmeldingWachtlijstFailed:
            return {
                ...state,
                AanmeldingWachtlijst: { ...state.AanmeldingWachtlijst, state: FetchState.Error, errorMessage: action.payload.errorMessage }
            }

        case ActionTypes.FetchContactenPerMedewerkerPerMaand:
            return {
                ...state,
                ContactenPerMedewerkerPerMaand: { ...state.ContactenPerMedewerkerPerMaand, state: FetchState.Busy, errorMessage: "" }
            }
        case ActionTypes.FetchContactenPerMedewerkerPerMaandSuccess:
            return {
                ...state,
                ContactenPerMedewerkerPerMaand: { ...state.ContactenPerMedewerkerPerMaand, state: FetchState.Success, errorMessage: "", data: action.payload.data, fetchDate: new Date() }
            }
        case ActionTypes.FetchContactenPerMedewerkerPerMaandFailed:
            return {
                ...state,
                ContactenPerMedewerkerPerMaand: { ...state.ContactenPerMedewerkerPerMaand, state: FetchState.Error, errorMessage: action.payload.errorMessage }
            }

        case ActionTypes.FetchContactenPerMaand:
            return {
                ...state,
                ContactenPerMaand: { ...state.ContactenPerMaand, state: FetchState.Busy, errorMessage: "" }
            }
        case ActionTypes.FetchContactenPerMaandSuccess:
            return {
                ...state,
                ContactenPerMaand: { ...state.ContactenPerMaand, state: FetchState.Success, errorMessage: "", data: action.payload.data, fetchDate: new Date() }
            }
        case ActionTypes.FetchContactenPerMaandFailed:
            return {
                ...state,
                ContactenPerMaand: { ...state.ContactenPerMaand, state: FetchState.Error, errorMessage: action.payload.errorMessage }
            }

        case ActionTypes.FetchContactenPerMaandGraph:
            return {
                ...state,
                ContactenPerMaandGraph: { ...state.ContactenPerMaandGraph, state: FetchState.Busy, errorMessage: "" }
            }
        case ActionTypes.FetchContactenPerMaandGraphSuccess:
            return {
                ...state,
                ContactenPerMaandGraph: { ...state.ContactenPerMaandGraph, state: FetchState.Success, errorMessage: "", data: action.payload.data, fetchDate: new Date() }
            }
        case ActionTypes.FetchContactenPerMaandGraphFailed:
            return {
                ...state,
                ContactenPerMaandGraph: { ...state.ContactenPerMaandGraph, state: FetchState.Error, errorMessage: action.payload.errorMessage }
            }

        case ActionTypes.FetchLopendeDossiers:
            return {
                ...state,
                LopendeDossiers: { ...state.LopendeDossiers, state: FetchState.Busy, errorMessage: "" }
            }
        case ActionTypes.FetchLopendeDossiersSuccess:
            return {
                ...state,
                LopendeDossiers: { ...state.LopendeDossiers, state: FetchState.Success, errorMessage: "", data: action.payload.data, fetchDate: new Date() }
            }
        case ActionTypes.FetchLopendeDossiersFailed:
            return {
                ...state,
                LopendeDossiers: { ...state.LopendeDossiers, state: FetchState.Error, errorMessage: action.payload.errorMessage }
            }

        case ActionTypes.FetchGewicht:
            return {
                ...state,
                Gewicht: { ...state.Gewicht, state: FetchState.Busy, errorMessage: "" }
            }
        case ActionTypes.FetchGewichtSuccess:
            return {
                ...state,
                Gewicht: { ...state.Gewicht, state: FetchState.Success, errorMessage: "", data: action.payload.data, fetchDate: new Date() }
            }
        case ActionTypes.FetchGewichtFailed:
            return {
                ...state,
                Gewicht: { ...state.Gewicht, state: FetchState.Error, errorMessage: action.payload.errorMessage }
            }

        case ActionTypes.FetchContactenPerMedewerkerPerDag:
            return {
                ...state,
                ContactenPerMedewerkerPerDag: {... state.ContactenPerMedewerkerPerDag, state: FetchState.Busy, errorMessage: ""}
            }
        case ActionTypes.FetchContactenPerMedewerkerPerDagSuccess:
            return {
                ...state,
                ContactenPerMedewerkerPerDag: { ...state.ContactenPerMedewerkerPerDag, state: FetchState.Success, errorMessage: "", data: action.payload.data, fetchDate: new Date() }
            }
        case ActionTypes.FetchContactenPerMedewerkerPerDagFailed:
            return {
                ...state,
                ContactenPerMedewerkerPerDag: { ...state.ContactenPerMedewerkerPerDag, state: FetchState.Error, errorMessage: action.payload.errorMessage }
            }

        default:
            return state;
    }
}
