import * as OndersteuningsCirkelTrends from "./OndersteuningsCirkelTrends"


import {combineReducers} from "redux";
import {all, fork} from "redux-saga/effects";


/* State */
export interface IState  {
    OndersteuningsCirkelTrends: OndersteuningsCirkelTrends.IState,
}
export const InitialState: IState = {
    OndersteuningsCirkelTrends: OndersteuningsCirkelTrends.InitialState,
}

export const ActionCreators = {
    OndersteuningsCirkelTrends: OndersteuningsCirkelTrends.ActionCreators,
}
export const reducers = combineReducers<IState>({
    OndersteuningsCirkelTrends: OndersteuningsCirkelTrends.Reducers,
});

export function* sagas() {
    yield all(
        [
            fork(OndersteuningsCirkelTrends.Sagas),
    ]);
}
