import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../utils/callApi'
import * as Selectie from './'
import ActionTypes = Selectie.SelectieActionTypes;
import ActionsCreator = Selectie.SelectieActionCreator;

const API_ENDPOINT = "";

// The async function that needs to be called on a fetchAfrekening
function* handleFetch(action: Selectie.IFetchAllMedewerkers) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/Medewerkers/`);

        if (res.error) {
            yield put(ActionsCreator.FetchAllMedewerkersFailed(res.error));
        } else {
            yield put(ActionsCreator.FetchAllMedewerkersSuccess(res));
        }
    }
    catch (err)
    {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchAllMedewerkersFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchAllMedewerkersFailed("An unknown error has occured"));
        }
    }
}

// The watcher function, that transforms the above to a watcher that executes the above
function* watchFetchConnection() {
    yield takeEvery(ActionTypes.FetchAllMedewerkers, handleFetch);
}

// The fork to combine all the watchers in this file
function* localSaga() {
    yield all([fork(watchFetchConnection)]);
}

export default localSaga