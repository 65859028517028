// React-Redux Stuff
import * as React from "react";
import {Dispatch} from "redux"
import {connect} from "react-redux"
import {IApplicationState} from "../../../store"

// Local Redux State Stuff
import * as InternalReports from "../../../store/rapporten/internal"
import AanmeldingWachtlijst from "../../../entities/reports/AanmeldingWachtlijst"
import {FetchState} from "../../../entities/FetchState"
import {IReportData} from "../../../entities/reports/IReportData"

// Components
import AllMedewerkersDropDown from "../../selectie/AllMedewerkersDropDown"
import {Button, Alert} from "react-bootstrap"
import Medewerker from "../../../entities/Medewerker"
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

import StdRapport from '../common/StandaardRapportFull';

interface IPropsFromState {
    rapport: IReportData<AanmeldingWachtlijst>;
    selectedUser: Medewerker;
}

const mapStateToProps = ({rapporten, selectie}: IApplicationState) => ({
    rapport: rapporten.internal.AanmeldingWachtlijst,
    selectedUser: selectie.selectedMedewerker
});


interface IPropsFromDispatch {
    FetchReport: typeof InternalReports.ActionCreator.FetchAanmeldingWachtlijst
    FetchReportXlsx: typeof InternalReports.ActionCreator.FetchAanmeldingWachtlijstXlsx
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string) => dispatch(InternalReports.ActionCreator.FetchAanmeldingWachtlijst(userId)),
    FetchReportXlsx: (userId: string) => dispatch(InternalReports.ActionCreator.FetchAanmeldingWachtlijstXlsx(userId))
});


type AllProps = IPropsFromState & IPropsFromDispatch

class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);

        this.RequestReport = this.RequestReport.bind(this);
        this.download = this.download.bind(this);
    }

    public render() {

        return <StdRapport
            rapport={this.props.rapport}
            selectedUser={this.props.selectedUser}
            startDate={new Date()}
            endDate={new Date()} FetchReport={this.props.FetchReport}
            FetchReportXlsx={this.props.FetchReportXlsx}
            title="Dossiers op te starten"
            selectMedewerker={true}
            selectDate={false}

            columns={
                [
                    {
                        Header: "Medewerker", columns:
                            [
                                {Header: "Medewerker", accessor: "medewerker"}
                            ]
                    },
                    {
                        Header: "Aanmelding", columns:
                            [

                                {
                                    Header: "Aanmeldingsdatum",
                                    accessor: "aanmeldingsDatum",
                                    Cell: (p: any) =>
                                        <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                },
                                {Header: "Dossier", accessor: "dossierNummer"},
                                {
                                    Header: "Voorrangdossier",
                                    accessor: "voorrangDossierType",
                                    Cell: (p: any) => <span>{p.value == null ? "Nee" : p.value}</span>
                                }
                            ]
                    },
                    {
                        Header: "Client", columns:
                            [
                                {Header: "Naam", accessor: "clientNaam"},
                                {Header: "Leeftijd", accessor: "clientLeeftijd"},
                                {Header: "Domiciliegemeente", accessor: "domicilieGemeente"},
                                {Header: "Verblijfgemeente", accessor: "verblijfGemeente"}
                            ]
                    },
                    {
                        Header: "Status", columns:
                            [
                                {
                                    Header: "Aanmeldingsgesprek / Cas",
                                    accessor: "aanmeldingsGesprekCasDatum",
                                    Cell: (p: any) =>
                                        <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                },
                                {Header: "Reden niet opgestart", accessor: "redenNietOpgestart"},
                                {
                                    Header: "Vermoedelijke opstart",
                                    accessor: "vermoedelijkeOpstartDatum",
                                    Cell: (p: any) =>
                                        <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                },
                                {
                                    Header: "Vraag Op Pvb",
                                    accessor: "heeftVraagNaarOpPvb",
                                    Cell: (p: any) => <span>{p.value ? "Ja" : "Nee"}</span>
                                },
                                {
                                    Header: "Gevalideerd",
                                    accessor: "isGevalideerdeAanmelding",
                                    Cell: (p: any) => <span>{p.value ? "Ja" : "Nee"}</span>
                                }
                            ]
                    },
                ]
            }
        />

        const header = (<div>
            <h1>Rapport aanmeldingen wachtlijst</h1>
            <AllMedewerkersDropDown/>
            <Button disabled={this.props.rapport.state === FetchState.Busy}
                    onClick={this.RequestReport}>Bekijken</Button>

            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.download}>Download</Button>
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.rapport.state) {
            case FetchState.Success:
                body = <div>
                    <Alert variant="success" dismissible={true}><Alert.Heading>Rapport opgehaald</Alert.Heading>Gegevens
                        werden opgehaald op {this.props.rapport.fetchDate.toLocaleString()} </Alert>
                    <ReactTable
                        data={this.props.rapport.data}
                        columns={
                            [
                                {
                                    Header: "Medewerker", columns:
                                        [
                                            {Header: "Medewerker", accessor: "medewerker"}
                                        ]
                                },
                                {
                                    Header: "Aanmelding", columns:
                                        [

                                            {
                                                Header: "Aanmeldingsdatum",
                                                accessor: "aanmeldingsDatum",
                                                Cell: p =>
                                                    <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                            },
                                            {Header: "Dossier", accessor: "dossierNummer"},
                                            {
                                                Header: "Voorrangdossier",
                                                accessor: "voorrangDossierType",
                                                Cell: p => <span>{p.value == null ? "Nee" : p.value}</span>
                                            }
                                        ]
                                },
                                {
                                    Header: "Client", columns:
                                        [
                                            {Header: "Naam", accessor: "clientNaam"},
                                            {Header: "Domiciliegemeente", accessor: "domicilieGemeente"},
                                            {Header: "Verblijfgemeente", accessor: "verblijfGemeente"}
                                        ]
                                },
                                {
                                    Header: "Status", columns:
                                        [
                                            {
                                                Header: "Aanmeldingsgesprek / Cas",
                                                accessor: "aanmeldingsGesprekCasDatum",
                                                Cell: p =>
                                                    <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                            },
                                            {Header: "Reden niet opgestart", accessor: "redenNietOpgestart"},
                                            {
                                                Header: "Vermoedelijke opstart",
                                                accessor: "vermoedelijkeOpstartDatum",
                                                Cell: p =>
                                                    <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                            },
                                            {
                                                Header: "Vraag Op Pvb",
                                                accessor: "heeftVraagNaarOpPvb",
                                                Cell: p => <span>{p.value ? "Ja" : "Nee"}</span>
                                            },
                                            {
                                                Header: "Gevalideerd",
                                                accessor: "isGevalideerdeAanmelding",
                                                Cell: p => <span>{p.value ? "Ja" : "Nee"}</span>
                                            }
                                        ]
                                },
                            ]
                        }
                    />
                </div>;
                break;
            case FetchState.Busy:
                body = <Alert variant="primary">Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.rapport.errorMessage}</Alert>
            default:
                break;
        }

        return (
            <div>
                {header}
                <hr/>
                {body}
            </div>
        );
    }

    private download(): void {
        this.props.FetchReportXlsx(this.props.selectedUser.id);
    }

    private RequestReport() {
        this.props.FetchReport(this.props.selectedUser.id);
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
