import {IReportData, emptyReportData} from "../../entities/reports/IReportData";
import {FetchState} from "../../entities/FetchState";
import { Reducer } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GraphQl, IError } from "../../utils/callGraphQl";
import Either from "../../utils/Either";
import { flatMap } from "lodash";


/**********************************************************
 * API Data
 **********************************************************/
export interface IData {
    medewerkers: string[]
    id: number
    datum: Date
    contacts: number
    organisatie: string
    doelgroep: string[]
}
export const defaultOutreachList: IReportData<IData> = emptyReportData()

/**********************************************************
 * API Settings
 **********************************************************/

const graphQlQuery = `
{ 
    company { 
        medewerkers(id: "{{medewerkerId}}") {
            outreaches { 
                medewerker {
                    fullName
                } 
                id 
                datum 
                contacts
                organisatie 
                doelgroep { 
                    naam 
                } 
            } 
        }
    }
}`

interface IGraphQlQueryResponse {
    company: {
        medewerkers: Array<{
            outreaches: Array<{
                medewerker: Array<{
                    fullName: string
                }>
                id: number
                datum: Date
                contacts: number
                organisatie: string
                doelgroep: Array<{
                    naam: string
                }>
            }>
        }>
    }
}

function transformData(response: IGraphQlQueryResponse): IData[] {
    const data = flatMap(response.company.medewerkers, (m) => m.outreaches.map((o) => ({
        medewerkers: o.medewerker.map(m => m.fullName),
        id: o.id,
        datum: o.datum,
        contacts: o.contacts,
        organisatie: o.organisatie,
        doelgroep: o.doelgroep.map(d => d.naam)
    })));

    const uniqueData = [...new Map(data.map(item => [item.id, item])).values()]

    return uniqueData
}

/**********************************************************
 * State
 **********************************************************/

export interface IState {
    OutreachList: IReportData<IData>
}
export const InitialState: IState = {
    OutreachList: defaultOutreachList
}

/**********************************************************
 * Action Types
 **********************************************************/
export enum ActionTypes {
    FetchData = "outreach.OutreachList Fetch",
    FetchDataSuccess = "outreach.OutreachList FetchSuccess",
    FetchDataFailed = "outreach.OutreachList FetchFailed"
}

/**********************************************************
 * Actions & Creators
 **********************************************************/
export interface IFetchData {
    type: ActionTypes.FetchData,
    payload: { medewerkerId: string }
}
export interface IFetchDataSuccess {
    type: ActionTypes.FetchDataSuccess,
    payload: { data: IData[] }
}
export interface IFetchDataFailed {
    type: ActionTypes.FetchDataFailed,
    payload: { errorMessage: string }
}
type Actions = IFetchData | IFetchDataSuccess | IFetchDataFailed;

export class ActionCreators {
    public static FetchData(medewerkerId = ""): IFetchData {
        return { 
            type: ActionTypes.FetchData, 
            payload: { medewerkerId } 
        };
    }
    public static FetchDataSuccess(newData: IData[]): IFetchDataSuccess {
        return {
            type: ActionTypes.FetchDataSuccess,
            payload: { data: newData }
        };
    }
    public static FetchDataFailed(err: string): IFetchDataFailed {
        return {
            type: ActionTypes.FetchDataFailed,
            payload: { errorMessage: err }
        };
    }
}

/**********************************************************
 * Reducer
 **********************************************************/
export const Reducers: Reducer<IState, Actions> = (state = InitialState, action) => {
    switch (action.type) {
        case ActionTypes.FetchData:
            return { 
                ...state, 
                OutreachList: {
                    ...state.OutreachList,
                    state: FetchState.Busy
                }
            };
        case ActionTypes.FetchDataSuccess:
            return { 
                ...state, 
                OutreachList: {
                    ...state.OutreachList,
                    state: FetchState.Success,
                    data: action.payload.data,
                    fetchDate: new Date()
                }
            };
        case ActionTypes.FetchDataFailed:
            return { 
                ...state, 
                OutreachList: {
                    ...state.OutreachList,
                    state: FetchState.Error,
                    errorMessage: action.payload.errorMessage
                }
            };
        default:
            return state;
    }
}

/**********************************************************
 * Sagas
 **********************************************************/

function* handleIFetchData(action: IFetchData) {
    try {
        const response : Either<IError, IGraphQlQueryResponse>= yield call(GraphQl.call, graphQlQuery.replace("{{medewerkerId}}", action.payload.medewerkerId))
        yield response
            .FlatMap(success => put(ActionCreators.FetchDataSuccess(transformData(success)) as any))
            .Reduce(error => put(ActionCreators.FetchDataFailed(error.text) as any))
    } catch (err) {
        yield put(ActionCreators.FetchDataFailed(JSON.stringify(err)))
    }
}

function* watchAll() {
    yield takeEvery(ActionTypes.FetchData, handleIFetchData)
}
export function* sagas() {
    yield all([fork(watchAll)]);
}