import {IReportDataObject} from "../../entities/reports/IReportData";
import {FetchState} from "../../entities/FetchState";
import { Reducer } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GraphQl, IError } from "../../utils/callGraphQl";
import Either from "../../utils/Either";

/**********************************************************
 * API Data
 **********************************************************/
export interface IData {
    minDateEdit: Date
    minDateDelete: Date
    minDate: Date
    maxDate: Date
    minContacts: number
    maxContacts: number
}

export const dummyGetDate = (days: number) => {
    return new Date((new Date()).getTime() + (1000 * 60 * 60 * 24 * days));
}

export const defaultOutreachSetting: IData = {
    minDateEdit: dummyGetDate(-356),
    minDateDelete: dummyGetDate(-356),
    minDate: dummyGetDate(-356),
    maxDate: dummyGetDate(5),
    minContacts: 1,
    maxContacts: 3
}


export const defaultOutreachSettings: IReportDataObject<IData> = {
    data: defaultOutreachSetting,
    errorMessage: "",
    fetchDate: new Date(),
    state: FetchState.NotFetched
}


export const dummyOutreachSetting: IData = {...defaultOutreachSetting}
export const dummyOutreachSettings: IReportDataObject<IData> = {
    ...defaultOutreachSettings,
    data: dummyOutreachSetting,
    errorMessage: "dummy error message",
    fetchDate: new Date(),
    state: FetchState.Success
}

/**********************************************************
 * API Settings
 **********************************************************/

const graphQlQuery = `
{ 
    outreachSettings {
        minDateEdit
        minDateDelete
        minDate
        maxDate
        minContacts
        maxContacts
    }    
}`

interface IGraphQlQueryResponse {
    outreachSettings: {
        minDateEdit: Date
        minDateDelete: Date
        minDate: Date
        maxDate: Date
        minContacts: number
        maxContacts: number
    }
}

export const transformData = (data: IGraphQlQueryResponse): IData => {
    return {
        minDateEdit: data.outreachSettings.minDateEdit,
        minDateDelete: data.outreachSettings.minDateDelete,
        minDate: data.outreachSettings.minDate,
        maxDate: data.outreachSettings.maxDate,
        minContacts: data.outreachSettings.minContacts,
        maxContacts: data.outreachSettings.maxContacts,
    }
}

/**********************************************************
 * State
 **********************************************************/

export interface IState {
    Item: IReportDataObject<IData>
}
const InitialState: IState = {
    Item: defaultOutreachSettings
}

/**********************************************************
 * Action Types
 **********************************************************/

export enum ActionTypes {
    FetchData = "outreach.OutreachSetting Fetch",
    FetchDataSuccess = "outreach.OutreachSetting FetchSuccess",
    FetchDataFailed = "outreach.OutreachSetting FetchFailed"
}

/**********************************************************
 * Actions & Creators
 **********************************************************/
export interface IFetchData {
    type: ActionTypes.FetchData,
}
export interface IFetchDataSuccess {
    type: ActionTypes.FetchDataSuccess,
    payload: { data: IData }
}
export interface IFetchDataFailed {
    type: ActionTypes.FetchDataFailed,
    payload: { errorMessage: string }
}
type Actions = IFetchData | IFetchDataSuccess | IFetchDataFailed;

export class ActionCreators {
    public static FetchData(): IFetchData {
        return { 
            type: ActionTypes.FetchData, 
        };
    }
    public static FetchDataSuccess(newData: IData): IFetchDataSuccess {
        return {
            type: ActionTypes.FetchDataSuccess,
            payload: { data: newData }
        };
    }
    public static FetchDataFailed(err: string): IFetchDataFailed {
        return {
            type: ActionTypes.FetchDataFailed,
            payload: { errorMessage: err }
        };
    }
}

/**********************************************************
 * Reducer
 **********************************************************/
export const Reducers: Reducer<IState, Actions> = (state = InitialState, action) => {
    switch (action.type) {
        case ActionTypes.FetchData:
            return { 
                ...state, 
                Item: {
                    ...state.Item,
                    state: FetchState.Busy
                }
            };
        case ActionTypes.FetchDataSuccess:
            return { 
                ...state, 
                Item: {
                    ...state.Item,
                    state: FetchState.Success,
                    data: action.payload.data,
                    fetchDate: new Date()
                }
            };
        case ActionTypes.FetchDataFailed:
            return { 
                ...state, 
                Item: {
                    ...state.Item,
                    state: FetchState.Error,
                    errorMessage: action.payload.errorMessage
                }
            };
        default:
            return state;
    }
}

/**********************************************************
 * Sagas
 **********************************************************/

function* handleIFetchData(action: IFetchData) {
    try {
        const response : Either<IError, IGraphQlQueryResponse>= yield call(GraphQl.call, graphQlQuery)
        yield response
            .FlatMap(success => put(ActionCreators.FetchDataSuccess(transformData(success)) as any))
            .Reduce(error => put(ActionCreators.FetchDataFailed(error.text) as any))
    } catch (err) {
        yield put(ActionCreators.FetchDataFailed(JSON.stringify(err)))
    }
}

function* watchAll() {
    yield takeEvery(ActionTypes.FetchData, handleIFetchData)
}
export function* sagas() {
    yield all([fork(watchAll)]);
}