import * as React from "react";
import {Navbar, Nav, NavDropdown, Form, DropdownButton} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import ConnectedStatus from "./ConnectedStatus"
import {IApplicationState} from "../../store";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUnlockAlt} from "@fortawesome/free-solid-svg-icons/faUnlockAlt";

interface IPropsFromState {
    hasAccessToEvaluationForm: boolean;
}

const mapStateToProps = (state: IApplicationState) => ({
    hasAccessToEvaluationForm: state.connection.WhoAmI.data.data.hasAccessToEvaluationForm
})
const mapDispatchToProps = (dispatch: Dispatch) => ({})


class Header extends React.Component<IPropsFromState> {

    public render() {
        return (
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand>
                    <LinkContainer exact={true} to="/" activeClassName="active">
                        <Nav.Link>Rapporten</Nav.Link>
                    </LinkContainer>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse>
                    <Nav className="mr-auto">
                        <NavDropdown title="Vaph" id="nav-dropdown">
                            <LinkContainer to="/vaph/afrekening" activeClassName="active">
                                <NavDropdown.Item>Afrekening (VAPH)</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vaph/aanmeldingen" activeClassName="active">
                                <NavDropdown.Item>Aanmeldingen per periode (VAPH)</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vaph/projecten" activeClassName="active">
                                <NavDropdown.Item>Projecten</NavDropdown.Item>
                            </LinkContainer>
                            <DropdownButton className="dropdown-item" variant="link" id="dropdown-button-drop-right" title="VAPH Rapporten" drop="right">
                                <LinkContainer to="/dop/Traject" activeClassName="active">
                                    <NavDropdown.Item>Traject</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/dop/EersteContact" activeClassName="active">
                                    <NavDropdown.Item>Eerste Contact</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/dop/RegistratiesDoorMedewerkers" activeClassName="active">
                                    <NavDropdown.Item>Registraties Door Medewerkers</NavDropdown.Item>
                                </LinkContainer>
                            </DropdownButton>
                        </NavDropdown>




                        <NavDropdown title="D.O.P." id="nav-dropdown">
                            <LinkContainer to="/dop/takenlijst" activeClassName="active">
                                <NavDropdown.Item>Takenlijst</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/internal/aanmeldingenWachtlijst" activeClassName="active">
                                <NavDropdown.Item>Dossiers op te starten</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/wachtlijst" activeClassName="active">
                                <NavDropdown.Item>Wachtlijst</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/voorrangsdossiers" activeClassName="active">
                                <NavDropdown.Item>Voorrangsdossiers</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/achttienmaandenproces" activeClassName="active">
                                <NavDropdown.Item>Achttien maanden proces (VAPH)</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/achttienmaandenmax" activeClassName="active">
                                <NavDropdown.Item>Achttien maanden max (VAPH)</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/eenmaligContact" activeClassName="active">
                                <NavDropdown.Item>Eenmalig Contact</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/DopDuosLopendeClienten" activeClassName="active">
                                <NavDropdown.Item>Duo's Lopende Clienten</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/internal/lopendeDossiers" activeClassName="active">
                                <NavDropdown.Item>Lopende Dossiers</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/internal/contactenPerMedewerkerPerMaand" activeClassName="active">
                                <NavDropdown.Item>Contacten per medewerker per maand</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/internal/contactenPerMedewerkerPerDag" activeClassName="active">
                                <NavDropdown.Item>Contacten per medewerker per dag</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/internal/contactenPerMaand" activeClassName="active">
                                <NavDropdown.Item>Contacten per maand</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/ContactenPerMaandProjecten" activeClassName="active">
                                <NavDropdown.Item>Contacten per maand (Projecten)</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/internal/gewicht" activeClassName="active">
                                <NavDropdown.Item>Gewicht</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/dop/verdelingcas" activeClassName="active">
                                <NavDropdown.Item>Verdeling CAS</NavDropdown.Item>
                            </LinkContainer>
                            {this.props.hasAccessToEvaluationForm && <LinkContainer to="/dop/EvaluatieMedewerker" activeClassName="active">
                                <NavDropdown.Item>Evaluatie Medewerker <FontAwesomeIcon icon={faUnlockAlt} color="#cccccc" /> </NavDropdown.Item>
                            </LinkContainer>}
                            <LinkContainer to="/dop/DossiersPerGemeente" activeClassName="active">
                            <NavDropdown.Item>Dossiers per gemeente</NavDropdown.Item>
                        </LinkContainer>
                        </NavDropdown>




                        <NavDropdown id="nav-dropdown" title="Jaarverslag">
                            <DropdownButton className="dropdown-item" variant="link" id="dropdown-button-drop-right"
                                            title="Aanmelding" drop="right">
                                <LinkContainer to="/jaarrapport/AanmeldingAantalPerMaand" activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: Aantal aanmeldingen per maand (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AanmeldingAangemeldAfgeslotenZonderContact"
                                               activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: Afgesloten zonder contact (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz"
                                               activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: Leeftijd, geslacht, beperking, activiteit,
                                        verwijzer, gemeente, regio (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AanmeldingVerwijzer" activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: Verwijzer</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AanmeldingGeenVermoedenBeperking" activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: Geen vermoeden van Beperking</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AanmeldingOpPvb" activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: OP PVB (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/RedenHeraanmeldingPerClient" activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: Reden heraanmelding per client</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/RedenHeraanmelding" activeClassName="active">
                                    <NavDropdown.Item>Aanmeldingen: Reden heraanmelding</NavDropdown.Item>
                                </LinkContainer>
                            </DropdownButton>




                            <DropdownButton className="dropdown-item" variant="link" id="dropdown-button-drop-right"
                                            title="Proces" drop="right">
                                <LinkContainer to="/jaarrapport/ProcesDossierverloop" activeClassName="active">
                                    <NavDropdown.Item>Proces: Dossierverloop (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/ProcesLeeftijdGeslachtBeperkingActivieteitEnz"
                                               activeClassName="active">
                                    <NavDropdown.Item>Proces: Leeftijd, geslacht, beperking, activiteit, verwijzer,
                                        gemeente, regio (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/ProcesContactplaats" activeClassName="active">
                                    <NavDropdown.Item>Proces: Contactplaats (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/OndersteuningscirkelsInstroom" activeClassName="active">
                                    <NavDropdown.Item>Proces: Ondersteuningscirkels Instroom (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/ProcesGedetineerden" activeClassName="active">
                                    <NavDropdown.Item>Proces: Gedetineerden</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/ProcesBijkomendeUitdaging" activeClassName="active">
                                    <NavDropdown.Item>Proces: Bijkomende Uitdaging (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                            </DropdownButton>

                            <DropdownButton className="dropdown-item" variant="link" id="dropdown-button-drop-right"
                                            title="Afgesloten" drop="right">
                                <LinkContainer to="/jaarrapport/EenmaligContact" activeClassName="active">
                                    <NavDropdown.Item>Eenmalig Contact</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/VroegtijdigeStopzetting" activeClassName="active">
                                    <NavDropdown.Item>Vroegtijdige Stopzetting</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/OndersteuningsCirkelsInstroomEnUitstroom"
                                               activeClassName="active">
                                    <NavDropdown.Item>Ondersteuningscirkels Instroom en Uitstroom (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/OndersteuningsCirkelsUitstroom"
                                               activeClassName="active">
                                    <NavDropdown.Item>Ondersteuningscirkels Uitstroom (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AfgeslotenOndersteuningscirkelsWachtrij" activeClassName="active">
                                    <NavDropdown.Item>Afgesloten: Ondersteuningcirkels Uitstroom Wachtrij (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AfgeslotenOpPvb" activeClassName="active">
                                    <NavDropdown.Item>Afgesloten: OP PVB (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AfgeslotenGemiddeldeDuurProcesInMaanden" activeClassName="active">
                                    <NavDropdown.Item>Afgesloten: Gemiddelde duur proces in maanden (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/RedenVanAfsluitPerClient" activeClassName="active">
                                    <NavDropdown.Item>Afgesloten: Reden per client</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/RedenVanAfsluit" activeClassName="active">
                                    <NavDropdown.Item>Afgesloten: Reden van afsluit</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/jaarrapport/AfgeslotenAardVanHandicap" activeClassName="active">
                                    <NavDropdown.Item>Afgesloten: Aard van handicap (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                                                                <LinkContainer to="/jaarrapport/AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz"
                                               activeClassName="active">
                                    <NavDropdown.Item>Afgesloten: Leeftijd, geslacht, beperking, activiteit, verwijzer,
                                        gemeente, regio (VAPH)</NavDropdown.Item>
                                </LinkContainer>
                            </DropdownButton>
                        </NavDropdown>

                        <NavDropdown title="Grafieken" id="nav-dropdown">
                            <LinkContainer to="/charts/ContactenPerMaand" activeClassName="active">
                                <NavDropdown.Item>Contacten per maand</NavDropdown.Item>
                            </LinkContainer>
                            {this.props.hasAccessToEvaluationForm && <LinkContainer to="/charts/OndersteuningsCirkelsTrend" activeClassName="active">
                                <NavDropdown.Item>Evolutie Ondersteuningscirkels&nbsp;<FontAwesomeIcon icon={faUnlockAlt} color="#cccccc" /></NavDropdown.Item>
                            </LinkContainer>}
                        </NavDropdown>




                        <NavDropdown title="Outreach" id="nav-dropdown">
                            <LinkContainer to="/outreach/" activeClassName="active">
                                <NavDropdown.Item>Raadplegen</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                    </Nav>
                    <Form inline={true}>
                        <ConnectedStatus/>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
