import { FetchState } from "./FetchState"

export interface IDataState<T> {
    state: FetchState;
    data: T;
    fetchDate: Date;
    errorMessage: string;
}

export function emptyDataState<T>(): IDataState<T> {
    return {
        state: FetchState.NotFetched,
        data: {} as T,
        fetchDate: new Date(),
        errorMessage: ""
    };
}