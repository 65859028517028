// React-Redux Stuff
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { IApplicationState } from "../../../store"

// Local Redux State Stuff
import {FetchState} from "../../../entities/FetchState"
import {IReportData} from "../../../entities/reports/IReportData"
import * as ReportStuff from "../../../store/rapporten/vaph/Afrekening"

// Components
import StartEndDatePicker from "../../selectie/StartEndDatePickers"
import { Button, Alert, Table } from "react-bootstrap"
import DateRange from "../../../entities/DateRange"
import {IReportDownloadData} from "../../../entities/reports/IReportDownloadData";
import Medewerker from "../../../entities/Medewerker";

interface IPropsFromState {
    rapport: IReportData<ReportStuff.IReport>;
    download: IReportDownloadData;
    selectedDates: DateRange;
    selectedUser: Medewerker;
}
const mapStateToProps = ({ rapporten, selectie }: IApplicationState) => ({
    rapport: rapporten.vaph.Afrekening.Report,
    download: rapporten.vaph.Afrekening.ReportXlsx,
    selectedUser: selectie.selectedMedewerker,
    selectedDates: selectie.selectedDateRange
});


interface IPropsFromDispatch {
    FetchReport: typeof ReportStuff.ActionCreators.FetchReport,
    DownloadReport: typeof ReportStuff.ActionCreators.FetchReportXlsx,
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ReportStuff.ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ReportStuff.ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

type AllProps = IPropsFromState & IPropsFromDispatch

class VaphAfrekeningRapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);

        this.RequestReport = this.RequestReport.bind(this);
        this.download = this.download.bind(this);
    }

    public render() {
        const header = (<div>
            <h1>Rapport Afrekening VAPH (VAPH)</h1>
            <p>Selecteer een datumrange en klik op de optie die je wenst te gebruiken</p>
            <StartEndDatePicker />
            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.RequestReport}>Bekijken</Button>
            
            <Button disabled={this.props.download.state === FetchState.Busy} onClick={this.download}>Download voorbereiden</Button>

            {this.props.download.state === FetchState.Success &&
            <a href={window.URL.createObjectURL(this.props.download.data)} download={`AfrekeningVaph.xlsx`}>Klik hier om te downloaden.</a>}

            {this.props.download.state === FetchState.Error &&
            <Alert variant="danger">An error has occured: {this.props.download.errorMessage}</Alert>
            }
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.rapport.state) {
            case FetchState.Success:
                body = <div>
                           <Alert variant="success" dismissible={true}><Alert.Heading>Rapport opgehaald</Alert.Heading>Gegevens werden opgehaald op {this.props.rapport.fetchDate.toLocaleString()}</Alert>
                           <Table striped={true} bordered={true} hover={true}>
                               <thead>
                               <tr>
                                   <th>Medewerker</th>
                                   <th>Client / Dossier</th>
                                   <th>Begeleiding</th>
                                   <th>Ondersteuningsplan</th>
                               </tr>
                               </thead>
                               <tbody>
                               {this.props.rapport.data.map((item, index) => (
                                <tr>
                                    <td>{item.medewerkerClient}</td>
                                    <td>
                                        {item.naamClient} <small>(Rrn: {item.rijksregisterNummer})</small><br />
                                        <small>Dossier Dop: {item.dossierNummer} {item.aanmeldingGevalideerd === true ? " - Gevalideerd" : <Alert variant="danger">Niet gevalideerd</Alert>}<br />
                                        Vaph Nummer: {item.vaphNummer}<br /></small>
                                    </td>
                                    <td>
                                        Gestart {item.begindatumBegeleiding.toLocaleDateString()}  {item.einddatumBegeleiding != null ? ` tot ${item.einddatumBegeleiding.toLocaleDateString()}` : " en niet afgesoten in deze periode."}<br />
                                        Aantal begeleidingen in periode: {item.aantalBegeleidingen} {item.nietGevalideerdeBegeleidingen === 0 ? " - Gevalideerd" : <Alert variant="danger">{item.nietGevalideerdeBegeleidingen} Niet gevalideerde begeleidingen</Alert>}
                                    </td>
                                    <td>
                                        {item.ondersteuningsplanOpgesteld}
                                    </td>
                                </tr>
                            ))}
                               </tbody>
                           </Table>
                       </div>;
                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.rapport.errorMessage}</Alert>
            default:
                break;
        }


        return (
            <div>
                {header}
                <hr />
                {body}
            </div>
        );
    }

    private download(): void {
        this.props.DownloadReport(this.props.selectedUser.id, this.props.selectedDates.startDate, this.props.selectedDates.endDate);
    }

    private RequestReport() {
        this.props.FetchReport(this.props.selectedUser.id, this.props.selectedDates.startDate, this.props.selectedDates.endDate);
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VaphAfrekeningRapport);
