export default class Medewerker {
    public static GetAllMedewerker(): Medewerker {
        return { id: "", voornaam: "Alle", achternaam: "Medewerkers", email: "", fullName: "Alle Medewerkers", fullNamePrefixed: "Alle Medewerkers", isActive: true };
    }


    public id: string;
    public voornaam: string;
    public achternaam: string;
    public fullName:string;
    public fullNamePrefixed: string;
    public email: string;
    public isActive: boolean;
    
}
export type Medewerkers = Medewerker[];