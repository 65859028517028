// Core imports
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux";

// State & report config
import { IApplicationState } from "../../../store"
import * as InternalReports from "../../../store/rapporten/internal"
import { FetchState } from "../../../entities/FetchState"
import { IReportData } from "../../../entities/reports/IReportData"
import ContactenPerMaandGraph from "../../../entities/reports/ContactenPerMaandGraph"

// Components
import { VictoryChart, VictoryTheme, VictoryBar, VictoryLabel, VictoryStack, VictoryLegend, VictoryAxis } from 'victory';
import { Button, Alert, Container, Row, Col } from "react-bootstrap"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";

interface IPropsFromState {
    contactenPerMaandRapport: IReportData<ContactenPerMaandGraph>;
    // Add extra reports here
}
const mapStateToProps = (state: IApplicationState) => ({
    contactenPerMaandRapport: state.rapporten.internal.ContactenPerMaandGraph,
    // Add extra reports here
});


interface IPropsFromDispatch {
    FetchContactenPerMaandGraphReport: typeof InternalReports.ActionCreator.FetchContactenPerMaandGraph
    // Add extra report fetchers here
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchContactenPerMaandGraphReport: () => dispatch(InternalReports.ActionCreator.FetchContactenPerMaandGraph())
    // Add extra report fetchers here
});


type AllProps = IPropsFromState & IPropsFromDispatch

class HomePage extends React.Component<AllProps> {
    constructor(props: any, context: any) {
        super(props, context);

        this.getReportAllData();
    }
    
    public render() {

        const contactenPerMaandGraph = this.getContactenPerMaandGraph();

        return (
            <div>                
                <Container fluid={true}>
                    <Row>
                        <Col><div style={{padding: "50px"}}>{contactenPerMaandGraph}</div></Col>
                        <Col><div style={{padding: "50px"}}>&nbsp;</div></Col>
                        <Col><div style={{padding: "50px"}}>&nbsp;</div></Col>
                    </Row>
                </Container>
            </div>
        );
    }

    private getReportAllData() {
        this.props.FetchContactenPerMaandGraphReport();
    }

    private getContactenPerMaandGraph() {
        const header = (<div>
            <h4>
                Contacten per maand ({new Date().getFullYear()})
                &nbsp;
                <Button 
                    disabled={this.props.contactenPerMaandRapport.state === FetchState.Busy} 
                    onClick={this.props.FetchContactenPerMaandGraphReport}
                    size={"sm"}>
                        <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
            </h4>            
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.contactenPerMaandRapport.state) {
            case FetchState.Success:
                body = <div>
                    <VictoryChart
                        theme={VictoryTheme.grayscale}
                        domainPadding={{x: [20, 20], y: [0, 75]}}
                        padding={{ top: 50, bottom: 50, left: 100, right: 5 }}
                        height={500}>
                            
                        <VictoryLegend x={125} y={10}
                            centerTitle={true}
                            orientation="horizontal"
                            gutter={25}
                            style={{ border: { stroke: "#333" }, title: { fontSize: 10 }, labels: { fontSize: 10 } }}
                            data={[
                            { name: "Aantal Contacten", symbol: { fill: "#940031" } },
                            // { name: "Aantal Dossiers", symbol: { fill: "orange" } }
                            ]}
                        />

                        <VictoryAxis
                            dependentAxis={true}
                            style={{ tickLabels: { fontSize: "10px" } }}                            
                            />
                        
                        <VictoryAxis                        
                            dependentAxis={false}
                            tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                            tickFormat={["JAN", "FEB", "MAA", "APR", "MEI", "JUN", "JUL", "AUG", "SEP", "OKT", "NOV", "DEC"].reverse()}
                            style={{ tickLabels: { fontSize: 10 } }}
                            />                        
                        
                        <VictoryStack
                            colorScale={ ["#940031", "orange"] }
                            horizontal={true}>

                            <VictoryBar 
                                data={this.props.contactenPerMaandRapport.data}
                                x="maandStr"
                                y="aantalContacten"
                                sortKey={"maand"}
                                sortOrder={"descending"}

                                horizontal={true}
                                style={{ data: { fillOpacity: 0.9 }, labels: { fill: "#fff", fontSize: 9 } }}
                                barRatio={0.8}
                                animate={{
                                    duration: 2000,
                                    onLoad: { duration: 1000 }
                                    }}

                                labels={({ datum }) => datum.aantalContacten > 0 ? datum.aantalContacten : "" }
                                labelComponent={ <VictoryLabel dx={-5} labelPlacement={"vertical"} textAnchor={"end"} />}
                                
                            />

                            {/* <VictoryBar 
                                data={this.props.contactenPerMaandRapport.data}
                                x="maandStr"
                                y="aantalDossiers"
                                sortKey={"maand"}
                                sortOrder={"descending"}

                                horizontal={true}
                                style={{ data: { fillOpacity: 0.9 }, labels: { fill: "#fff", fontSize: 9 } }}
                                barRatio={0.8}
                                animate={{
                                    duration: 2000,
                                    onLoad: { duration: 1000 }
                                    }}

                                labels={({ datum }) => datum.aantalDossiers > 0 ? datum.aantalDossiers : "" }

                                labelComponent={ <VictoryLabel dx={-5} labelPlacement={"vertical"} textAnchor={"end"} />}
                            /> */}

                        </VictoryStack>
                    </VictoryChart>
                    
                </div>;

                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.contactenPerMaandRapport.errorMessage}</Alert>
            default:
                break;
        }

        return (
            <div>
                {header}
                {body}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);