import { IReportData } from "src/entities/reports/IReportData";
import { FetchState } from "../../../entities/FetchState"
import { IReport, IDuoError, ActionCreators } from "../../../store/rapporten/dop/DopDuosLopendeClienten";
import Medewerker from "../../../entities/Medewerker";
import {IApplicationState} from "../../../store";
import {Dispatch} from "redux";
import * as React from "react";
import StdRapport from "../common/StandaardRapportFull";
import {connect} from "react-redux";

// Setup Title
const reportTitle = "Duo's Lopende Clienten";
const selectDates = true;
const selectMedewerker = true;

// Mapping Dynamic Properties
interface IPropsFromState {
    rapport: IReportData<IReport>;
    errors: IDuoError[];
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = (state: IApplicationState) => ({
    rapport: state.rapporten.dop.DopDuosLopendeClienten.Report,
    errors: state.rapporten.dop.DopDuosLopendeClienten.Errors,
    selectedUser: state.selectie.selectedMedewerker,
    startDate: state.selectie.selectedDateRange.startDate,
    endDate: state.selectie.selectedDateRange.endDate
});

// Mapping Event Dispatcher
interface IPropsFromDispatch {
    FetchReport: typeof ActionCreators.FetchReport
    DownloadReport: typeof ActionCreators.FetchReportXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

// Setup Report
type AllProps = IPropsFromState & IPropsFromDispatch
class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
        console.log(this.props.rapport);
        if (this.props.errors && this.props.errors.length > 0) {
            const someShit: IReportData<IDuoError> = {
                data: this.props.errors,
                fetchDate: new Date(),
                state: FetchState.Success,
                errorMessage: ""
            };
            return (
                <StdRapport
                    title="Foutieve data gevonden"
                    selectDate={selectDates}
                    selectMedewerker={selectMedewerker}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    selectedUser={this.props.selectedUser}
                    rapport={someShit}
                    FetchReport={this.props.FetchReport}
                    FetchReportXlsx={this.props.DownloadReport}
                    columns={
                        [
                            {
                                Header: "Contacten", columns:
                                    [
                                        { Header: "Client", accessor: "clientName" },
                                        { Header: "Contact datum", accessor: "contactDate" },
                                        { Header: "Aantal Medewerkers", accessor: "aantalMedewerkers" },
                                    
                                ]
                            }
                        ]
                    }
                />
            );
        } else {
            return (
                <StdRapport
                    title={reportTitle}
                    selectDate={selectDates}
                    selectMedewerker={selectMedewerker}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    selectedUser={this.props.selectedUser}
                    rapport={this.props.rapport}
                    FetchReport={this.props.FetchReport}
                    FetchReportXlsx={this.props.DownloadReport}
                    columns={
                        [
                            {
                                Header: "Dossier", columns:
                                [
                                    { Header: "Duo Begeleiding Medewerker", accessor: "duoBegeleidingMedewerker" },
                                    { Header: "Hoofd Medewerker Contact", accessor: "hoofdMedewerkerContact" },
                                    { Header: "Client", accessor: "naamClient" },
                                    { Header: "Aantal Contacten", accessor: "aantalContacten" },
                                    { Header: "Afsluitingsdatum", accessor: "afsluitDatum", Cell: (p: any) => <span>{p.value ? "Ja" : "Nee"}</span> }
                                ]
                            }
                        ]
                    }
                />
            );
        }
        
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
