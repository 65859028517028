import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi, downloadFile } from '../../../utils/callApi'
import * as Internal from './'
import ActionTypes = Internal.ActionTypes;
import ActionsCreator = Internal.ActionCreator;

const API_ENDPOINT = "";

// The async function that needs to be called on a fetchAfrekening
function* handleIFetchAanmeldingWachtlijst(action: Internal.IFetchAanmeldingWachtlijst) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/Dop/GetAanmeldingWachtlijst?gebruikerId=${action.payload.gebruikersId}`);

        if (res.error) {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed(res.error));
        } else {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstSuccess(res));
        }
    }
    catch (err)
    {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("An unknown error has occured"));
        }
    }
}
function* handleIFetchAanmeldingWachtlijstXlsx(action: Internal.IFetchAanmeldingWachtlijstXlsx) {
    try {
        // call the thing
        yield call(downloadFile, 'get', `/Dop/GetAanmeldingWachtlijstXlsx?gebruikerId=${action.payload.gebruikersId}`, "DossiersOpTeStarten.xlsx");
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("An unknown error has occured"));
        }
    }
}



// The async function that needs to be called on a fetchAfrekening
function* handleIFetchContactenPerMedewerkerPerMaand(action: Internal.IFetchContactenPerMedewerkerPerMaand) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/Dop/GetContactenPerMedewerkerPerMaand?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`);

        if (res.error) {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerMaandFailed(res.error));
        } else {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerMaandSuccess(res));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerMaandFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerMaandFailed("An unknown error has occured"));
        }
    }
}
function* handleIFetchContactenPerMedewerkerPerMaandXlsx(action: Internal.IFetchContactenPerMedewerkerPerMaandXlsx) {
    try {
        // call the thing
        yield call(downloadFile, 'get', `/Dop/GetContactenPerMedewerkerPerMaandXlsx?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`, "ContactenPerMedewerkerPerMaand.xlsx");
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("An unknown error has occured"));
        }
    }
}

// The async function that needs to be called on a fetchAfrekening
function* handleIFetchContactenPerMaand(action: Internal.IFetchContactenPerMaand) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/Dop/GetContactenPerMaand?startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`);

        if (res.error) {
            yield put(ActionsCreator.FetchContactenPerMaandFailed(res.error));
        } else {
            yield put(ActionsCreator.FetchContactenPerMaandSuccess(res));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchContactenPerMaandFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchContactenPerMaandFailed("An unknown error has occured"));
        }
    }
}
function* handleIFetchContactenPerMaandXlsx(action: Internal.IFetchContactenPerMaandXlsx) {
    try {
        // call the thing
        yield call(downloadFile, 'get', `/Dop/GetContactenPerMaandXlsx?startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`, "ContactenPerMaand.xlsx");
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("An unknown error has occured"));
        }
    }
}
function* handleIFetchContactenPerMaandGraph(action: Internal.IFetchContactenPerMaandGraph) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/chart/GetContactenPerMaand`);

        if (res.error) {
            yield put(ActionsCreator.FetchContactenPerMaandGraphSuccess(res.error));
        } else {
            yield put(ActionsCreator.FetchContactenPerMaandGraphSuccess(res));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchContactenPerMaandGraphFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchContactenPerMaandGraphFailed("An unknown error has occured"));
        }
    }
}

// The async function that needs to be called on a fetchAfrekening
function* handleIFetchLopendeDossiers(action: Internal.IFetchLopendeDossiers) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/Dop/GetLopendeDossiers?gebruikerId=${action.payload.gebruikersId}`);

        if (res.error) {
            yield put(ActionsCreator.FetchLopendeDossiersFailed(res.error));
        } else {
            yield put(ActionsCreator.FetchLopendeDossiersSuccess(res));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchLopendeDossiersFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchLopendeDossiersFailed("An unknown error has occured"));
        }
    }
}
function* handleIFetchLopendeDossiersXlsx(action: Internal.IFetchLopendeDossiersXlsx) {
    try {
        // call the thing
        yield call(downloadFile, 'get', `/Dop/GetLopendeDossiersXlsx?gebruikerId=${action.payload.gebruikersId}`, "LopendeDossiers.xlsx");
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchAanmeldingWachtlijstFailed("An unknown error has occured"));
        }
    }
}

// The async function that needs to be called on a fetchAfrekening
function* handleIFetchGewicht(action: Internal.IFetchGewicht) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/Dop/GetGewicht?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`);

        if (res.error) {
            yield put(ActionsCreator.FetchGewichtFailed(res.error));
        } else {
            yield put(ActionsCreator.FetchGewichtSuccess(res));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchGewichtFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchGewichtFailed("An unknown error has occured"));
        }
    }
}
function* handleIFetchGewichtXlsx(action: Internal.IFetchGewichtXlsx) {
    try {
        // call the thing
        yield call(downloadFile, 'get', `/Dop/GetGewichtXlsx?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`, "Gewicht.xlsx");
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchGewichtFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchGewichtFailed("An unknown error has occured"));
        }
    }
}


// The async function that needs to be called on a fetchAfrekening
function* handleIFetchContactenPerMedewerkerPerDag(action: Internal.IFetchContactenPerMedewerkerPerDag) {
    try {
        // call the thing
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/Dop/GetContactenPerMedewerkerPerDag?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`);

        if (res.error) {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerDagFailed(res.error));
        } else {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerDagSuccess(res));
        }
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerDagFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerDagFailed("An unknown error has occured"));
        }
    }
}
function* handleIFetchContactenPerMedewerkerPerDagXlsx(action: Internal.IFetchContactenPerMedewerkerPerDagXlsx) {
    try {
        // call the thing
        yield call(downloadFile, 'get', `/Dop/GetContactenPerMedewerkerPerDagXlsx?gebruikerId=${action.payload.gebruikersId}&startTime=${encodeURI(action.payload.startDate.toISOString())}&endTime=${encodeURI(action.payload.endDate.toISOString())}`, "ContactenPerMedewerkerPerDag.xlsx");
    }
    catch (err) {
        if (err instanceof Error) {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerDagFailed("Error" + err.message + " - " + err.stack!));
        } else {
            yield put(ActionsCreator.FetchContactenPerMedewerkerPerDagFailed("An unknown error has occured"));
        }
    }
}



// The watcher function, that transforms the above to a watcher that executes the above
function* watchFetchConnection() {
    yield takeEvery(ActionTypes.FetchAanmeldingWachtlijst, handleIFetchAanmeldingWachtlijst);   
    yield takeEvery(ActionTypes.FetchAanmeldingWachtlijstXlsx, handleIFetchAanmeldingWachtlijstXlsx);
    yield takeEvery(ActionTypes.FetchContactenPerMedewerkerPerMaand, handleIFetchContactenPerMedewerkerPerMaand);
    yield takeEvery(ActionTypes.FetchContactenPerMedewerkerPerMaandXlsx, handleIFetchContactenPerMedewerkerPerMaandXlsx);
    yield takeEvery(ActionTypes.FetchContactenPerMaand, handleIFetchContactenPerMaand);
    yield takeEvery(ActionTypes.FetchContactenPerMaandGraph, handleIFetchContactenPerMaandGraph);
    yield takeEvery(ActionTypes.FetchContactenPerMaandXlsx, handleIFetchContactenPerMaandXlsx);
    yield takeEvery(ActionTypes.FetchLopendeDossiers, handleIFetchLopendeDossiers);
    yield takeEvery(ActionTypes.FetchLopendeDossiersXlsx, handleIFetchLopendeDossiersXlsx);
    yield takeEvery(ActionTypes.FetchGewicht, handleIFetchGewicht);
    yield takeEvery(ActionTypes.FetchGewichtXlsx, handleIFetchGewichtXlsx);
    yield takeEvery(ActionTypes.FetchContactenPerMedewerkerPerDag, handleIFetchContactenPerMedewerkerPerDag);
    yield takeEvery(ActionTypes.FetchContactenPerMedewerkerPerDagXlsx, handleIFetchContactenPerMedewerkerPerDagXlsx);
}

// The fork to combine all the watchers in this file
function* localSaga() {
    yield all([fork(watchFetchConnection)]);
}

export default localSaga