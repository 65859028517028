import { IReportData } from "src/entities/reports/IReportData";
import { IReport, ActionCreators } from "../../../store/rapporten/vaph/Projecten";
import Medewerker from "../../../entities/Medewerker";
import {IApplicationState} from "../../../store";
import {Dispatch} from "redux";
import * as React from "react";
import StdRapport from "../common/StandaardRapportFullImprovedDownload";
import {connect} from "react-redux";
import {IReportDownloadData} from "../../../entities/reports/IReportDownloadData";

// Setup Title
const reportTitle = "Projecten";
const selectDates = true;
const selectMedewerker = false;
const downloadName = "Projecten.xlsx";

// Mapping Dynamic Properties
interface IPropsFromState {
    rapport: IReportData<IReport>;
    download: IReportDownloadData;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = (state: IApplicationState) => ({
    rapport: state.rapporten.vaph.Projecten.Report,
    download: state.rapporten.vaph.Projecten.ReportXlsx,
    selectedUser: state.selectie.selectedMedewerker,
    startDate: state.selectie.selectedDateRange.startDate,
    endDate: state.selectie.selectedDateRange.endDate
});

// Mapping Event Dispatcher
interface IPropsFromDispatch {
    FetchReport: typeof ActionCreators.FetchReport
    DownloadReport: typeof ActionCreators.FetchReportXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

// Setup Report
type AllProps = IPropsFromState & IPropsFromDispatch
class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
        return(
            <StdRapport
                title={reportTitle}
                selectDate={selectDates}
                selectMedewerker={selectMedewerker}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedUser={this.props.selectedUser}
                rapport={this.props.rapport}
                download={this.props.download}
                downloadName={downloadName}
                FetchReport={this.props.FetchReport}
                FetchReportXlsx={this.props.DownloadReport}
                columns={
                    [
                        {
                            Header: "Project", columns:
                            [
                                {Header: "Project naam", accessor: "projectNaam"}
                            ]
                        },
                        {
                            Header: "Dossier", columns: 
                                [
                                    { Header: "Medewerker", accessor: "medewerkerClient" },
                                    { Header: "Client", accessor: "naamClient" },
                                    { Header: "Rijksregisternummer", accessor: "rijksregisternummerClient" },
                                    { Header: "Aanmeldingsdatum", accessor: "aanmeldingsdatum", Cell: (p: any) => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                                ]
                        },
                        {
                            Header: "Contact", columns:
                                [
                                    { Header: "Datum contact", accessor: "contactDatum", Cell: (p: any) => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                                    { Header: "Aantal contacten", accessor: "aantalContacten" },
                                ]
                        }
                    ]
                }
            />
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
