// React-Redux Stuff
import * as React from "react";
import {Dispatch} from "redux"
import {connect} from "react-redux"
import {IApplicationState} from "../../../store"

// Local Redux State Stuff
import {FetchState} from "../../../entities/FetchState"
import {IReportData} from "../../../entities/reports/IReportData"
import * as ReportStuff from "../../../store/rapporten/vaph/AanmeldingenPerPeriode"

// Components
import StartEndDatePicker from "../../selectie/StartEndDatePickers"
import {Alert, Button,} from "react-bootstrap"
import DateRange from "../../../entities/DateRange"
import ReactTable from "react-table";
import Medewerker from "../../../entities/Medewerker";
import {IReportDownloadData} from "../../../entities/reports/IReportDownloadData";

interface IPropsFromState {
    rapport: IReportData<ReportStuff.IReport>;
    download: IReportDownloadData;
    selectedDates: DateRange;
    selectedUser: Medewerker;
}
const mapStateToProps = ({ rapporten, selectie }: IApplicationState) => ({
    rapport: rapporten.vaph.AanmeldingenPerPeriode.Report,
    download: rapporten.vaph.AanmeldingenPerPeriode.ReportXlsx,
    selectedUser: selectie.selectedMedewerker,
    selectedDates: selectie.selectedDateRange
});


interface IPropsFromDispatch {
    FetchReport: typeof ReportStuff.ActionCreators.FetchReport,
    DownloadReport: typeof ReportStuff.ActionCreators.FetchReportXlsx,
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ReportStuff.ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ReportStuff.ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

type AllProps = IPropsFromState & IPropsFromDispatch

class VaphAfrekeningRapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);

        this.RequestReport = this.RequestReport.bind(this);
        this.download = this.download.bind(this);
    }

    public render() {
        const header = (<div>
            <h1>Rapport Aanmeldingen per periode VAPH (VAPH)</h1>
            <p>Selecteer een datumrange en klik op de optie die je wenst te gebruiken</p>
            <StartEndDatePicker />
            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.RequestReport}>Bekijken</Button>
            
            <Button disabled={this.props.download.state === FetchState.Busy} onClick={this.download}>Download voorbereiden</Button>
            
            {this.props.download.state === FetchState.Success && 
                <a href={window.URL.createObjectURL(this.props.download.data)} download={`AanmeldingenPerPeriodeVaph.xlsx`}>Klik hier om te downloaden.</a>}

            {this.props.download.state === FetchState.Error &&
                <Alert variant="danger">An error has occured: {this.props.download.errorMessage}</Alert>
            }
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.rapport.state) {
            case FetchState.Success:
                body = <div>
                           <Alert variant="success" dismissible={true}><Alert.Heading>Rapport opgehaald</Alert.Heading>Gegevens werden opgehaald op {this.props.rapport.fetchDate.toLocaleString()}</Alert>
                        
                    <ReactTable
                        data={this.props.rapport.data}
                        columns={
                            [
                                {
                                    Header: "Medewerker", columns:
                                        [
                                            { Header: "Medewerker", accessor: "medewerker" }
                                        ]
                                },
                                {
                                    Header: "Aanmelding", columns:
                                        [

                                            {
                                                Header: "Aanmeldingsdatum", accessor: "aanmeldingsDatum", Cell: p => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                            },
                                            { Header: "Dossier", accessor: "dossierNummer" }
                                        ]
                                },
                                {
                                    Header: "Client", columns:
                                        [
                                            { Header: "Naam", accessor: "naamClient" },
                                        ]
                                },
                                {
                                    Header: "Status", columns:
                                        [
                                            { Header: "Aanmeldingsgesprek / Cas", accessor: "aanmeldingsGesprekCasDatum", Cell: p => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                                            { Header: "Heeft Contacten", accessor: "heeftContacten", Cell: p => <span>{p.value ? "Ja" : "Nee"}</span> }
                                        ]
                                },
                            ]
                        }
                    />

                       </div>;
                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.rapport.errorMessage}</Alert>
            default:
                break;
        }


        return (
            <div>
                {header}
                <hr />
                {body}
            </div>
        );
    }

    private download(): void {
        this.props.DownloadReport(this.props.selectedUser.id, this.props.selectedDates.startDate, this.props.selectedDates.endDate);
    }

    private RequestReport() {
        this.props.FetchReport(this.props.selectedUser.id, this.props.selectedDates.startDate, this.props.selectedDates.endDate);
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VaphAfrekeningRapport);
