import * as Internal from "./internal"
import internalSagas from "./internal/sagas"

import { combineReducers } from "redux"
import { all, fork } from 'redux-saga/effects'

import * as Jaarverslag from './jaarverslag'
import * as Dop from "./dop"
import * as Vaph from "./vaph"
import * as Charts from "./charts"

// Mid level state
export interface IRapportenState {
    vaph: Vaph.IState,
    internal: Internal.IState,
    jaarverslag: Jaarverslag.IState,
    dop: Dop.IState,
    charts: Charts.IState,
}

// Root reducer, containing all reducers
export const rootReducer = combineReducers<IRapportenState>({
    vaph: Vaph.reducers,
    internal: Internal.reducer,
    jaarverslag: Jaarverslag.reducers,
    dop: Dop.reducers,
    charts: Charts.reducers,
});

export const ActionCreators = {
    Jaarverslag: Jaarverslag.ActionCreators,
    Dop: Dop.ActionCreators,
    Vaph: Vaph.ActionCreators,
    Charts: Charts.ActionCreators,
}

// Root saga, containing all sagas
export function* midSaga() {
    yield all([
        fork(Vaph.sagas),
        fork(internalSagas),
        fork(Jaarverslag.sagas),
        fork(Dop.sagas),
        fork(Charts.sagas)]);
}
