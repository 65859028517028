import {FetchState} from "../FetchState";

export interface IReportDownloadData {
    state: FetchState;
    data: Blob;
    fetchDate: Date;
    errorMessage: string;
}

export function emptyReportDownloadData(): IReportDownloadData {
    return {
        state: FetchState.NotFetched,
        data: new Blob(),
        fetchDate: new Date(),
        errorMessage: ""
    };
}