import * as React from "react";
import { Route } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { Store } from 'redux'
import { Provider } from 'react-redux'
import App from "./components/App";
import { IApplicationState, history } from "./store";

// Common component imports
import HomePage from "./components/home/HomePage";
import Projecten from "./components/rapporten/vaph/Projecten"
import AboutPage from "./components/about/AboutPage";
import Takenlijst from "./components/rapporten/dop/Takenlijst";

// VAPH reports
import VaphAfrekeningRapport from "./components/rapporten/vaph/afrekening"
import VaphAanmeldingenRapport from "./components/rapporten/vaph/AanmeldingenPerPeriode"

// Internal reports imports
import AanmeldingenWachtlijst from "./components/rapporten/internal/AanmeldingenWachtlijstRapport"
import ContactenPerMedewerkerPerMaand from "./components/rapporten/internal/ContactenPerMedewerkerPerMaandRapport"
import ContactenPerMaand from "./components/rapporten/internal/ContactenPerMaandRapport"
import LopendeDossiersRapport from "./components/rapporten/internal/LopendeDossiersRapport"
import GewichtRapport from "./components/rapporten/internal/GewichtRapport"
import ContactenPerMedewerkerPerDagRapport from "./components/rapporten/internal/ContactenPerMedewerkerPerDagRapport"

// DOP reports imports
import Voorrangdossiers from "./components/rapporten/dop/Voorrangdossiers";
import AchttienmaandenMax from "./components/rapporten/dop/AchttienmaandenMax";
import AchttienmaandenProces from "./components/rapporten/dop/AchttienmaandenProces";
import EenmaligContactDop from "./components/rapporten/dop/EenmaligContact";
import DopDuosLopendeClienten from "./components/rapporten/dop/DopDuosLopendeClienten";
import Traject from "./components/rapporten/dop/Traject";
import EersteContact from "./components/rapporten/dop/EersteContact";
import RegistratiesDoorMedewerkers from "./components/rapporten/dop/RegistratiesDoorMedewerkers";
import EvaluatieMedewerker from "./components/rapporten/dop/EvaluatieMedewerker";
import DossiersPerGemeente from "./components/rapporten/dop/DossiersPerGemeente";
import ContactenPerMaandProjecten from "./components/rapporten/dop/ContactenPerMaandProjecten"
import Wachtlijst from "./components/rapporten/dop/Wachtlijst";

// Jaarverslag imports
import JaarrapportAanmeldingAantalPerMaand from "./components/rapporten/jaarverslag/AanmeldingAantalPerMaand"
import AanmeldingAangemeldAfgeslotenZonderContact from "./components/rapporten/jaarverslag/AanmeldingAangemeldAfgeslotenZonderContactReport"
import AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz from "./components/rapporten/jaarverslag/AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz"
import AanmeldingVerwijzer from "./components/rapporten/jaarverslag/AanmeldingVerwijzer"
import ProcesDossierverloop from "./components/rapporten/jaarverslag/ProcesDossierverloop"
import EenmaligContactJaarverslag from "./components/rapporten/jaarverslag/EenmaligContact"
import VroegtijdigeStopzetting from "./components/rapporten/jaarverslag/VroegtijdigeStopzetting"
import ProcesLeeftijdGeslachtBeperkingActivieteitEnz from "./components/rapporten/jaarverslag/ProcesLeeftijdGeslachtBeperkingActivieteitEnz"
import ProcesContactplaats from "./components/rapporten/jaarverslag/ProcesContactplaats"
import OndersteuningsCirkelsInstroomEnUitstroom from "./components/rapporten/jaarverslag/OndersteuningsCirkelsInstroomEnUitstroom"
import OndersteuningsCirkelsUitstroom from "./components/rapporten/jaarverslag/OndersteuningscirkelsUitstroom"
import OndersteuningscirkelsInstroom from "./components/rapporten/jaarverslag/OndersteuningscirkelsInstroom";
import ProcesGedetineerden from "./components/rapporten/jaarverslag/ProcesGedetineerden";
import ProcesBijkomendeUitdaging from "./components/rapporten/jaarverslag/ProcesBijkomendeUitdaging";
import AanmeldingGeenVermoedenBeperking from "./components/rapporten/jaarverslag/AanmeldingGeenVermoedenBeperking";
import AanmeldingOpPvb from "./components/rapporten/jaarverslag/AanmeldingOpPvb";
import AfgeslotenOpPvb from "./components/rapporten/jaarverslag/AfgeslotenOpPvb";
import AfgeslotenGemiddeldeDuurProcesInMaanden from "./components/rapporten/jaarverslag/AfgeslotenGemiddeldeDuurProcesInMaanden";
import RedenVanAfsluitPerClient from "./components/rapporten/jaarverslag/RedenVanAfsluitPerClient";
import RedenVanAfsluit from "./components/rapporten/jaarverslag/RedenVanAfsluit";
import RedenHeraanmeldingPerClient from "./components/rapporten/jaarverslag/RedenHeraanmeldingPerClient";
import RedenHeraanmelding from "./components/rapporten/jaarverslag/RedenHeraanmelding";
import AfgeslotenAardVanHandicap from "./components/rapporten/jaarverslag/AfgeslotenAardVanHandicap";
import AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz from "./components/rapporten/jaarverslag/AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz";

// Chart imports
import ContactenPerMaandChart from "./components/rapporten/charts/ContactenPerMaandChart";
import OndersteuningsCirkelsDeltaChart from "./components/rapporten/charts/OndersteuningsCirkelsDeltaChart";
import VerdelingCas from "./components/rapporten/dop/VerdelingCas";
import AfgeslotenOndersteuningscirkelsUitstroomWachtlijst
    from "./components/rapporten/jaarverslag/AfgeslotenOndersteuningscirkelsUitstroomWachtlijst";


// Outreach imports
import OutreachListPage from "./pages/outreach/OutreachListPage";
import OutreachDetailsPage from "./pages/outreach/OutreachDetailsPage";
import OutreachNewPage from "./pages/outreach/OutreachNewPage";

interface IOwnProps {
    store: Store<IApplicationState>
}

type AllProps = IOwnProps

class MainRouter extends React.Component<AllProps> {
    public render() {

        return (
            <Provider store={this.props.store}>
                <ConnectedRouter history={history}>
                    <App>
                        {/* Common routes */}
                        <Route exact={true} path="/" component={HomePage} />
                        <Route path="/about" component={AboutPage} />

                        {/* VAPH reports */}
                        <Route path="/vaph/afrekening" component={VaphAfrekeningRapport} />
                        <Route path="/vaph/aanmeldingen" component={VaphAanmeldingenRapport} />
                        <Route path="/vaph/projecten" component={Projecten} />

                        {/* Internal reports */}
                        <Route path="/internal/aanmeldingenWachtlijst" component={AanmeldingenWachtlijst} />
                        <Route path="/internal/contactenPerMedewerkerPerMaand" component={ContactenPerMedewerkerPerMaand} />
                        <Route path="/internal/contactenPerMaand" component={ContactenPerMaand} />
                        <Route path="/internal/lopendeDossiers" component={LopendeDossiersRapport} />
                        <Route path="/internal/gewicht" component={GewichtRapport} />
                        <Route path="/internal/contactenPerMedewerkerPerDag" component={ContactenPerMedewerkerPerDagRapport} />
                        
                        {/* DOP Reports */}
                        <Route path="/dop/voorrangsdossiers" component={Voorrangdossiers} />
                        <Route path="/dop/achttienmaandenproces" component={AchttienmaandenProces} />
                        <Route path="/dop/achttienmaandenmax" component={AchttienmaandenMax} />
                        <Route path="/dop/eenmaligContact" component={EenmaligContactDop} />
                        <Route path="/dop/DopDuosLopendeClienten" component={DopDuosLopendeClienten} />
                        <Route path="/dop/takenlijst" component={Takenlijst} />
                        <Route path="/dop/Traject" component={Traject} />
                        <Route path="/dop/EersteContact" component={EersteContact} />
                        <Route path="/dop/RegistratiesDoorMedewerkers" component={RegistratiesDoorMedewerkers} />
                        <Route path="/dop/EvaluatieMedewerker" component={EvaluatieMedewerker} />
                        <Route path="/dop/DossiersPerGemeente" component={DossiersPerGemeente} />
                        <Route path="/dop/ContactenPerMaandProjecten" component={ContactenPerMaandProjecten} />
                        <Route path="/dop/verdelingcas" component={VerdelingCas} />
                        <Route path="/dop/wachtlijst" component={Wachtlijst} />

                        {/* Jaarrapporten */}
                        <Route path="/jaarrapport/AanmeldingAantalPerMaand" component={JaarrapportAanmeldingAantalPerMaand} />
                        <Route path="/jaarrapport/AanmeldingAangemeldAfgeslotenZonderContact" component={AanmeldingAangemeldAfgeslotenZonderContact} />
                        <Route path="/jaarrapport/AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz" component={AanmeldingLeeftijdGeslachtBeperkingActivieteitEnz} />
                        <Route path="/jaarrapport/AanmeldingVerwijzer" component={AanmeldingVerwijzer} />
                        <Route path="/jaarrapport/AanmeldingGeenVermoedenBeperking" component={AanmeldingGeenVermoedenBeperking} />
                        <Route path="/jaarrapport/ProcesDossierverloop" component={ProcesDossierverloop} />
                        <Route path="/jaarrapport/EenmaligContact" component={EenmaligContactJaarverslag} />
                        <Route path="/jaarrapport/VroegtijdigeStopzetting" component={VroegtijdigeStopzetting} />
                        <Route path="/jaarrapport/ProcesLeeftijdGeslachtBeperkingActivieteitEnz" component={ProcesLeeftijdGeslachtBeperkingActivieteitEnz} />
                        <Route path="/jaarrapport/ProcesContactplaats" component={ProcesContactplaats} />
                        <Route path="/jaarrapport/OndersteuningsCirkelsInstroomEnUitstroom" component={OndersteuningsCirkelsInstroomEnUitstroom} />
                        <Route path="/jaarrapport/OndersteuningsCirkelsUitstroom" component={OndersteuningsCirkelsUitstroom} />
                        <Route path="/jaarrapport/OndersteuningscirkelsInstroom" component={OndersteuningscirkelsInstroom} />
                        <Route path="/jaarrapport/ProcesGedetineerden" component={ProcesGedetineerden} />
                        <Route path="/jaarrapport/ProcesBijkomendeUitdaging" component={ProcesBijkomendeUitdaging} />
                        <Route path="/jaarrapport/AanmeldingOpPvb" component={AanmeldingOpPvb} />
                        <Route path="/jaarrapport/AfgeslotenOpPvb" component={AfgeslotenOpPvb} />
                        <Route path="/jaarrapport/AfgeslotenGemiddeldeDuurProcesInMaanden" component={AfgeslotenGemiddeldeDuurProcesInMaanden} />
                        <Route path="/jaarrapport/RedenVanAfsluitPerClient" component={RedenVanAfsluitPerClient} />
                        <Route path="/jaarrapport/RedenVanAfsluit" component={RedenVanAfsluit} />
                        <Route path="/jaarrapport/RedenHeraanmeldingPerClient" component={RedenHeraanmeldingPerClient} />
                        <Route path="/jaarrapport/RedenHeraanmelding" component={RedenHeraanmelding} />
                        <Route path="/jaarrapport/AfgeslotenAardVanHandicap" component={AfgeslotenAardVanHandicap} />
                        <Route path="/jaarrapport/AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz" component={AfgeslotenLeeftijdGeslachtBeperkingActivieteitEnz} />
                        <Route path="/jaarrapport/AfgeslotenOndersteuningscirkelsWachtrij" component={AfgeslotenOndersteuningscirkelsUitstroomWachtlijst} />
                        
                        {/* Charts */}
                        <Route path="/charts/ContactenPerMaand" component={ContactenPerMaandChart} />
                        <Route path="/charts/OndersteuningsCirkelsTrend" component={OndersteuningsCirkelsDeltaChart} />

                        {/* Outreach */}
                        <Route path="/outreach" component={OutreachListPage} exact={true} />
                        <Route exact={true} path="/outreach/details/:id" component={OutreachDetailsPage} />
                        <Route path="/outreach/new" component={OutreachNewPage} />

                    </App>
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default MainRouter;
