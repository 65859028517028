// React-Redux Stuff
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { IApplicationState } from "../../../store"

// Local Redux State Stuff
import * as InternalReports from "../../../store/rapporten/internal"
import ContactenPerMedewerkerPerDag from "../../../entities/reports/ContactenPerMedewerkerPerDag"
import { FetchState } from "../../../entities/FetchState"
import { IReportData } from "../../../entities/reports/IReportData"

// Components
import AllMedewerkersDropDown from "../../selectie/AllMedewerkersDropDown"
import StartEndAtePickers from "../../selectie/StartEndDatePickers"
import { Button, Alert } from "react-bootstrap"
import Medewerker from "../../../entities/Medewerker"
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

interface IPropsFromState {
    rapport: IReportData<ContactenPerMedewerkerPerDag>;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = ({ rapporten, selectie }: IApplicationState) => ({
    rapport: rapporten.internal.ContactenPerMedewerkerPerDag,
    selectedUser: selectie.selectedMedewerker,
    startDate: selectie.selectedDateRange.startDate,
    endDate: selectie.selectedDateRange.endDate
});


interface IPropsFromDispatch {
    FetchReport: typeof InternalReports.ActionCreator.FetchContactenPerMedewerkerPerDag,
    DownloadReport: typeof InternalReports.ActionCreator.FetchContactenPerMedewerkerPerDagXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(InternalReports.ActionCreator.FetchContactenPerMedewerkerPerDag(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(InternalReports.ActionCreator.FetchContactenPerMedewerkerPerDagXlsx(userId, startDate, endDate))
});


type AllProps = IPropsFromState & IPropsFromDispatch

class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);

        this.requestReport = this.requestReport.bind(this);
        this.download = this.download.bind(this);
    }

    public render() {
        const header = (<div>
            <h1>Rapport contacten per medewerker per dag</h1>
            <StartEndAtePickers />
            <AllMedewerkersDropDown />
            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.requestReport}>Bekijken</Button>

            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.download}>Download</Button>
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.rapport.state) {
            case FetchState.Success:
                body = <div>
                    <Alert variant="success" dismissible={true}><Alert.Heading>Rapport opgehaald</Alert.Heading>Gegevens werden opgehaald op {this.props.rapport.fetchDate.toLocaleString()} </Alert>
                    <ReactTable
                        data={this.props.rapport.data}
                        columns={
                            [
                                {
                                    Header: "Periode", columns:
                                        [

                                            {
                                                Header: "Dag", accessor: "dag", Cell: p => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span>
                                            }
                                        ]
                                },
                                {
                                    Header: "Medewerker", columns:
                                        [
                                            { Header: "Medewerker", accessor: "medewerker" }
                                        ]
                                },
                                {
                                    Header: "Status", columns:
                                        [
                                            { Header: "Dossiers", accessor: "aantalDossiers" },
                                            { Header: "Contacten", accessor: "aantalContacten" }
                                        ]
                                },
                            ]
                        }
                    />
                </div>;
                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.rapport.errorMessage}</Alert>
            default:
                break;
        }

        return (
            <div>
                {header}
                <hr />
                {body}
            </div>
        );
    }

    private download(): void {
        this.props.DownloadReport(this.props.selectedUser.id, this.props.startDate, this.props.endDate);
    }

    private requestReport() {
        this.props.FetchReport(this.props.selectedUser.id, this.props.startDate, this.props.endDate);
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
