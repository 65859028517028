import * as React from "react";
import { IData as  OutreachDoelgroep } from "src/store/outreach/OutreachDoelgroepen";
import { IData } from "src/store/outreach/OutreachItem";
import { emptyMedewerker } from "../../components/selectie/AlleMedewerkersDropDown.args";
import { IData as outreachSettings } from "src/store/outreach/outreachSettings";
import Multiselect from "../../atoms/multi-select/multiselect";
import Medewerker from "../../entities/Medewerker";



interface IProps {
    details: IData;
    settings: outreachSettings;
    detailUpdated: (details: IData) => void;
    doelgroepen: OutreachDoelgroep[];
    allMedewerkers: Medewerker[];
}

export type AllProps = IProps;

export const OutreachFullForm = (props: AllProps) => {
    
    const [details, setDetails] = React.useState(props.details);

    const update = (det: IData) => {
        setDetails(det);
        props.detailUpdated(det);
    }

    return (
        <div className=" table-responsive">
        <table className={'table table-striped table-hover'}>
            <tbody>
                <tr><th className="w-50" scope={"row"}>Medewerkers:</th>
                    <td>
                        <Multiselect 
                            valueList={props.allMedewerkers.map(m => ({ value: m.id, label: m.fullNamePrefixed, enabled: true }))} 
                            selectedValues={details.medewerkers.map(m => ({ value: m.medewerkerId, label: m.medewerker, enabled: true }))} 
                            emptyLabel="Selecteer Medewerker" 
                            onChange={(selectedValues) => update({...details, medewerkers: selectedValues.map(m => ({ medewerkerId: m.value, medewerker: m.label }))})}
                        />
                    </td>
                </tr>
                <tr><th scope={"row"}>Datum:</th><td><input min={props.settings.minDate.toISOString().split('T')[0]} max={props.settings.maxDate.toISOString().split('T')[0]} pattern="\d{4}-\d{2}-\d{2}" type="date" onChange={e => new Date(e.target.value) > props.settings.minDate && new Date(e.target.value) < props.settings.maxDate && update({...details, datum: new Date(e.target.value) })} value={details.datum.toISOString().split('T')[0]}/></td></tr>
                <tr><th scope={"row"}>Contacten per medewerker:</th><td><input min={props.settings.minContacts} max={props.settings.maxContacts} name={'contacts'} type={"number"} onChange={e => update({...details, contacts: +e.target.value})} value={details.contacts} /></td></tr>
                <tr><th scope={"row"}>Organisatie:</th><td><input required={true} type={"text"} value={details.organisatie} onChange={e => update({...details, organisatie: e.target.value})} /></td></tr>
                <tr> 
                    <th scope={"row"}>Doelgroepen:</th>
                    <td>
                        <Multiselect 
                            valueList={props.doelgroepen.map(d => ({ value: d.id, label: d.naam, enabled: true }))} 
                            selectedValues={details.doelgroepen.map(d => ({ value: d.doelgroepId, label: d.doelgroep, enabled: true }))} 
                            emptyLabel="Selecteer Doelgroepen" 
                            onChange={(selectedValues) => update({...details, doelgroepen: selectedValues.map(d => ({ doelgroepId: d.value, doelgroep: d.label }))})}
                        />
                    </td>
                </tr>
                {
                    (
                        props.doelgroepen.filter(dg => details.doelgroepen.some(x => x.doelgroepId == dg.id)).some(dg => dg.enableOmschrijving)
                    ) &&
                    <tr>
                        <th scope={"row"}>Doelgroep Detail:</th><td><input required={true} type={"text"} value={details.doelgroepDetail} onChange={e => update({...details, doelgroepDetail: e.target.value})} /></td>
                    </tr> 
                }
                <tr><th scope={"row"}>Thema:</th><td><input type={"text"} required={true} value={details.thema} onChange={e => update({...details, thema: e.target.value})} /></td></tr>
                <tr><th scope={"row"}>Aantal aanwezigen Professioneel:</th><td><input min={0} name={'aantalAanwezigProfessioneel'} type={"number"} onChange={e => update({...details, aantalAanwezigProfessioneel: +e.target.value})} value={details.aantalAanwezigProfessioneel} /></td></tr>
                <tr><th scope={"row"}>Aantal aanwezigen Niet-professioneel:</th><td><input min={0} name={'aantalAanwezigNietProfessioneel'} type={"number"} value={details.aantalAanwezigNietProfessioneel} onChange={e => update({...details, aantalAanwezigNietProfessioneel: +e.target.value})} /></td></tr>
                <tr><th scope={"row"}>Aantal aanwezigen:</th><td>{details.aantalAanwezigNietProfessioneel + details.aantalAanwezigProfessioneel}</td></tr>
            </tbody>
        </table> 
        </div>
         );
}