export default abstract class Either<Tleft, Tright> {
    protected abstract readonly isRight: boolean

    /**
     * Returns true if left. When true object is cast as a left object.
     * @constructor
     */
    public IsLeft(): this is Left<Tleft, Tright> {
        return this.isRight === false;
    }

    /**
     * Returns true if right. When true object is cast as a right object
     * @constructor
     */
    public IsRight(): this is Right<Tleft, Tright> {
        return this.isRight;
    }

    /**
     * Maps Right object into a new right object
     * @param map
     * @constructor
     */
    public FlatMap<TNewRight>(map: (input: Tright) => TNewRight): Either<Tleft, TNewRight> {
        return this.IsRight()
            ? new Right(map(this.Value))
            : this as unknown as Either<Tleft, TNewRight>
    }
    public GenerateFlatMap<TNewRight>(map: (input: Tright) => TNewRight): Either<Tleft, TNewRight> {
        return this.IsRight()
            ? new Right(map(this.Value))
            : this as unknown as Either<Tleft, TNewRight>
    }

    /**
     * Maps an right into an Either Left or Right
     * @param map
     * @constructor
     */
    public Map<TNewRight>(map: (input: Tright) => Either<Tleft, TNewRight>): Either<Tleft, TNewRight> {
        return this.IsRight()
            ? map(this.Value)
            : this as unknown as Either<Tleft, TNewRight>
    }


    /**
     * Maps a Left object into a Right object and removes the Either object
     * @param map
     * @constructor
     */
    public Reduce(map: (input: Tleft) => Tright): Tright {
        return this.IsLeft()
            ? map(this.Value)
            : (this as unknown as Right<Tleft, Tright>).Value
    }

    /**
     * Returns a string representation, ideal for
     * comparisons (returns "None" when no value is
     * present, a JSON representation when a value
     * is present)
     */
    public abstract toString(): string
}

export function IsRight<Tleft, Tright>(input: Left<Tleft, Tright> | Right<Tleft, Tright>): input is Right<Tleft, Tright> {
    return input.isRight;
}

export class Right<Tleft, Tright> extends Either<Tleft, Tright> {
    public readonly isRight: boolean = true;
    public readonly Value: Tright;

    constructor(value: Tright) {
        super();
        this.Value = value;
    }

    public toString(): string {
        return JSON.stringify(this);
    }
}

export class Left<Tleft, Tright> extends Either<Tleft, Tright> {
    public readonly isRight: boolean = false;
    public readonly Value: Tleft;

    constructor(value: Tleft) {
        super();
        this.Value = value;
    }

    public toString(): string {
        return JSON.stringify(this);
    }
}