import * as React from "react";

class AboutPage extends React.Component {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {

        

        return (
            <div>
                <h1>Over deze tool.</h1>
                
            </div>
        );
    }

}

export default AboutPage;