
import { FetchState } from "../../entities/FetchState";
import { IReportDataObject, emptyReportDataObject } from "../../entities/reports/IReportData";
import { Reducer } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GraphQl, IError } from "../../utils/callGraphQl";
import Either from "../../utils/Either";
import { ActionCreators as Navigation } from "../navigate"

/**********************************************************
 * API Data
 **********************************************************/
export interface IDataDoelgroep {
    doelgroepId: string;
    doelgroep: string;
}
export interface IDataMedewerker {
    medewerkerId: string;
    medewerker: string; 
}
export interface IData {
    id: number;
    medewerkers: IDataMedewerker[];
    datum: Date;
    contacts: number;
    organisatie: string;
    doelgroepen: IDataDoelgroep[];
    doelgroepDetail: string;
    thema: string;
    aantalAanwezigProfessioneel: number;
    aantalAanwezigNietProfessioneel: number;
    savedInGir: boolean;
}

export const defaultOutreachItem: IData = {
    id: 0,
    aantalAanwezigNietProfessioneel: 0,
    aantalAanwezigProfessioneel: 0,
    datum: new Date(),
    contacts: 1,
    doelgroepen: [],
    doelgroepDetail: '',
    medewerkers: [],
    organisatie: '',
    savedInGir: false,
    thema: ''
};

// Make a default OutreachDetail with empty values and a date of today
export const defaultOutreachDetail= emptyReportDataObject<IData>()

/**********************************************************
 * API Settings
 **********************************************************/



const graphQlQuery = `
{ 
    company { 
        outreaches(id: {{id}}) { 
            medewerker {
                id
                fullName
            } 
            id 
            datum 
            contacts
            organisatie 
            aantalAanwezigNietProfessioneel
            aantalAanwezigProfessioneel
            savedInGir
            thema
            doelgroep { 
                id
                naam 
            } 
            doelgroepDetails
        } 
    }
}`

interface IGraphQlQueryResponse {
    company: {
        outreaches: Array<{
            medewerker: Array<{
                id: string
                fullName: string
            }>
            id: number
            datum: Date
            contacts: number
            organisatie: string
            aantalAanwezigNietProfessioneel: number
            aantalAanwezigProfessioneel: number
            savedInGir: boolean
            thema: string
            doelgroep: Array<{
                id: string
                naam: string
            }>,
            doelgroepDetails: string
        }>
    }
}

const graphQlAddSelector = `id`

interface IGraphQlQueryAddResponse {
    company: {
        addOutreachItem: {
            id: number
        }
    }
}

function transformData(response: IGraphQlQueryResponse): IData {
    const data = response.company.outreaches.map(o => {
        return { 
            medewerkers: o.medewerker.map(m => ({medewerker: m.fullName, medewerkerId: m.id})),
            id: o.id,
            datum: o.datum,
            contacts: o.contacts,
            organisatie: o.organisatie,
            aantalAanwezigNietProfessioneel: o.aantalAanwezigNietProfessioneel,
            aantalAanwezigProfessioneel: o.aantalAanwezigProfessioneel,
            doelgroepen: o.doelgroep.map(d => ({doelgroep: d.naam, doelgroepId: d.id})),
            doelgroepDetail: o.doelgroepDetails,
            thema: o.thema,
            savedInGir: o.savedInGir
        }})
    
    if (data.length === 0) {
        throw new Error('No data found')
    }
    return data[0]
}

/**********************************************************
 * State
 **********************************************************/

export interface IState {
    Item: IReportDataObject<IData>
    AddItem: IReportDataObject<IData>
}
const InitialState: IState = {
    Item: defaultOutreachDetail,
    AddItem: defaultOutreachDetail
}

/**********************************************************
 * Action Types
 **********************************************************/
export enum ActionTypes {
    FetchData = "outreach.OutreachItem Fetch",
    FetchDataSuccess = "outreach.OutreachItem FetchSuccess",
    FetchDataFailed = "outreach.OutreachItem FetchFailed",
    AddData = "outreach.OutreachItem Add",
    AddDataSuccess = "outreach.OutreachItem AddSuccess",
    AddDataFailed = "outreach.OutreachItem AddFailed",
}

/**********************************************************
 * Actions & Creators
 **********************************************************/
export interface IFetchData {
    type: ActionTypes.FetchData,
    payload: { id: number }
}
export interface IFetchDataSuccess {
    type: ActionTypes.FetchDataSuccess,
    payload: { data: IData }
}
export interface IFetchDataFailed {
    type: ActionTypes.FetchDataFailed,
    payload: { errorMessage: string }
}
export interface IAddData {
    type: ActionTypes.AddData,
    payload: { data: IData }
}
export interface IAddDataSuccess {
    type: ActionTypes.AddDataSuccess,
    payload: { id: number }
}
export interface IAddDataFailed {
    type: ActionTypes.AddDataFailed,
    payload: { errorMessage: string }
}
type Actions = IFetchData | IFetchDataSuccess | IFetchDataFailed | IAddData | IAddDataSuccess | IAddDataFailed;

export class ActionCreators {
    public static FetchData(id: number): IFetchData {
        return { 
            type: ActionTypes.FetchData, 
            payload: { id } 
        };
    }
    public static FetchDataSuccess(newData: IData): IFetchDataSuccess {
        return {
            type: ActionTypes.FetchDataSuccess,
            payload: { data: newData }
        };
    }
    public static FetchDataFailed(err: string): IFetchDataFailed {
        return {
            type: ActionTypes.FetchDataFailed,
            payload: { errorMessage: err }
        };
    }
    public static AddData(data: IData): IAddData {
        return { 
            type: ActionTypes.AddData, 
            payload: { data } 
        };
    }
    public static AddDataSuccess(id: number): IAddDataSuccess {
        return {
            type: ActionTypes.AddDataSuccess,
            payload: { id }
        };
    }
    public static AddDataFailed(err: string): IAddDataFailed {
        return {
            type: ActionTypes.AddDataFailed,
            payload: { errorMessage: err }
        };
    }
}

/**********************************************************
 * Reducer
 **********************************************************/
export const Reducers: Reducer<IState, Actions> = (state = InitialState, action) => {
    switch (action.type) {
        case ActionTypes.FetchData:
            return { 
                ...state, 
                Item: {
                    ...state.Item,
                    state: FetchState.Busy
                }
            };
        case ActionTypes.FetchDataSuccess:
            return { 
                ...state, 
                Item: {
                    ...state.Item,
                    state: FetchState.Success,
                    data: action.payload.data,
                    fetchDate: new Date()
                }
            };
        case ActionTypes.FetchDataFailed:
            return { 
                ...state, 
                Item: {
                    ...state.Item,
                    state: FetchState.Error,
                    errorMessage: action.payload.errorMessage
                }
            };
        case ActionTypes.AddData:
            return { 
                ...state, 
                AddItem: {
                    ...state.AddItem,
                    state: FetchState.Busy
                }
            };
        case ActionTypes.AddDataSuccess:
            return { 
                ...state, 
                AddItem: {
                    ...state.AddItem,
                    state: FetchState.Success,
                    data: {
                        ... state.AddItem.data,
                        id: action.payload.id
                    },
                    fetchDate: new Date()
                }
            };
        case ActionTypes.AddDataFailed:
            return { 
                ...state, 
                AddItem: {
                    ...state.AddItem,
                    state: FetchState.Error,
                    errorMessage: action.payload.errorMessage
                }
            };
        default:
            return state;
    }
}

/**********************************************************
 * Sagas
 **********************************************************/

function* handleIFetchData(action: IFetchData) {
    try {
        const response : Either<IError, IGraphQlQueryResponse>= yield call(GraphQl.call, graphQlQuery.replace('{{id}}', action.payload.id.toString()))
        yield response
            .FlatMap(success => put(ActionCreators.FetchDataSuccess(transformData(success)) as any))
            .Reduce(error => put(ActionCreators.FetchDataFailed(error.text) as any))
    } catch (err) {
        yield put(ActionCreators.FetchDataFailed(JSON.stringify(err)))
    }
}

function* handleIAddData(action: IAddData) {
    try {

        const mutation = `
        mutation {
            company {
              addOutreachItem(outreachItem: {
                medewerkerId: ${JSON.stringify(action.payload.data.medewerkers.map(m => m.medewerkerId))},
                datum: "${action.payload.data.datum.toISOString()}",
                contacts: ${JSON.stringify(action.payload.data.contacts)},
                organisatie: ${JSON.stringify(action.payload.data.organisatie)},
                doelgroepIds: ${JSON.stringify(action.payload.data.doelgroepen.map(d => d.doelgroepId))},
                doelgroepDetails: ${JSON.stringify(action.payload.data.doelgroepDetail)},
                thema: ${JSON.stringify(action.payload.data.thema)},
                aantalAanwezigProfessioneel: ${JSON.stringify(action.payload.data.aantalAanwezigProfessioneel)},
                aantalAanwezigNietProfessioneel: ${JSON.stringify(action.payload.data.aantalAanwezigNietProfessioneel)},
              }) {
                ${graphQlAddSelector} 
              }
            }
          }
        `


        const response : Either<IError, IGraphQlQueryAddResponse>= yield call(GraphQl.call, mutation)
        yield response
            .FlatMap(success => put(ActionCreators.AddDataSuccess(success.company.addOutreachItem.id) as any))
            .Reduce(error => put(ActionCreators.AddDataFailed(error.text) as any))
    } catch (err) {
        yield put(ActionCreators.AddDataFailed(JSON.stringify(err)))
    }
}

function* handleIAddDataSuccess(action: IAddDataSuccess) {
    yield put(Navigation.ToOutreachDetail(action.payload.id))
}

function* watchAll() {
    yield takeEvery(ActionTypes.FetchData, handleIFetchData)
    yield takeEvery(ActionTypes.AddData, handleIAddData)
    yield takeEvery(ActionTypes.AddDataSuccess, handleIAddDataSuccess)
}
export function* sagas() {
    yield all([fork(watchAll)]);
}