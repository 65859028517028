// React-Redux Stuff
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { IApplicationState } from "../../../store"

// Local Redux State Stuff
import * as InternalReports from "../../../store/rapporten/internal"
import ContactenPerMaand from "../../../entities/reports/ContactenPerMaand"
import { FetchState } from "../../../entities/FetchState"
import { IReportData } from "../../../entities/reports/IReportData"

// Components
import StartEndAtePickers from "../../selectie/StartEndDatePickers"
import { Button, Alert } from "react-bootstrap"
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

interface IPropsFromState {
    rapport: IReportData<ContactenPerMaand>;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = ({ rapporten, selectie }: IApplicationState) => ({
    rapport: rapporten.internal.ContactenPerMaand,
    startDate: selectie.selectedDateRange.startDate,
    endDate: selectie.selectedDateRange.endDate
});


interface IPropsFromDispatch {
    FetchReport: typeof InternalReports.ActionCreator.FetchContactenPerMaand
    DownloadReport: typeof InternalReports.ActionCreator.FetchContactenPerMaandXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (startDate: Date, endDate: Date) => dispatch(InternalReports.ActionCreator.FetchContactenPerMaand(startDate, endDate)),
    DownloadReport: (startDate: Date, endDate: Date) => dispatch(InternalReports.ActionCreator.FetchContactenPerMaandXlsx(startDate, endDate))
});


type AllProps = IPropsFromState & IPropsFromDispatch

class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);

        this.RequestReport = this.RequestReport.bind(this);
        this.download = this.download.bind(this);
    }

    public render() {
        const header = (<div>
            <h1>Rapport contacten per maand</h1>
            <StartEndAtePickers />
            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.RequestReport}>Bekijken</Button>

            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.download}>Download</Button>
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.rapport.state) {
            case FetchState.Success:
                body = <div>
                    <Alert variant="success" dismissible={true}><Alert.Heading>Rapport opgehaald</Alert.Heading>Gegevens werden opgehaald op {this.props.rapport.fetchDate.toLocaleString()} </Alert>
                    <ReactTable
                        data={this.props.rapport.data}
                        columns={
                            [
                                {
                                    Header: "Periode", columns:
                                        [

                                            { Header: "Maand", accessor: "maand" },
                                            { Header: "Jaar", accessor: "jaar" }
                                        ]
                                },
                                {
                                    Header: "Status", columns:
                                        [
                                            { Header: "Dossiers", accessor: "aantalDossiers" },
                                            { Header: "Contacten", accessor: "aantalContacten" },
                                            { Header: "Outreach", accessor: "aantalOutreach" },
                                            { Header: "Totaal", accessor: "totaalContacten" },
                                        ]
                                },
                            ]
                        }
                    />
                </div>;
                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.rapport.errorMessage}</Alert>
            default:
                break;
        }

        return (
            <div>
                {header}
                <hr />
                {body}
            </div>
        );
    }

    private download(): void {
        this.props.DownloadReport(this.props.startDate, this.props.endDate);
    }

    private RequestReport() {
        this.props.FetchReport(this.props.startDate, this.props.endDate);
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
