import * as Voorrangdossiers from "./Voorrangdossiers"
import * as AchttienmaandenMax from "./AchttienmaandMax";
import * as AchttienmaandenProces from "./AchttienmaandProces"
import * as EenmaligContact from "./EenmaligContact"
import * as DopDuosLopendeClienten from "./DopDuosLopendeClienten"
import * as Takenlijst from "./Takenlijst"
import * as Traject from "./Traject"
import * as EersteContact from "./EersteContact"
import * as RegistratiesDoorMedewerkers from "./RegistratiesDoorMedewerkers"
import * as EvaluatieMedewerker from "./EvaluatieMedewerker";
import * as DossiersPerGemeente from "./DossiersPerGemeente";
import * as ContactenPerMaandProjecten from "./ContactenPerMaandProjecten"
import * as VerdelingCas from "./VerdelingCas"
import * as Wachtlijst from "./Wachtlijst"


import {combineReducers} from "redux";
import {all, fork} from "redux-saga/effects";


/* State */
export interface IState  {
    Voorrangdossiers: Voorrangdossiers.IState,
    AchttienmaandenMax: AchttienmaandenMax.IState,
    AchttienmaandenProces: AchttienmaandenProces.IState,
    EenmaligContact: EenmaligContact.IState,
    DopDuosLopendeClienten: DopDuosLopendeClienten.IState,
    Takenlijst: Takenlijst.IState, 
    Traject: Traject.IState,
    EersteContact: EersteContact.IState,
    RegistratiesDoorMedewerkers: RegistratiesDoorMedewerkers.IState,
    EvaluatieMedewerker: EvaluatieMedewerker.IState,
    DossiersPerGemeente: DossiersPerGemeente.IState,
    ContactenPerMaandProjecten: ContactenPerMaandProjecten.IState,
    VerdelingCas: VerdelingCas.IState,
    Wachtlijst: Wachtlijst.IState
}
export const InitialState: IState = {
    Voorrangdossiers: Voorrangdossiers.InitialState,
    AchttienmaandenMax: AchttienmaandenMax.InitialState,
    AchttienmaandenProces: AchttienmaandenProces.InitialState,
    EenmaligContact: EenmaligContact.InitialState,
    DopDuosLopendeClienten: DopDuosLopendeClienten.InitialState,
    Takenlijst: Takenlijst.InitialState,
    Traject: Traject.InitialState,
    EersteContact: EersteContact.InitialState,
    RegistratiesDoorMedewerkers: RegistratiesDoorMedewerkers.InitialState,
    EvaluatieMedewerker: EvaluatieMedewerker.InitialState,
    DossiersPerGemeente: DossiersPerGemeente.InitialState,
    ContactenPerMaandProjecten: ContactenPerMaandProjecten.InitialState,
    VerdelingCas: VerdelingCas.InitialState,
    Wachtlijst: Wachtlijst.InitialState
}

export const ActionCreators = {
    Voorrangdossiers: Voorrangdossiers.ActionCreators,
    AchttienmaandenMax: AchttienmaandenMax.ActionCreators,
    AchttienmaandenProces: AchttienmaandenProces.ActionCreators,
    EenmaligContact: EenmaligContact.ActionCreators,
    DopDuosLopendeClienten: DopDuosLopendeClienten.ActionCreators,
    Takenlijst: Takenlijst.ActionCreators, 
    Traject: Traject.ActionCreators,
    EersteContact: EersteContact.ActionCreators,
    RegistratiesDoorMedewerkers: RegistratiesDoorMedewerkers.ActionCreators,
    EvaluatieMedewerker: EvaluatieMedewerker.ActionCreators,
    DossiersPerGemeente: DossiersPerGemeente.ActionCreators,
    ContactenPerMaandProjecten: ContactenPerMaandProjecten.ActionCreators,
    VerdelingCas: VerdelingCas.ActionCreators,
    Wachtlijst: Wachtlijst.ActionCreators
}
export const reducers = combineReducers<IState>({
    Voorrangdossiers: Voorrangdossiers.Reducers,
    AchttienmaandenMax: AchttienmaandenMax.Reducers,
    AchttienmaandenProces: AchttienmaandenProces.Reducers,
    EenmaligContact: EenmaligContact.Reducers,
    DopDuosLopendeClienten: DopDuosLopendeClienten.Reducers,
    Takenlijst: Takenlijst.Reducers,
    Traject: Traject.Reducers,
    EersteContact: EersteContact.Reducers,
    RegistratiesDoorMedewerkers: RegistratiesDoorMedewerkers.Reducers,
    EvaluatieMedewerker: EvaluatieMedewerker.Reducers,
    DossiersPerGemeente: DossiersPerGemeente.Reducers,
    ContactenPerMaandProjecten: ContactenPerMaandProjecten.Reducers,
    VerdelingCas: VerdelingCas.Reducers,
    Wachtlijst: Wachtlijst.Reducers
});

export function* sagas() {
    yield all(
        [
            fork(Voorrangdossiers.Sagas),
            fork(AchttienmaandenMax.Sagas),
            fork(AchttienmaandenProces.Sagas),
            fork(EenmaligContact.Sagas),
            fork(DopDuosLopendeClienten.Sagas),
            fork(Takenlijst.Sagas),
            fork(Traject.Sagas),
            fork(EersteContact.Sagas),
            fork(RegistratiesDoorMedewerkers.Sagas),
            fork(EvaluatieMedewerker.Sagas),
            fork(DossiersPerGemeente.Sagas),
            fork(ContactenPerMaandProjecten.Sagas),
            fork(VerdelingCas.Sagas),
            fork(Wachtlijst.Sagas)
    ]);
}
