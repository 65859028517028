import * as React from "react";
import * as Amdd from "../../components/selectie/AllMedewerkersDropDown" ;
import ReactTable from "react-table";
import { IApplicationState, RootActionCreators } from "../../store";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {IData as OutreachList} from "../../store/outreach/outreachList";
import { IReportData } from "../../entities/reports/IReportData";
import {Alert, Button} from "react-bootstrap";
import { FetchState } from "../../entities/FetchState";
import {withRouter} from 'react-router-dom';
import { push } from "connected-react-router";

interface IPropsFromState extends Amdd.IPropsFromState {
    list: IReportData<OutreachList>,
}

interface IPropsFromDispatch extends Amdd.IPropsFromDispatch {
    onFetchData: (id: string) => void,
    onClickDetails: (id: number) => void,
    onClickEdit: (id: number) => void,
    onClickNew: () => void,
}

type AllProps = IPropsFromState & IPropsFromDispatch

export class OutreachListPage extends React.Component<AllProps> {

    constructor(props: AllProps, context: any) {
        super(props, context);

        if (this.props.list.state === FetchState.NotFetched) {
            props.onFetchData(this.props.selectedMedewerker.id);
        }
    }

    public render() {

        switch (this.props.list.state) {
            case FetchState.NotFetched:
            case FetchState.Busy:
                return (<div><h1>Outreach: Laden</h1>
                <p>
                    Bezig met laden van de lijst... Even geduld aub
                </p></div>)
            case FetchState.Error:
                return (<div>
                    <h1>Outreach: Fout</h1>
                    <Alert variant="danger">An error has occured while getting the list: {this.props.list.errorMessage} </Alert>
                    <p>Fout bij met laden van gegevens, probeer opnieuw aub</p>
                    <Button onClick={() => this.props.onFetchData(this.props.selectedMedewerker.id)}>Probeer opnieuw</Button>
                </div>)
        }

        return (
            <div>
                <h1>Outreach</h1>
                <Amdd.AlleMedewerkersDropDown {...this.props} />
                <Button onClick={() => this.props.onFetchData(this.props.selectedMedewerker.id)}>Vernieuw gegevens</Button>
                <Button onClick={() => this.props.onClickNew()}>Nieuwe Registratie</Button>
                <ReactTable
                    data={this.props.list.data}
                    columns={
                        [
                            { Header: "Medewerker", accessor: "medewerkers", Cell: (p: any) => <span>{p.value == null ? "" : p.value.join(', ')}</span> },
                            { Header: "Datum", accessor: "datum", Cell: (p: any) =>
                                    <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                            { Header: "Contacten", accessor: "contacts",  },
                            { Header: "Organisatie", accessor: "organisatie",  },
                            { Header: "Doelgroep", accessor: "doelgroep", Cell: (p: any) => <span>{p.value == null ? "" : p.value.join(', ')}</span> },
                            { Header: "Details", accessor: "id", Cell: (p: any) =><span>{p.value == null ? "Error - No Id" : <Button onClick={() => this.props.onClickDetails(p.value)}>Details</Button>}</span> },
                            { Header: "Aanpassen", accessor: "id", Cell: (p: any) =><span>{p.value == null ? "Error - No Id" : <Button onClick={() => this.props.onClickEdit(p.value)}>Aanpassen</Button>}</span> },
                        ]
                    }
                />

            </div>
        );
    }

}

/* Mapping the state to this page */

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
    ...Amdd.mapStateToProps(state),
    list: state.outreach.outreachList.OutreachList
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
    ...Amdd.mapDispatchToProps(dispatch),
    onFetchData: (id: string) => dispatch(RootActionCreators.Outreach.outreachList.FetchData(id)),
    onClickDetails: (id: number) => dispatch(RootActionCreators.Navigate.ToOutreachDetail(id)),
    onClickEdit: (id: number) => dispatch(RootActionCreators.Navigate.ToOutreachEdit(id)),
    onClickNew: () => dispatch(RootActionCreators.Navigate.ToOutreachNew()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutreachListPage);