import { Action, Reducer } from "redux";
import Medewerker, { Medewerkers } from "../../entities/Medewerker";
import DateRange from "../../entities/DateRange";
import { FetchState } from "../../entities/FetchState"

/**********************************************************
 * STATE
 **********************************************************/

export interface IMedewerkerList {
    fetchState: FetchState;
    errorMessage: string;
    fetchTime: Date;
    medewerkers: Medewerkers;
}

export interface IState {
    selectedMedewerker: Medewerker;
    allMedewerkers: IMedewerkerList;
    allMedewerkersNoAll: IMedewerkerList;
    selectedDateRange: DateRange;
}

export const initialState: IState = {
    selectedMedewerker: Medewerker.GetAllMedewerker(),
    allMedewerkers: { errorMessage: "", fetchState: FetchState.NotFetched, fetchTime: new Date(), medewerkers: [] },
    allMedewerkersNoAll: { errorMessage: "", fetchState: FetchState.NotFetched, fetchTime: new Date(), medewerkers: [] },
    selectedDateRange: DateRange.Default()
}


/**********************************************************
 * ACTIONS & CREATORS
 **********************************************************/
export enum SelectieActionTypes {
    FetchAllMedewerkers = "Selectie.FetchAllMedewerkers",
    FetchAllMedewerkersSuccess = "Selectie.FetchAllMedewerkersSuccess",
    FetchAllMedewerkersFailed = "Selectie.FetchAllMedewerkersFailed",
    ActiveMedewerkerChanged = "Selectie.ActiveMedewerkerChanged",
    DateRangeChanged = "Selectie.DateRangeChanged"
};

// Action object signatures
export interface IFetchAllMedewerkers extends Action<SelectieActionTypes> { type: SelectieActionTypes.FetchAllMedewerkers }
export interface IFetchAllMedewerkersSuccess extends Action<SelectieActionTypes> { type: SelectieActionTypes.FetchAllMedewerkersSuccess, payload: { medewerkers: Medewerker[] } }
export interface IFetchAllMedewerkersFailed extends Action<SelectieActionTypes> { type: SelectieActionTypes.FetchAllMedewerkersFailed, payload: { errorMessage: string } }
export interface IActiveMedewerkerChanged extends Action<SelectieActionTypes> { type: SelectieActionTypes.ActiveMedewerkerChanged, payload: { selectedMedewerker: Medewerker } }
export interface IDateRangeChanged extends Action<SelectieActionTypes> { type: SelectieActionTypes.DateRangeChanged, payload: { dateRange: DateRange } }

// 
export class SelectieActionCreator {

    public static FetchAllMedewerkers(): IFetchAllMedewerkers {
        return { type: SelectieActionTypes.FetchAllMedewerkers }
    }

    public static FetchAllMedewerkersSuccess(newMedewerkers: Medewerker[]): IFetchAllMedewerkersSuccess {
        return {
            type: SelectieActionTypes.FetchAllMedewerkersSuccess, payload: { medewerkers: newMedewerkers }
        }
    }

    public static FetchAllMedewerkersFailed(err: string): IFetchAllMedewerkersFailed {
        return {
            type: SelectieActionTypes.FetchAllMedewerkersFailed,
            payload: { errorMessage: err }
        }
    }

    public static ActiveMedewerkerChanged(med: Medewerker): IActiveMedewerkerChanged {
        return {
            type: SelectieActionTypes.ActiveMedewerkerChanged,
            payload: { selectedMedewerker: med }
        }
    }

    public static ActiveMedewerkerChangedToAll(): IActiveMedewerkerChanged {
        return {
            type: SelectieActionTypes.ActiveMedewerkerChanged,
            payload: { selectedMedewerker: Medewerker.GetAllMedewerker() }
        }
    }

    public static DateRangeChanged(newDateRange: DateRange): IDateRangeChanged {
        return {
            type: SelectieActionTypes.DateRangeChanged,
            payload: { dateRange: newDateRange }
        }
    }
}





/******************************************************************
 * REDUCER
 *****************************************************************/
type ReducerActions = IFetchAllMedewerkers | IFetchAllMedewerkersSuccess | IFetchAllMedewerkersFailed | IActiveMedewerkerChanged | IDateRangeChanged;
export const reducer: Reducer<IState, ReducerActions> = (state = initialState, action) => {
    switch (action.type) {
        case SelectieActionTypes.ActiveMedewerkerChanged:
            return { ...state, selectedMedewerker: action.payload.selectedMedewerker };
        case SelectieActionTypes.FetchAllMedewerkers:
            return { ...state, allMedewerkers: { ...state.allMedewerkers, fetchState: FetchState.Busy, errorMessage: "" } }
        case SelectieActionTypes.FetchAllMedewerkersFailed:
            return { ...state, allMedewerkers: { ...state.allMedewerkers, fetchState: FetchState.Error, errorMessage: action.payload.errorMessage, medewerkers: [] } }
        case SelectieActionTypes.FetchAllMedewerkersSuccess:
            const medewerkerslst = [...action.payload.medewerkers];
            medewerkerslst.unshift(Medewerker.GetAllMedewerker());
            return { ...state, 
                allMedewerkers: { ...state.allMedewerkers, fetchState: FetchState.Success, errorMessage: "", medewerkers: medewerkerslst },
                allMedewerkersNoAll: { ...state.allMedewerkersNoAll, fetchState: FetchState.Success, errorMessage: "", medewerkers: [...action.payload.medewerkers] },
             }
        case SelectieActionTypes.DateRangeChanged:
            return { ...state, selectedDateRange: action.payload.dateRange }

        default:
            return state;
    }
}