import * as Connection from "./Connection"
import * as WhoAmI from "./WhoAmI"
import {combineReducers} from "redux";
import {all, fork} from "redux-saga/effects";

/* State */
export interface IState  {
    Connection: Connection.IState,
    WhoAmI: WhoAmI.IState
    
}

export const ActionCreators = {
    Connection: Connection.ActionCreators,
    WhoAmI: WhoAmI.ActionCreators,
}
export const reducers = combineReducers<IState>({
    Connection: Connection.Reducers,
    WhoAmI: WhoAmI.Reducers,
});

export function* sagas() {
    yield all(
        [
            fork(Connection.Sagas),
            fork(WhoAmI.Sagas)
        ]);
}