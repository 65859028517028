import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux"

import { Form } from "react-bootstrap"
import { IApplicationState } from "../../store"
import * as Selectie from "../../store/selectie/"
import Medewerker from "../../entities/Medewerker"
import { FetchState } from "../../entities/FetchState"

export interface IPropsFromState {
    medewerkers: Selectie.IMedewerkerList;
    selectedMedewerker: Medewerker;
}
export const mapStateToProps = ({ selectie }: IApplicationState) => {
    return {
        medewerkers: selectie.allMedewerkers,
        selectedMedewerker: selectie.selectedMedewerker
    }

}

export interface IPropsFromDispatch {
    fetchMedewerkers: typeof Selectie.SelectieActionCreator.FetchAllMedewerkers
    updateMedewerker: typeof Selectie.SelectieActionCreator.ActiveMedewerkerChanged
}
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchMedewerkers: () => dispatch(Selectie.SelectieActionCreator.FetchAllMedewerkers()),
    updateMedewerker: (medewerker: Medewerker) => dispatch(Selectie.SelectieActionCreator.ActiveMedewerkerChanged(medewerker)) 
});


export type AllProps = IPropsFromState & IPropsFromDispatch

export class AlleMedewerkersDropDown extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);

        this.updateMedewerker = this.updateMedewerker.bind(this);
    }


    public componentDidMount() {
        if (this.props.medewerkers.fetchState === FetchState.NotFetched) {
            this.props.fetchMedewerkers();
        }
    }

    public render() {
        return (

            <Form.Group controlId="selectie.SelectMedewerker">
                <Form.Label>Selecteer medewerker</Form.Label>
                <Form.Control as="select" onChange={this.updateMedewerker} value={this.props.selectedMedewerker.id}>
                    {this.props.medewerkers.medewerkers.map(medewerker =>
                        <option value={medewerker.id} key={medewerker.id}>
                            {medewerker.fullNamePrefixed}
                        </option>
                    )}
                </Form.Control>
            </Form.Group>

        );

    }

    private updateMedewerker(changeParam: any): void {
        const value = changeParam.target.value;
        const medewerker = this.props.medewerkers.medewerkers.filter(x => x.id === value)[0];
        this.props.updateMedewerker(medewerker);
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlleMedewerkersDropDown);