import * as React from "react";
import {RootActionCreators, IApplicationState} from "../../../store";
import {connect} from "react-redux";
import {NoAccess} from "../../common/NoAccess";
import AllMedewerkersDropDown from "../../selectie/AllMedewerkersDropDown";
import Medewerker from "../../../entities/Medewerker";
import {Alert, Button} from 'react-bootstrap';
import {Dispatch} from "redux";
import {IReportDownloadData} from "../../../entities/reports/IReportDownloadData";
import {FetchState} from "../../../entities/FetchState";

interface IProps {
    hasAccess: boolean;
    userLoggedIn: string;
    selectedUser: Medewerker;
    downloadStatus: IReportDownloadData;
}
const mapStateToProps = (state: IApplicationState) => ({
    hasAccess: state.connection.WhoAmI.data.data.hasAccessToEvaluationForm,
    userLoggedIn: state.connection.WhoAmI.data.data.fullName,
    selectedUser: state.selectie.selectedMedewerker,
    downloadStatus : state.rapporten.dop.EvaluatieMedewerker.ReportXlsx
})

interface IDispatch {
    FetchReportXlsx: (userId: string) => any
}
const mapStatetoDispatch = (dispatch: Dispatch) => ({
    FetchReportXlsx: (userId: string) => dispatch(RootActionCreators.Rapporten.Dop.EvaluatieMedewerker.FetchReportXlsx(userId, new Date(), new Date()))
})

export const EvaluatieMedewerker: React.FC<IProps & IDispatch> = props => {
      
    if (!props.hasAccess) {
        return <NoAccess />
    }
        
    const goForDownload = () => {
        if (props.selectedUser.id === "") {
            window.alert("You can't do that. You need to select a staff member.")
            return;
        }
        props.FetchReportXlsx(props.selectedUser.id);
    }
    
    const downloadState = () => {
        switch (props.downloadStatus.state) {
            case FetchState.Busy:
                return <Alert variant={"info"}>Bezig met voorbereiden...</Alert>
            case FetchState.Error:
                return <>
                    <h3>Stap 2bis: Fix the error</h3>
                    <Alert variant={"danger"}>Fout: {props.downloadStatus.errorMessage}</Alert>
                </>
            case FetchState.Success:
                const objectUrl = window.URL.createObjectURL(props.downloadStatus.data)
                return <>
                    <h3>Stap 3: Downloaden maar</h3>
                    <Alert variant={"success"}><a href={objectUrl} download={`Evaluatie.xlsx`}>Klik hier om het rapport te openen</a></Alert>
                </>
            default:
                return <></>
        }
    }
    
    return <>
        
        <h1>Evaluatie Medewerker</h1>
        <p>Deze beveiligde pagina wordt momenteel bekeken door {props.userLoggedIn}</p>
        
        
        <h3>Stap 1: Selecteer Medewerker</h3>
        <AllMedewerkersDropDown />

        {props.selectedUser.id === "" 
            ? <Alert variant={"warning"}>Selecteer een medewerker om door te gaan.</Alert> 
            : <>
                
                <h3>Stap 2: Klik op voorbereiden</h3>
                {props.downloadStatus.state !== FetchState.Busy && 
                    <Button onClick={goForDownload}>Voorbereiden</Button>}
                
                {downloadState()}
            </>}
    </>
    
}

export default connect(mapStateToProps, mapStatetoDispatch)(EvaluatieMedewerker)