// React-Redux Stuff
import * as React from "react";

// Local Redux State Stuff
import { FetchState } from "../../../entities/FetchState"
import { IReportData } from "../../../entities/reports/IReportData"

// Components
import AllMedewerkersDropDown from "../../selectie/AllMedewerkersDropDown"
import StartEndAtePickers from "../../selectie/StartEndDatePickers";
import { Button, Alert } from "react-bootstrap"
import Medewerker from "../../../entities/Medewerker"

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";


interface IPropsFromState<T> {
    rapport: IReportData<T>;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}

interface ISettings {
    title: string;
    columns: any;
    selectMedewerker: boolean;
    selectDate: boolean;
}

interface IPropsFromDispatch {
    FetchReport: any
    FetchReportXlsx: any
}

type AllProps<T> = IPropsFromState<T> & IPropsFromDispatch & ISettings

class StdRapport<T> extends React.Component<AllProps<T>> {

    constructor(props: any, context: any) {
        super(props, context);

        this.RequestReport = this.RequestReport.bind(this);
        this.download = this.download.bind(this);
    }

    public render() {
        const medewerkerDropDown = this.props.selectMedewerker ? <AllMedewerkersDropDown /> : <></>
        const datePicker = this.props.selectDate ? <StartEndAtePickers /> : <></>
        
        const header = (<div>
            <h1>{this.props.title}</h1>
            {medewerkerDropDown}
            {datePicker}
            
            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.RequestReport}>Bekijken</Button>

            <Button disabled={this.props.rapport.state === FetchState.Busy} onClick={this.download}>Download</Button>
        </div>);

        let body = <Alert variant="warning">Geen data ingeladen</Alert>;

        switch (this.props.rapport.state) {
            case FetchState.Success:
                body = <div>
                    <Alert variant="success" dismissible={true}><Alert.Heading>Rapport opgehaald</Alert.Heading>Gegevens werden opgehaald op {this.props.rapport.fetchDate.toLocaleString()} </Alert>
                    <ReactTable
                        data={this.props.rapport.data}
                        columns={this.props.columns}
                    />
                </div>;
                break;
            case FetchState.Busy:
                body = <Alert variant="primary" >Even geduld... Bezig met ophalen...</Alert>;
                break;
            case FetchState.Error:
                body = <Alert variant="danger">An error has occured: {this.props.rapport.errorMessage}</Alert>
            default:
                break;
        }

        return (
            <div>
                {header}
                <hr />
                {body}
            </div>
        );
    }

    private download(): void {
        this.props.FetchReportXlsx(this.props.selectedUser.id, this.props.startDate, this.props.endDate);
    }

    private RequestReport() {
        this.props.FetchReport(this.props.selectedUser.id, this.props.startDate, this.props.endDate);
    }

}


export default StdRapport;
