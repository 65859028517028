import { IReportData } from "src/entities/reports/IReportData";
import { IReport, ActionCreators } from "../../../store/rapporten/dop/RegistratiesDoorMedewerkers";
import Medewerker from "../../../entities/Medewerker";
import {IApplicationState} from "../../../store";
import {Dispatch} from "redux";
import * as React from "react";
import StdRapport from "../common/StandaardRapportFull";
import {connect} from "react-redux";

// Setup Title
const reportTitle = "Registraties Door Medewerkers";
const selectDates = true;
const selectMedewerker = false;

// Mapping Dynamic Properties
interface IPropsFromState {
    rapport: IReportData<IReport>;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = (state: IApplicationState) => ({
    rapport: state.rapporten.dop.RegistratiesDoorMedewerkers.Report,
    selectedUser: state.selectie.selectedMedewerker,
    startDate: state.selectie.selectedDateRange.startDate,
    endDate: state.selectie.selectedDateRange.endDate
});

// Mapping Event Dispatcher
interface IPropsFromDispatch {
    FetchReport: typeof ActionCreators.FetchReport;
    DownloadReport: typeof ActionCreators.FetchReportXlsx;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

// Setup Report
type AllProps = IPropsFromState & IPropsFromDispatch
class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
        return(
            <StdRapport
                title={reportTitle}
                selectDate={selectDates}
                selectMedewerker={selectMedewerker}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedUser={this.props.selectedUser}
                rapport={this.props.rapport}
                FetchReport={this.props.FetchReport}
                FetchReportXlsx={this.props.DownloadReport}
                columns={
                    [
                        {
                            Header: "Dossier", columns: 
                                [
                                    { Header: "Naam SE", accessor: "naamSe" },
                                    { Header: "Nummer SE", accessor: "nummerSe" },
                                    { Header: "Rijksregisternummer", accessor: "rijksregisterNummer" },
                                    { Header: "Naam", accessor: "naam" },
                                    { Header: "Voornaam", accessor: "voornaam" },
                                    { Header: "Straat & Nr", accessor: "straatNummer" },
                                    { Header: "Postcode", accessor: "postcode" },
                                    { Header: "Gemeente", accessor: "gemeente" },
                                    { Header: "Type begeleiding", accessor: "typeRegistratie" },

                                    { Header: "Datum", accessor: "datum", Cell: (p: any) => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },
                                    { Header: "Datum Registratie", accessor: "datumRegistratie", Cell: (p: any) => <span>{p.value == null ? "" : p.value.toLocaleDateString()}</span> },

                                    { Header: "Naam Medewerker", accessor: "naamMedewerker" },
                                    { Header: "Voornaam Medewerker", accessor: "voornaamMedewerker" }
                                ]
                        }
                    ]
                }
            />
        );
    }


}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);