// Core imports
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux";

import { IApplicationState } from "../../store"
import * as Data from "../../store/connection/WhoAmI"

interface IPropsFromState {
    whoAmI: Data.IState;
}
const mapStateToProps = (state: IApplicationState) => ({
    whoAmI: state.connection.WhoAmI,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});


class HomePage extends React.Component<IPropsFromState> {
    constructor(props: any, context: any) {
        super(props, context);
    }
    
    public render() {

        return (
            <div>
                <div className="jumbotron">
                    <h1>D.O.P. {this.props.whoAmI.data.data.companyName} Rapportering</h1>
                    <p>Welkom {this.props.whoAmI.data.data.fullName}</p>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);