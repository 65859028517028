import { IReportData } from "src/entities/reports/IReportData";
import { IReport, ActionCreators } from "../../../store/rapporten/jaarverslag/ProcesDossierverloop";
import Medewerker from "../../../entities/Medewerker";
import {IApplicationState} from "../../../store";
import {Dispatch} from "redux";
import * as React from "react";
import StdRapport from "../common/StandaardRapportFull";
import {connect} from "react-redux";

// Setup Title
const reportTitle = "Proces: Dossierverloop";
const selectDates = true;
const selectMedewerker = false;

// Mapping Dynamic Properties
interface IPropsFromState {
    rapport: IReportData<IReport>;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = (state: IApplicationState) => ({
    rapport: state.rapporten.jaarverslag.ProcesDossierverloop.Report,
    selectedUser: state.selectie.selectedMedewerker,
    startDate: state.selectie.selectedDateRange.startDate,
    endDate: state.selectie.selectedDateRange.endDate
});

// Mapping Event Dispatcher
interface IPropsFromDispatch {
    FetchReport: typeof ActionCreators.FetchReport
    DownloadReport: typeof ActionCreators.FetchReportXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReport(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(ActionCreators.FetchReportXlsx(userId, startDate, endDate))
});

// Setup Report
type AllProps = IPropsFromState & IPropsFromDispatch
class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
        return(
            <StdRapport
                title={reportTitle}
                selectDate={selectDates}
                selectMedewerker={selectMedewerker}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedUser={this.props.selectedUser}
                rapport={this.props.rapport}
                FetchReport={this.props.FetchReport}
                FetchReportXlsx={this.props.DownloadReport}
                columns={
                    [
                        {
                            Header: "Rapportage", columns:
                                [
                                    { Header: "Naam", accessor: "rapportage" },
                                ]
                        },
                        {
                            Header: "Begeleiding kwantiteiten", columns: 
                                [
                                    { Header: "Aantal Dossiers", accessor: "aantalDossiers" },
                                    { Header: "Aantal Contacten", accessor: "aantalContacten" },
                                    { Header: "Gemiddeld Contact per dossier", accessor: "gemiddeldAantalContactenPerClient" },
                                ]
                        },
                        {
                            Header: "Begeleiding gemiddelde duurtijd", columns:
                                [
                                    { Header: "Dagen", accessor: "gemiddeldeBegeleidingsduurDagen" },
                                    { Header: "Maanden", accessor: "gemiddeldeBegeleidingsduurMaanden" },
                                ]
                        },
                        {
                            Header: "Begeleiding resultaat", columns:
                                [
                                    { Header: "Opgestelde OpPvb", accessor: "aantalOpgesteldeOpPvb" },
                                    { Header: "Opgestelde OpDop", accessor: "aantalOpgesteldeOpDop" },
                                ]
                        }

                    ]
                }
            />
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);