import * as React from "react";

export interface IValue {
    value: string;
    label?: string;
    enabled?: boolean;
}

interface IInternalValue<T extends IValue> {
    isFromSelectedList: boolean;
    value: T;
}

interface IProps<T extends IValue> {
    valueList: T[];
    selectedValues?: T[];
    emptyLabel?: string;
    onChange?: (selectedValues: T[]) => void;
    className?: string;
}

export type AllProps<T extends IValue> = IProps<T>;

export const Multiselect = <T extends IValue>(props: AllProps<T>) => {
    const [selectedItems, setSelectedItems] = React.useState<T[]>(props.selectedValues || []);
    const [valueList, setValueList] = React.useState<IInternalValue<T>[]>(props.valueList.map(value => ({value: value, isFromSelectedList: false})));
    const randomId = Math.random().toString(36).substring(7);

    // Add selected values that are not in the value list
    const selectedValues = props.selectedValues || [];
    selectedValues.forEach(selectedItem => {
        if (!valueList.some(value => value.value.value == selectedItem.value)) {
            setValueList([...valueList, {value: {...selectedItem, label: selectedItem.label ?? selectedItem.value, enabled: true}, isFromSelectedList: true}]);
        }
    });

    const onChange = (selectedItems: T[]) => {
        if (props.onChange) {
            props.onChange(selectedItems);
        }
        setSelectedItems(selectedItems);
    }

    return (
        <div className={"dropdown"}>
            <button className={"btn dropdown-toggle " + (props.className || "btn-primary")}
                    type="button" 
                    id={"multiSelectDropdown-" + randomId}
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"> 
                {selectedItems.length === 0 ? props.emptyLabel ?? "Select" : valueList.filter(value => selectedItems.some(x => x.value == value.value.value)).map(value => value.value.label ?? value.value.value).join(", ")} 
            </button> 
            <ul className="dropdown-menu" style={{maxHeight: "200px", overflowY: "auto"}}
                aria-labelledby={"multiSelectDropdown-" + randomId}> 
                {valueList.map((value, index) => {
                    return (
                        <li key={index}> 
                            <div className="dropdown-item">
                                <div className="form-check">
                                    <input 
                                        type="checkbox"
                                        id={"checkbox" + index}
                                        className="form-check-input"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                onChange([...selectedItems, valueList.filter(v => v.value.value === value.value.value)[0].value]);
                                            } else {
                                                onChange(selectedItems.filter(item => item.value !== value.value.value));
                                            }
                                        
                                        }}
                                        disabled={!value.value.enabled}
                                        value={value.value.value}
                                        checked={selectedItems.some(si => si.value == value.value.value)} /> 
                                    <label className="form-check-label" htmlFor={"checkbox" + index}>
                                        {value.value.label ?? value.value.value} 
                                    </label> 
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul> 
        </div>
    );
}

export default Multiselect;