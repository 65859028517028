import * as outreachList from './outreachList';
import * as outreachItem from './OutreachItem';
import * as outreachSettings from './outreachSettings';
import * as outreachDoelgroepen from './OutreachDoelgroepen';

import {combineReducers} from "redux";
import {all, fork} from "redux-saga/effects";

export interface IState  {
    outreachList: outreachList.IState
    outreachItem: outreachItem.IState
    outreachSettings: outreachSettings.IState
    outreachDoelgroepen: outreachDoelgroepen.IState
}

export const ActionCreators = {
    outreachList: outreachList.ActionCreators,
    outreachItem: outreachItem.ActionCreators,
    outreachSettings: outreachSettings.ActionCreators,
    outreachDoelgroepen: outreachDoelgroepen.ActionCreators
}

export const Reducers = combineReducers<IState>({
    outreachList: outreachList.Reducers,
    outreachItem: outreachItem.Reducers,
    outreachSettings: outreachSettings.Reducers,
    outreachDoelgroepen: outreachDoelgroepen.Reducers
});

export function* sagas() {
    yield all([
        fork(outreachList.sagas),
        fork(outreachItem.sagas),
        fork(outreachSettings.sagas),
        fork(outreachDoelgroepen.sagas)
    ]);
}