import { FetchState } from "../FetchState"

export interface IReportData<T> {
    state: FetchState;
    data: T[];
    fetchDate: Date;
    errorMessage: string;
}

export function emptyReportData<T>(): IReportData<T> {
    return {
        state: FetchState.NotFetched,
        data: [],
        fetchDate: new Date(),
        errorMessage: ""
    };
}

export interface IReportDataObject<T> {
    state: FetchState;
    data: T;
    fetchDate: Date;
    errorMessage: string;
}

export function emptyReportDataObject<T>(): IReportDataObject<T> {
    return {
        state: FetchState.NotFetched,
        data: {} as T,
        fetchDate: new Date(),
        errorMessage: ""
    };
}